import React from "react";
import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  FormControl,
  FormLabel,
  InputGroup,
  Text,
} from "@chakra-ui/react";

/** Props for the CommonSelect component */
type CommonSelectProps = ChakraSelectProps & {
  /** Array of options to be displayed in the Select component */
  options: { value: string | number; label: string }[];
};

/** CommonSelect component is a reusable Select component that uses Chakra UI's Select and extends its functionality. */
export const CommonSelect: React.FC<CommonSelectProps> = (props) => {
  const { options, ...selectProps } = props;

  return (
    <InputGroup width={props.width}>
      <FormControl id="common-select">
        <FormLabel
          zIndex={2}
          top="-5px"
          lineHeight="10px"
          marginLeft="10px"
          position="absolute"
          backgroundColor="white"
          paddingLeft="4px"
          paddingRight="4px"
          width="fit-content"
          fontSize="small"
          color="blackAlpha.600"
          display={props.value ? "flex" : "none"}
        >
          {props.placeholder}
        </FormLabel>
        <ChakraSelect
          {...selectProps}
          placeholder={!props.value ? props.placeholder : undefined}
          isInvalid={!props.value && options.length === 0}
          height="42px"
          shadow="base"
          borderColor="blackAlpha.300"
          borderRadius="md"
          transition="border-color 0.2s ease, box-shadow 0.2s ease"
          _hover={{ borderColor: "orange.500", shadow: "sm", cursor: props.isDisabled ? "not-allowed" : "pointer" }}
          sx={{ ...selectProps.sx }}
        >
          {options.map((option, index) => (
            <Text as={"option"} key={index} value={option.value} color="black">
              {option.label}
            </Text>
          ))}
        </ChakraSelect>
      </FormControl>
    </InputGroup>
  );
};
