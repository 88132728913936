import { Card, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SkillSetCard, type SkillSetCardProps } from "./SkillSetCard";
import { CommonIconButton } from "../../../components/CommonIconButton";
import type { SkillSet } from "../../../types";

type SkillSetsProps = {
  skillSets: SkillSetCardProps["skillSet"][];
  onSave: (skillSetToSave: SkillSet) => Promise<void>;
};

export const SkillSets: React.FC<SkillSetsProps> = (props) => {
  const [skillSets, setSkillSets] = useState(
    props.skillSets.map((skillSet) => ({ ...skillSet, loading: false, active: false })),
  );
  const [isCreatingNewSkillset, setIsCreatingNewSkillset] = useState(false);

  useEffect(() => {
    setSkillSets(props.skillSets.map((skillSet) => ({ ...skillSet, loading: false, active: false })));
  }, [props.skillSets]);

  const handleSave = async (skillSetToSave: SkillSet) => {
    setSkillSets((prevSkillSets) =>
      prevSkillSets.map((skillSet) => (skillSet.id === skillSetToSave.id ? { ...skillSet, loading: true } : skillSet)),
    );

    await props.onSave(skillSetToSave);

    setSkillSets((prevSkillSets) =>
      prevSkillSets.map((skillSet) => (skillSet.id === skillSetToSave.id ? { ...skillSet, loading: false } : skillSet)),
    );
    setIsCreatingNewSkillset(false);
  };

  const handleAddNewSkillSet = () => {
    setIsCreatingNewSkillset(true);
    setSkillSets((prevSkillSets) => [
      ...prevSkillSets,
      {
        name: "",
        skills: [],
        active: true,
        loading: false,
      },
    ]);
  };

  const handleAddSkill = (index: number) => {
    setSkillSets((prevSkillSets) =>
      prevSkillSets.map((skillSet, i) =>
        i === index
          ? {
              ...skillSet,
              active: true,
              skills: [...(skillSet.skills || []), { name: "" }],
            }
          : skillSet,
      ),
    );
  };

  const handleResetSkillSet = (skillSetId: string | null) => {
    setSkillSets((prevSkillSets) =>
      prevSkillSets
        .map((skillSet) =>
          skillSet.id === skillSetId
            ? {
                ...skillSet,
                skills: skillSet.skills?.filter((skill) => skill.name),
              }
            : skillSet,
        )
        .filter((skillSet) => skillSet.name),
    );
    setIsCreatingNewSkillset(false);
  };

  return (
    <SimpleGrid minChildWidth="80" spacing="8">
      {skillSets.map((skillSet, index) => (
        <SkillSetCard
          key={skillSet.id || index}
          skillSet={skillSet}
          onAdd={() => handleAddSkill(index)}
          onSave={(updatedSkillSetOrSkill) => handleSave(updatedSkillSetOrSkill)}
          resetSkillSet={(skillSetId) => handleResetSkillSet(skillSetId)}
          isLoading={skillSet.loading}
          isActive={skillSet.active}
        />
      ))}
      <Card
        display={isCreatingNewSkillset ? "none" : "flex"}
        width="80"
        variant="outline"
        size="lg"
        height="fit-content"
        paddingY="3"
        paddingX="4"
        bgColor="teal.800"
        borderColor="blackAlpha.300"
        borderRadius="md"
        shadow="base"
        transition="all 0.3s ease"
        alignItems="center"
        justifyContent="center"
        _hover={{
          bgColor: "teal.900",
          cursor: "pointer",
        }}
        onClick={handleAddNewSkillSet}
      >
        <CommonIconButton
          height="30px"
          onClick={(event) => {
            event.stopPropagation();
            handleAddNewSkillSet();
          }}
        />
      </Card>
    </SimpleGrid>
  );
};
