import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useAlert } from "../../components/AlertProvider";
import { handleError } from "../../components/utils";
import { GET_ALL_ORGANIZATIONS } from "./get-all-organizations.query";
import { SAVE_ORGANIZATION } from "./save-organization.mutation";
import { Organization } from "../../types";
import { OrganizationUsers } from "./subcomponents/OrganizationUsers";
import { REGISTER } from "./register.mutation";
import { Box, Divider, Progress, Stack, Grid, GridItem, Text } from "@chakra-ui/react";
import { Autocomplete } from "../../components/AutoComplete";
import { CommonButton } from "../../components/CommonButton";
import { LoadingContainer, loadingContainerFadeIn } from "../../components/LoadingContainer";
import { FormModal } from "../../components/FormModal";
import { CommonInput } from "../../components/CommonInput";

export const Admin: React.FC = () => {
  const {
    data: organizationData,
    loading: organizationDataLoading,
    error: organizationDataError,
  } = useQuery(GET_ALL_ORGANIZATIONS);

  const [saveOrganizationMutation, { error: saveOrganizationError, loading: saveOrganizationLoading }] = useMutation(
    SAVE_ORGANIZATION,
    {
      refetchQueries: [{ query: GET_ALL_ORGANIZATIONS }],
    },
  );

  const [registerNewUserMutation, { error: registerNewUserError, loading: registerNewUserLoading }] = useMutation(
    REGISTER,
    {
      refetchQueries: [{ query: GET_ALL_ORGANIZATIONS }],
    },
  );

  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [isModified, setIsModified] = useState(false);
  const { showAlert, hideAlert } = useAlert();
  const isLoading = organizationDataLoading;
  const [showContent, setShowContent] = useState(!isLoading);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newOrganizationName, setNewOrganizationName] = useState("");

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => setShowContent(true), 300);
    }
  }, [isLoading]);

  useEffect(() => {
    const errors = [organizationDataError, saveOrganizationError, registerNewUserError].filter(Boolean);
    if (errors.length) {
      showAlert(handleError(errors), "error");
    }
    return hideAlert;
  }, [organizationDataError, saveOrganizationError, registerNewUserError, showAlert, hideAlert]);

  const allOrganizations: Organization[] = useMemo(
    () => organizationData?.getAllOrganizations || [],
    [organizationData],
  );

  const handleOrganizationChange = (newOrganization: Organization) => {
    setSelectedOrganization(newOrganization);
  };

  const handleSaveChanges = async (newUserName?: string, newPassword?: string) => {
    if (!selectedOrganization) {
      return;
    }
    const { __typename, users, ...dataToSave } = selectedOrganization;
    try {
      const { data: organizationResponse } = await saveOrganizationMutation({
        variables: { data: dataToSave },
      });

      if (newUserName && newPassword) {
        await registerNewUserMutation({
          variables: {
            username: newUserName,
            password: newPassword,
            organizationId: organizationResponse.saveOrganization.id,
          },
        });
      }

      showAlert("Organization saved successfully!", "success", 5000);
      setIsModified(false);
    } catch (error) {
      showAlert(handleError([error]), "error");
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
    setNewOrganizationName("");
  };

  const handleCreateNewOrganization = async () => {
    if (newOrganizationName.trim()) {
      try {
        const { data: organizationResponse } = await saveOrganizationMutation({
          variables: { data: { name: newOrganizationName } },
        });
        showAlert("Organization created successfully!", "success", 5000);
        setSelectedOrganization(organizationResponse.saveOrganization);
        setIsModified(false);
      } catch (error) {
        showAlert(handleError([error]), "error");
      }
      setIsDialogOpen(false);
    } else {
      alert("Organization name cannot be empty.");
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleUpdateUserAndPassword = (username: string, password: string) => {
    handleSaveChanges(username, password);
  };

  return (
    <Box id="admin">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Stack direction="column" spacing="4" animation={!isLoading ? `${loadingContainerFadeIn} 0.3s` : undefined}>
          <Stack direction="row" justifyContent="space-between" spacing="6" alignItems="center">
            <Autocomplete
              allOptions={allOrganizations}
              handleOptionChange={handleOrganizationChange}
              getOptionLabel={(organization) => organization.name}
            />
            {saveOrganizationLoading || registerNewUserLoading ? (
              <Progress />
            ) : (
              <CommonButton variantType="solidPrimary" disabled={!isModified} onClick={handleSaveChanges}>
                Save
              </CommonButton>
            )}
            <CommonButton variantType="outlineSecondary" onClick={handleOpenDialog}>
              Create new Organization
            </CommonButton>
          </Stack>
          {selectedOrganization && (
            <>
              <Divider />
              <OrganizationUsers
                key={selectedOrganization.id}
                organizationUsers={selectedOrganization.users || []}
                onUserAndPasswordChange={handleUpdateUserAndPassword}
              />
            </>
          )}
        </Stack>
      )}
      <FormModal
        open={isDialogOpen}
        title="Create new Organization"
        submitButtonText="Create"
        handleSubmit={handleCreateNewOrganization}
        onClose={handleCloseDialog}
        submitDisabled={!newOrganizationName.trim()}
      >
        <Grid templateColumns="repeat(2, 1fr)" paddingTop="1">
          <GridItem colSpan={2}>
            <CommonInput
              placeholder="Organization name"
              value={newOrganizationName}
              onChange={(value) => setNewOrganizationName(value)}
            />
          </GridItem>
        </Grid>
      </FormModal>
    </Box>
  );
};
