import React from "react";
import { FormControl, FormLabel, Stack, Switch, Text } from "@chakra-ui/react";

/** Props for the SwitchWithLabel component. */
export type SwitchWithLabelProps = {
  /** Whether the switch is checked or not. */
  checked: boolean;
  /** The label to display next to the switch. */
  label: string;
  /** Function to be called when the switch is toggled. */
  onChange: () => void;
  /** The width of the component, defaults to "fit-content". */
  width?: string;
  /** Data test id for the form control. */
  dataTestId?: string;
};

/**
 * A component that renders a switch with a label. The switch's label, checked state, and onChange handler are
 * controlled via props.
 */
export const SwitchWithLabel: React.FC<SwitchWithLabelProps> = (props) => {
  return (
    <FormControl
      display="flex"
      width={props.width || "fit-content"}
      height="42px"
      border="1px solid"
      shadow={props.checked ? "sm" : "base"}
      borderColor={props.checked ? "orange.500" : "blackAlpha.300"}
      borderRadius="md"
      transition="border-color 0.2s ease, box-shadow 0.2s ease"
      _hover={{ borderColor: "orange.500", shadow: "sm" }}
      onClick={props.onChange}
      data-testid={props.dataTestId || "form-control"}
    >
      <FormLabel margin={0}>
        <Stack
          direction="row"
          spacing="2"
          padding="8px"
          display="flex"
          alignItems="center"
          cursor="pointer"
          data-testid="stack"
        >
          <Switch isChecked={props.checked} onChange={props.onChange} colorScheme="orange" />
          <Stack direction="row" spacing={1} alignItems="center">
            <Text>{props.label}</Text>
          </Stack>
        </Stack>
      </FormLabel>
    </FormControl>
  );
};
