import React, { useState } from "react";
import { Box, Text, Divider, Stack, Tag, Tooltip, TagLabel } from "@chakra-ui/react";
import { Player, type Note } from "../../../types";
import { CommonButton } from "../../../components/CommonButton";
import { CommonTextArea } from "../../../components/CommonTextArea";
import { extractDateFromUTC, extractTimeFromUTC } from "../../Calendar/utils";
import { extractFormattedTime } from "../../../components/utils";
import { CommonScrollbar } from "../../../components/CommonScrollbar/CommonScrollbar";
import { LoadingOverlay } from "../../../components/LoadingOverlay";

type NotesProps = {
  player: Player | null;
  onSave: (newNote: string) => void;
  /** Whether the component is loading */
  isLoading?: boolean;
};

type DisplayNote = Note & {
  tags?: {
    label: string;
    tooltip: React.ReactNode;
  }[];
};

export const Notes: React.FC<NotesProps> = ({ player, onSave, isLoading }) => {
  const [newNote, setNewNote] = useState("");

  const handleSaveNote = () => {
    if (newNote.trim() !== "") {
      onSave(newNote);
      setNewNote("");
    }
  };

  const displayNotes: DisplayNote[] =
    player?.notes?.map((note) => {
      const tags = [];

      if (note.isGroupNote) {
        tags.push({
          label: "Group",
          tooltip: (
            <Text fontSize="medium" color="whiteAlpha.900">
              Note relates to entire training group
            </Text>
          ), // TODO: group information, once that is implemented (recurring training event)
        });
      }

      // TODO: if training event is deleted, this information is lost
      // maybe then tag to indicate that related event is deleted? needs new field to NoteEntity
      if (!!note.recurringTrainingEventMetadata || !!note.singleTrainingEvent) {
        const trainingEvent = note.recurringTrainingEventMetadata || note.singleTrainingEvent;
        const trainingEventDate = extractDateFromUTC(trainingEvent?.startDateTime || "", ".", true);
        const trainingEventStartTime = extractTimeFromUTC(trainingEvent?.startDateTime || "");
        const trainingEventEndTime = extractTimeFromUTC(trainingEvent?.endDateTime || "");

        const tooltip = (
          <Stack direction="column" spacing="2" padding="1" borderRadius="md">
            <Text fontSize="medium" color="whiteAlpha.900">
              Note relates to a training event:
            </Text>
            <Text fontSize="medium" color="whiteAlpha.900">
              - Time: {`${trainingEventDate}  (${trainingEventStartTime}-${trainingEventEndTime})`}
            </Text>
            <Text fontSize="medium" color="whiteAlpha.900">
              - Place: {trainingEvent?.court?.name || ""}
            </Text>
            <Text fontSize="medium" color="whiteAlpha.900">
              - Coach: {trainingEvent?.coaches?.map((coach) => coach.name).join(", ") || "Not assigned"}
            </Text>
            <Text fontSize="medium" color="whiteAlpha.900">
              - Players: {trainingEvent?.players?.map((player) => player.name).join(", ") || ""}
            </Text>
          </Stack>
        );

        tags.push({
          label: "Training",
          tooltip: tooltip,
        });
      }

      return {
        ...note,
        tags: tags.length > 0 ? tags : undefined,
      };
    }) || [];

  return (
    <Box
      position="relative"
      width="100%"
      maxHeight="50svh"
      overflow="hidden"
      padding="6"
      border="1px solid"
      borderColor="blackAlpha.300"
      borderRadius="md"
      boxShadow="base"
      transition="all 0.3s ease"
      _hover={{ boxShadow: "lg", borderColor: "blackAlpha.50" }}
    >
      <LoadingOverlay display={isLoading || false} spinnerSize="xl" spinnerTopPosition="60px" />
      <Text fontSize="large" marginBottom="6" color="blackAlpha.900">
        Notes ({player?.notes?.length || 0})
      </Text>
      <CommonScrollbar overflow="auto" maxHeight="calc(100% - 51px - 104px - 80px)">
        {displayNotes.length > 0 ? (
          <Stack direction="column" spacing="6" align="stretch" marginBottom="6">
            {displayNotes.map((note, index) => (
              <Box
                key={index}
                padding="4"
                backgroundColor="blackAlpha.50"
                borderRadius="md"
                boxShadow="base"
                borderColor="blackAlpha.300"
                transition="all 0.3s ease"
                _hover={{ backgroundColor: "white", boxShadow: "lg", borderColor: "blackAlpha.50" }}
              >
                <Stack direction="column" spacing="2">
                  {note.tags && (
                    <Stack direction="row" spacing="2">
                      {note.tags.map((tag, index) => (
                        <Tag
                          key={index}
                          size="md"
                          height="fit-content"
                          borderRadius="full"
                          variant="solid"
                          colorScheme="orange"
                        >
                          <Tooltip hasArrow label={tag.tooltip} aria-label={`${tag.label} tag`} placement="top">
                            <TagLabel>{tag.label}</TagLabel>
                          </Tooltip>
                        </Tag>
                      ))}
                    </Stack>
                  )}
                  <Text fontWeight="medium" whiteSpace="pre-wrap" marginBottom="3" color="blackAlpha.900">
                    {note.content}
                  </Text>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Text fontSize="xs" color="blackAlpha.600">
                    {note.createdBy?.username}
                  </Text>
                  <Stack direction="row" spacing="4">
                    <Text fontSize="xs" color="blackAlpha.600">
                      {extractDateFromUTC(note.createdAt || "")}
                    </Text>
                    <Text fontSize="xs" color="blackAlpha.600">
                      {extractFormattedTime(note.createdAt || "")}
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            ))}
          </Stack>
        ) : (
          <Text fontSize="sm" color="blackAlpha.600">
            No notes
          </Text>
        )}
      </CommonScrollbar>
      <Divider marginY="6" borderColor="blackAlpha.300" />
      <Box marginBottom="6">
        <CommonTextArea
          placeholder="Add a note"
          value={newNote}
          onChange={(value) => setNewNote(value)}
          minHeight="80px"
          dataTestId="add-manual-note"
        />
      </Box>
      <Box width="100%" textAlign="end">
        <CommonButton
          variantType="solidPrimary"
          onClick={handleSaveNote}
          dataTestId="save-manual-note"
          disabled={newNote.trim() === ""}
        >
          Save Note
        </CommonButton>
      </Box>
    </Box>
  );
};
