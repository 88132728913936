import React, { useEffect, useState } from "react";
import { DrillBuilder } from "./DrillBuilder";
import { Drill, type SkillSet } from "../../../../types";
import { DrillLibrary } from "../../../../components/DrillLibrary";
import { useMutation, useQuery } from "@apollo/client";
import { SAVE_DRILL } from "./save-drill.mutation";
import { GET_ALL_DRILLS } from "./get-all-drills.query";
import { useAlert } from "../../../../components/AlertProvider";
import { handleError } from "../../../../components/utils";
import { Box, Stack, Text } from "@chakra-ui/react";
import { CommonIconButton } from "../../../../components/CommonIconButton";
import { LoadingContainer, loadingContainerFadeIn } from "../../../../components/LoadingContainer";
import { GET_ALL_SKILL_SETS } from "../../../SkillSets/graphql/get-all-skill-sets.query";

type DrillInput = {
  id?: string;
  name: string;
  implementation: string;
  goal?: string;
  tags?: string[];
  skillSetIds?: string[];
  remove?: boolean;
};

/** DrillManager component */
export const DrillManager: React.FC = () => {
  const { data, loading, error: drillDataError } = useQuery(GET_ALL_DRILLS);
  const { data: skillSetData, loading: skillSetDataLoading, error: skillSetDataError } = useQuery(GET_ALL_SKILL_SETS);

  const [selectedDrill, setSelectedDrill] = useState<Drill | null>(null);
  const [drills, setDrills] = useState<Drill[]>([]);
  const [allUniqueTags, setAllUniqueTags] = useState<string[]>([]);
  const [isDrillBuilderOpen, setIsDrillBuilderOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [searchSkillSets, setSearchSkillSets] = useState<SkillSet[]>([]);
  const [saveDrillMutation, { loading: saveDrillLoading, error: saveDrillError }] = useMutation(SAVE_DRILL, {
    refetchQueries: [{ query: GET_ALL_DRILLS }],
    awaitRefetchQueries: true,
  });
  const { showAlert, hideAlert } = useAlert();
  const isLoading = loading || skillSetDataLoading;
  const [showContent, setShowContent] = useState(!isLoading);
  useEffect(() => {
    if (!isLoading) {
      // Timeout needs to match with LoadingContainer animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    }
  }, [isLoading]);

  useEffect(() => {
    if (drillDataError || saveDrillError || skillSetDataError) {
      const errors = [drillDataError, saveDrillError, skillSetDataError].filter(Boolean);
      if (errors.length) {
        showAlert(handleError(errors), "error");
      }
    }
    return () => {
      hideAlert();
    };
  }, [drillDataError, saveDrillError, skillSetDataError]);

  useEffect(() => {
    if (data?.getAllDrills) {
      setDrills(data.getAllDrills);
    }
  }, [data]);

  useEffect(() => {
    if (drills.length > 0) {
      setAllUniqueTags(Array.from(new Set(drills.flatMap((drill) => (drill.tags ? drill.tags : [])))));
    }
  }, [drills]);

  /**
   * Select a drill
   *
   * @param {Drill} drill - The drill to select
   */
  const handleSelectDrill = (drill: Drill) => {
    setSelectedDrill(drill);
    setIsDrillBuilderOpen(true);
  };

  /**
   * Save a drill
   *
   * @param {Drill} drill - The drill to save
   */
  const saveDrill = async (drill: Drill, remove: boolean) => {
    const drillInput: DrillInput = {
      id: drill.id,
      name: drill.name,
      implementation: drill.implementation,
      goal: drill.goal,
      tags: drill.tags,
      skillSetIds: drill.skillSets?.map((skillSet) => skillSet.id || ""),
      remove: remove,
    };

    try {
      if (remove) {
        showAlert("Deleting Drill", "info", undefined, true);
      } else if (drillInput.id) {
        showAlert("Updating Drill", "info", undefined, true);
      } else {
        showAlert("Creating new Drill", "info", undefined, true);
      }

      setSelectedDrill(null);
      setIsDrillBuilderOpen(false);

      const response = await saveDrillMutation({
        variables: {
          data: drillInput,
        },
      });

      if (response.data.saveDrill === null && remove) {
        showAlert("Drill deleted!", "success", 5000);
      } else if (drillInput.id) {
        showAlert("Drill updated!", "success", 5000);
      } else {
        showAlert("Drill created!", "success", 5000);
      }
    } catch {
      // catch silently, error handling is done in the useEffect
    }
  };

  const handleDrillBuilderOpen = () => {
    setIsDrillBuilderOpen(true);
  };

  const handleDrillBuilderClose = () => {
    setSelectedDrill(null);
    setIsDrillBuilderOpen(false);
  };

  /**
   * Change the search query
   *
   * @param {string} newSearchQuery - The new search query
   * @param {string} newSearchTags - The new search tags
   */
  const handleSearchChange = (newSearchQuery: string, newSearchTags: string[], newSearchSkillSets: SkillSet[]) => {
    setSearchQuery(newSearchQuery);
    setSearchTags(newSearchTags);
    setSearchSkillSets(newSearchSkillSets);
  };

  // Filter the drills based on the input(s)
  const filteredDrills = drills.filter((drill) => {
    // Check if the drill matches the search query
    const matchesSearchQuery =
      searchQuery === "" ||
      drill.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      drill.goal.toLowerCase().includes(searchQuery.toLowerCase()) ||
      drill.implementation.toLowerCase().includes(searchQuery.toLowerCase());
    // Check if the drill matches the search tags
    const matchesSearchTags = searchTags.every((searchTag) => drill.tags?.includes(searchTag));
    // Check if the drill matches the search skill sets
    const matchesSearchSkillSets = searchSkillSets.every((searchSkillSet) =>
      drill.skillSets?.some((drillSkillSet) => drillSkillSet.id === searchSkillSet.id),
    );

    // Return the drill if it matches all the search criteria
    return matchesSearchQuery && matchesSearchTags && matchesSearchSkillSets;
  });

  return (
    <Box id="drills">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box animation={isLoading ? undefined : `${loadingContainerFadeIn} 0.3s`} data-testid="drill-manager">
          <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="center">
            <Text fontSize="x-large">Drills</Text>
            <CommonIconButton height="36px" onClick={handleDrillBuilderOpen} dataTestId="add-drill" />
          </Stack>
          <DrillLibrary
            drills={filteredDrills}
            allSkillSets={skillSetData?.getAllSkillSets || []}
            searchSkillSets={searchSkillSets}
            onSelectDrill={handleSelectDrill}
            onSearchChange={handleSearchChange}
            searchQuery={searchQuery}
            searchTags={searchTags}
            scrollbarHeight="75svh"
            displayMetadata={true}
            isLoading={saveDrillLoading}
          />
        </Box>
      )}
      {isDrillBuilderOpen && (
        <DrillBuilder
          onSave={saveDrill}
          selectedDrill={selectedDrill}
          open={isDrillBuilderOpen}
          allTags={allUniqueTags}
          allSkillSets={skillSetData?.getAllSkillSets || []}
          onClose={handleDrillBuilderClose}
        />
      )}
    </Box>
  );
};
