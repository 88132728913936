import { gql } from "@apollo/client";

export const GET_MISSING_RECURRING_TRAININGEVENT_METADATAS = gql`
  query GetMissingRecurringTrainingEventMetadatas {
    getMissingRecurringTrainingEventMetadatas {
      recurringTrainingEventId
      startDateTime
      players {
        id
        name
      }
      court {
        id
        name
      }
      coaches {
        id
        name
      }
    }
  }
`;
