import React, { useEffect, useState } from "react";
import { Coaches } from "./components/Coaches/Coaches";
import { Clubs } from "./components/Clubs/Clubs";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { Season } from "./components/Season/Season";
import { LoadingContainer } from "../../components/LoadingContainer";
import { useQuery } from "@apollo/client";
import { GET_USER_ORGANIZATION } from "./components/Season/get-user-organization.query";
import { GET_MISSING_RECURRING_TRAININGEVENT_METADATAS } from "./components/Season/get-missing-recurring-trainingevent-metadatas.query";
import { GET_ALL_COACHES } from "./components/Coaches/get-all-coaches.query";
import { useAlert } from "../../components/AlertProvider";
import { handleError } from "../../components/utils";
import { GET_ALL_CLUBS } from "./components/Clubs/get-all-clubs.query";

export const Settings: React.FC = () => {
  const {
    data: organizationData,
    loading: organizationDataLoading,
    error: organizationDataError,
  } = useQuery(GET_USER_ORGANIZATION);
  const {
    data: missingRecurringTrainingEventMetadatasData,
    loading: missingRecurringTrainingEventMetadatasLoading,
    error: missingRecurringTrainingEventMetadatasError,
  } = useQuery(GET_MISSING_RECURRING_TRAININGEVENT_METADATAS);
  const { data: coachData, loading: coachDataLoading, error: coachDataError } = useQuery(GET_ALL_COACHES);
  const { data: clubData, loading: clubDataLoading, error: clubDataError } = useQuery(GET_ALL_CLUBS);

  const isLoading =
    organizationDataLoading || missingRecurringTrainingEventMetadatasLoading || coachDataLoading || clubDataLoading;
  const { showAlert, hideAlert } = useAlert();
  const [showContent, setShowContent] = useState(!isLoading);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    } else {
      setShowContent(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (organizationDataError || missingRecurringTrainingEventMetadatasError || coachDataError || clubDataError) {
      const errors = [organizationDataError, coachDataError, clubDataError].filter(Boolean);
      if (errors.length) {
        showAlert(handleError(errors), "error");
      }
    }
    return () => {
      hideAlert();
    };
  }, [organizationDataError, missingRecurringTrainingEventMetadatasError, coachDataError, clubDataError]);

  // TODO: training groups
  return (
    <Box>
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Grid templateColumns="repeat(3, 1fr)" columnGap="12" rowGap="24">
          <GridItem colSpan={3}>
            <Season
              seasonStart={organizationData?.getUserOrganization?.seasonStart}
              seasonEnd={organizationData?.getUserOrganization?.seasonEnd}
              missingMetadata={missingRecurringTrainingEventMetadatasData?.getMissingRecurringTrainingEventMetadatas}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <Coaches allCoaches={coachData?.getAllCoaches} />
          </GridItem>
          <GridItem colSpan={2}>
            <Clubs allClubs={clubData?.getAllClubs} />
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};
