import { gql } from "@apollo/client";

export const SAVE_CLUB = gql`
  mutation SaveClub($data: ClubInput!) {
    saveClub(data: $data) {
      id
      name
      courts {
        id
        name
      }
    }
  }
`;
