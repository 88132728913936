import React from "react";
import { Box, Divider, Grid, Stack, Text } from "@chakra-ui/react";
import { Player } from "../../../types";
import { CommonScrollbar } from "../../../components/CommonScrollbar/CommonScrollbar";
import { extractDateFromUTC } from "../../Calendar/utils";
import { extractFormattedTime } from "../../../components/utils";
import { format, getISOWeek } from "date-fns";
import { LoadingOverlay } from "../../../components/LoadingOverlay";

type LogsProps = {
  player: Player | null;
  /** Whether the component is loading */
  isLoading?: boolean;
};

type LogItemProps = {
  log: any;
  isPast: boolean;
};

const LogItem: React.FC<LogItemProps> = ({ log, isPast }) => {
  const formatWeekday = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "EEEE");
  };

  const formatWeekNumber = (dateString: string) => {
    const date = new Date(dateString);
    return getISOWeek(date);
  };

  return (
    <Box
      padding="4"
      backgroundColor="blackAlpha.50"
      borderRadius="md"
      boxShadow="base"
      borderColor="blackAlpha.300"
      transition="all 0.3s ease"
      _hover={{ backgroundColor: "white", boxShadow: "lg", borderColor: "blackAlpha.50" }}
    >
      <Box fontWeight="medium" whiteSpace="pre-wrap" marginBottom="3">
        <Box as="dl">
          <Grid templateColumns="min-content 1fr" columnGap="4">
            <Text as="dt" color="blackAlpha.600">
              Date
            </Text>
            <Text as="dd" color={isPast ? "blackAlpha.600" : undefined}>
              {formatWeekday(log.recurringTrainingEventMetadata.startDateTime)},{" "}
              {extractDateFromUTC(log.recurringTrainingEventMetadata.startDateTime)} (week{" "}
              {formatWeekNumber(log.recurringTrainingEventMetadata.startDateTime)})
            </Text>
            <Text as="dt" color="blackAlpha.600">
              Time
            </Text>
            <Text as="dd" color={isPast ? "blackAlpha.600" : undefined}>
              {extractFormattedTime(log.recurringTrainingEventMetadata.startDateTime)} -{" "}
              {extractFormattedTime(log.recurringTrainingEventMetadata.endDateTime)}
            </Text>
            <Text as="dt" color="blackAlpha.600">
              Place
            </Text>
            <Text as="dd" color={isPast ? "blackAlpha.600" : undefined}>
              {log.recurringTrainingEventMetadata.court.club.name}, {log.recurringTrainingEventMetadata.court.name}
            </Text>
          </Grid>
        </Box>
      </Box>
      <Text fontSize="xs" color="blackAlpha.600">
        Automated message
      </Text>
    </Box>
  );
};

export const Logs: React.FC<LogsProps> = (props) => {
  const currentDateTime = new Date().toISOString();

  const pastLogs = props.player?.logs?.filter(
    (log) => log.recurringTrainingEventMetadata.endDateTime < currentDateTime,
  );
  const upcomingLogs = props.player?.logs?.filter(
    (log) => log.recurringTrainingEventMetadata.startDateTime > currentDateTime,
  );

  return (
    <Box
      position="relative"
      width="100%"
      maxHeight="50svh"
      overflow="hidden"
      padding="6"
      border="1px solid"
      borderColor="blackAlpha.300"
      borderRadius="md"
      boxShadow="base"
      transition="all 0.3s ease"
      _hover={{ boxShadow: "lg", borderColor: "blackAlpha.50" }}
    >
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="60px" />
      <Text fontSize="large" marginBottom="6" color="blackAlpha.900">
        Logs ({props.player?.logs?.length || 0})
      </Text>
      <CommonScrollbar overflow="auto" maxHeight="calc(100% - 51px)">
        {upcomingLogs?.length ? (
          <Stack direction="column" spacing="6" align="stretch" marginBottom="6">
            <Text>Upcoming weekly trainings</Text>
            {upcomingLogs.map((log, index) => (
              <LogItem key={index} log={log} isPast={false} />
            ))}
          </Stack>
        ) : (
          <Text color="blackAlpha.600">No upcoming weekly trainings</Text>
        )}
        <Divider marginY="6" borderColor="blackAlpha.300" />
        {pastLogs?.length ? (
          <Stack direction="column" spacing="6" align="stretch" marginBottom="6">
            <Text color="blackAlpha.600">Past weekly trainings</Text>
            {pastLogs.map((log, index) => (
              <LogItem key={index} log={log} isPast={true} />
            ))}
          </Stack>
        ) : (
          <Text color="blackAlpha.600">No past weekly trainings</Text>
        )}
      </CommonScrollbar>
    </Box>
  );
};
