import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { GET_ALL_COACHES } from "./get-all-coaches.query";
import { SAVE_COACH } from "./save-coach.mutation";
import { Coach } from "../../../../types";
import { useAlert } from "../../../../components/AlertProvider";
import { handleError, sortNames } from "../../../../components/utils";
import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { FormModal } from "../../../../components/FormModal";
import { CommonButton } from "../../../../components/CommonButton";
import { CommonIconButton } from "../../../../components/CommonIconButton";
import { CommonInput } from "../../../../components/CommonInput";
import { LoadingOverlay } from "../../../../components/LoadingOverlay";

type CoachesProps = {
  allCoaches: Coach[];
};

export const Coaches: React.FC<CoachesProps> = (props) => {
  const [saveCoachMutation, { error: saveCoachError, loading: saveCoachLoading }] = useMutation(SAVE_COACH, {
    refetchQueries: [{ query: GET_ALL_COACHES }],
    awaitRefetchQueries: true,
  });
  const [selectedCoach, setSelectedCoach] = useState<Coach | null>(null);
  const [isNewCoach, setIsNewCoach] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { showAlert, hideAlert } = useAlert();

  useEffect(() => {
    if (saveCoachError) {
      const errors = [saveCoachError].filter(Boolean);
      if (errors.length) {
        showAlert(handleError(errors), "error");
      }
    }
    return () => {
      hideAlert();
    };
  }, [saveCoachError]);

  const handleOpenDialog = (coach: Coach) => {
    setSelectedCoach(coach);
    setIsDialogOpen(true);
  };

  const saveCoach = async (remove: boolean) => {
    if (remove) {
      showAlert("Deleting Coach", "info", undefined, true);
    } else if (selectedCoach?.id) {
      showAlert("Updating Coach", "info", undefined, true);
    } else {
      showAlert("Creating new Coach", "info", undefined, true);
    }

    setIsNewCoach(false);
    setIsDialogOpen(false);

    try {
      const { __typename, ...dataToSave } = selectedCoach || {};
      await saveCoachMutation({
        variables: {
          data: { ...dataToSave, remove: remove },
        },
      });
      if (remove) {
        showAlert("Coach deleted!", "success", 5000);
      } else if (selectedCoach?.id) {
        showAlert("Coach updated!", "success", 5000);
      } else {
        showAlert("Coach created!", "success", 5000);
      }
    } catch {
      // catch silently, error handling is done in the useEffect
    }
  };

  const handleSave = () => {
    saveCoach(false);
  };

  const handleRemove = () => {
    saveCoach(true);
  };

  const handleClose = () => {
    setIsNewCoach(false);
    setIsDialogOpen(false);
  };

  const allCoachNames = props.allCoaches.map((coach) => coach.name.toLowerCase());

  const getSubmitDisabledReasons = (): string | undefined => {
    if (allCoachNames.includes(selectedCoach?.name.toLowerCase() || "")) {
      return `Coach "${selectedCoach?.name}" already exists`;
    } else if (selectedCoach?.name.trim() === "") {
      return "Coach name cannot be empty";
    }

    return undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  return (
    <Box position="relative" data-testid="coaches">
      <LoadingOverlay display={saveCoachLoading || false} spinnerSize="xl" spinnerTopPosition="40px" />
      <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="center">
        <Text fontSize="x-large">Coaches</Text>
        <CommonIconButton
          height="36px"
          onClick={() => {
            setIsNewCoach(true);
            handleOpenDialog({ name: "New Coach" });
          }}
          dataTestId="add-coach"
        />
      </Stack>
      <Grid
        templateColumns="repeat(auto-fit, minmax(calc(25% - 12px), calc(25% - 12px)))"
        gap="4"
        justifyContent="center"
      >
        {props.allCoaches.length === 0 ? (
          <Text fontSize="large" textAlign="center" data-testid="no-coaches">
            No coaches
          </Text>
        ) : (
          props.allCoaches
            .slice()
            .sort((a, b) => sortNames(a.name, b.name))
            .map((coach, index) => (
              <GridItem key={index} colSpan={1} data-testid="coach-grid">
                <CommonButton
                  variantType="outlineSecondary"
                  fullWidth
                  onClick={() => handleOpenDialog(coach)}
                  dataTestId={`coach-${index}`}
                >
                  <Text fontSize="medium" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {coach.name}
                  </Text>
                </CommonButton>
              </GridItem>
            ))
        )}
      </Grid>
      <FormModal
        open={isDialogOpen}
        title={isNewCoach ? "Create new Coach" : "Edit Coach"}
        submitButtonText={isNewCoach ? "Create" : "Confirm"}
        handleSubmit={handleSave}
        onClose={handleClose}
        handleRemove={handleRemove}
        confirmationDialogTitle="Delete Coach"
        submitDisabled={!!submitDisabledReasons}
        submitButtonHoverText={submitDisabledReasons}
      >
        <Grid templateColumns="repeat(2, 1fr)" paddingTop="1" data-testid="coach-modal">
          <GridItem colSpan={2}>
            <CommonInput
              placeholder="Coach name"
              value={selectedCoach?.name || ""}
              onChange={(value) => {
                setSelectedCoach({ ...selectedCoach, name: value });
              }}
              dataTestId="coach-name"
            />
          </GridItem>
        </Grid>
      </FormModal>
    </Box>
  );
};
