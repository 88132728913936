import React, { useState, useEffect } from "react";
import { Court } from "../../../../types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { SAVE_COURT } from "./save-court.mutation";
import { useAlert } from "../../../../components/AlertProvider";
import { handleError, sortNames } from "../../../../components/utils";
import { useGlobalContext } from "../../../../components/GlobalProvider";
import { GET_CLUB } from "./get-club.query";
import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { FormModal } from "../../../../components/FormModal";
import { CommonButton } from "../../../../components/CommonButton";
import { CommonIconButton } from "../../../../components/CommonIconButton";
import { CommonInput } from "../../../../components/CommonInput";
import { LoadingOverlay } from "../../../../components/LoadingOverlay";

type CourtsProps = {
  title: string;
  allCourts: Court[];
  clubId: string;
  isLoading?: boolean;
};

export const Courts: React.FC<CourtsProps> = (props) => {
  const { globalSetSelectedClub } = useGlobalContext();
  const [getClub, { loading: clubDataLoading, error: clubDataError }] = useLazyQuery(GET_CLUB, {
    variables: { id: props.clubId },
    fetchPolicy: "network-only",
  });
  const [saveCourtMutation, { error: saveCourtError, loading: saveCourtLoading }] = useMutation(SAVE_COURT);
  const [selectedCourt, setSelectedCourt] = useState<Court | null>(null);
  const [isNewCourt, setIsNewCourt] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { showAlert, hideAlert } = useAlert();

  useEffect(() => {
    if (saveCourtError || clubDataError) {
      const errors = [saveCourtError, clubDataError].filter(Boolean);
      if (errors.length) {
        showAlert(handleError(errors), "error");
      }
    }
    return () => {
      hideAlert();
    };
  }, [saveCourtError, clubDataError]);

  const handleOpenDialog = (court: Court) => {
    setSelectedCourt(court);
    setIsDialogOpen(true);
  };

  const saveCourt = async (remove: boolean) => {
    if (remove) {
      showAlert("Deleting Court", "info", undefined, true);
    } else if (selectedCourt?.id) {
      showAlert("Updating Court", "info", undefined, true);
    } else {
      showAlert("Creating new Court", "info", undefined, true);
    }

    setIsNewCourt(false);
    setIsDialogOpen(false);

    try {
      const { __typename, ...dataToSave } = selectedCourt || {};
      const response = await saveCourtMutation({
        variables: {
          data: { ...dataToSave, remove: remove },
          clubId: props.clubId,
        },
      });

      // Update selected club to get the updated courts
      const clubResponse = await getClub();
      const updateClub = clubResponse.data.getClub;
      if (updateClub) {
        globalSetSelectedClub(updateClub);
      }

      if (response.data.saveCourt === null && remove) {
        showAlert("Court deleted!", "success", 5000);
      } else if (selectedCourt?.id) {
        showAlert("Court updated!", "success", 5000);
      } else {
        showAlert("Court created!", "success", 5000);
      }
    } catch {
      // catch silently, error handling is done in the useEffect
    }
  };

  const handleSubmit = () => {
    saveCourt(false);
  };

  const handleRemove = () => {
    saveCourt(true);
  };

  const handleClose = () => {
    setIsNewCourt(false);
    setIsDialogOpen(false);
  };

  const getSubmitDisabledReasons = (): string | undefined => {
    const courtNames = props.allCourts.map((court) => court.name.toLowerCase());
    if (courtNames.includes(selectedCourt?.name.toLowerCase() || "")) {
      return `Court "${selectedCourt?.name}" already exists`;
    } else if (selectedCourt?.name.trim() === "") {
      return "Court name cannot be empty";
    }

    return undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  return (
    <Box position="relative" data-testid="courts">
      <LoadingOverlay
        display={props.isLoading || clubDataLoading || saveCourtLoading || false}
        spinnerSize="xl"
        spinnerTopPosition="40px"
      />
      <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="center">
        <Text fontSize="x-large">{props.title}</Text>
        <CommonIconButton
          height="36px"
          onClick={() => {
            setIsNewCourt(true);
            handleOpenDialog({ name: "New Court" });
          }}
          dataTestId="add-court"
        />
      </Stack>
      <Grid
        templateColumns="repeat(auto-fit, minmax(calc(25% - 12px), calc(25% - 12px)))"
        gap="4"
        justifyContent="center"
      >
        {props.allCourts.length === 0 ? (
          <Text fontSize="large" textAlign="center" data-testid="no-courts">
            No courts
          </Text>
        ) : (
          props.allCourts
            .slice()
            .sort((a, b) => sortNames(a.name, b.name))
            .map((court, index) => (
              <GridItem key={index} colSpan={1} data-testid="court-grid">
                <CommonButton
                  variantType="outlineSecondary"
                  fullWidth
                  onClick={() => handleOpenDialog(court)}
                  dataTestId={`court-${index}`}
                >
                  <Text fontSize="medium" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {court.name}
                  </Text>
                </CommonButton>
              </GridItem>
            ))
        )}
      </Grid>
      <FormModal
        key={selectedCourt?.id}
        handleSubmit={handleSubmit}
        open={isDialogOpen}
        submitButtonText={isNewCourt ? "Create" : "Confirm"}
        title={isNewCourt ? "Create new Court" : "Edit Court"}
        onClose={handleClose}
        handleRemove={handleRemove}
        confirmationDialogTitle="Delete Court"
        submitDisabled={!!submitDisabledReasons}
        submitButtonHoverText={submitDisabledReasons}
      >
        <Grid templateColumns="repeat(2, 1fr)" paddingTop="1" data-testid="court-modal">
          <GridItem colSpan={2}>
            <CommonInput
              placeholder="Court name"
              value={selectedCourt?.name || ""}
              onChange={(value) => {
                setSelectedCourt({ ...selectedCourt, name: value });
              }}
              dataTestId="court-name"
            />
          </GridItem>
        </Grid>
      </FormModal>
    </Box>
  );
};
