import { FormControl, FormLabel, InputGroup, Textarea } from "@chakra-ui/react";
import React from "react";

/** Props for the CommonTextArea component. */
export type CommonTextAreaProps = {
  /** The value of the text area. */
  value: string;
  /** The placeholder text for the text area. */
  placeholder: string;
  /** The function to call when the text area value changes. */
  onChange: (value: string) => void;
  /** Whether the text area is disabled. */
  disabled?: boolean;
  /** Whether the text area is required. */
  required?: boolean;
  /** Whether the text area shadow is disabled. */
  disableShadow?: boolean;
  /** The minimum height of the text area. */
  minHeight?: string;
  /** The data-testid attribute to be added to the component */
  dataTestId?: string;
};

/** A common text area component that supports different customization options. */
export const CommonTextArea: React.FC<CommonTextAreaProps> = (props) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange(event.target.value);
  };

  return (
    <InputGroup>
      <FormControl id="common-textarea">
        <FormLabel
          zIndex={2}
          top="-5px"
          lineHeight="10px"
          marginLeft="10px"
          position="absolute"
          backgroundColor="white"
          paddingLeft="4px"
          paddingRight="4px"
          width="fit-content"
          fontSize="small"
          color="blackAlpha.600"
          display={props.value ? "flex" : "none"}
        >
          {props.placeholder}
        </FormLabel>
        <Textarea
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleChange}
          required={props.required}
          minHeight={props.minHeight}
          opacity={props.disabled ? 0.6 : 1}
          shadow={props.disableShadow ? "none" : "base"}
          borderColor="blackAlpha.300"
          transition="all 0.3s ease"
          _hover={{ borderColor: "orange.500", shadow: props.disableShadow ? undefined : "sm" }}
          _focus={{ boxShadow: "none" }}
          data-testid={props.dataTestId}
        />
      </FormControl>
    </InputGroup>
  );
};
