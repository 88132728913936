import React from "react";
import { DrillCard } from "../DrillCard";
import { Drill, type SkillSet } from "../../types";
import { MultiTagSelectMenu } from "../MultiTagSelectMenu";
import { Box, Divider, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { CommonInput } from "../CommonInput";
import { CommonScrollbar } from "../CommonScrollbar/CommonScrollbar";
import { LoadingOverlay } from "../LoadingOverlay";

/** Props for the DrillLibrary component. */
type DrillLibraryProps = {
  /** Callback function for selecting a drill */
  onSelectDrill: (drill: Drill) => void;
  /** Callback function for changing search */
  onSearchChange: (searchQuery: string, searchTags: string[], searchSkillSets: SkillSet[]) => void;
  /** Array of drills */
  drills: Drill[];
  /** Current search query */
  searchQuery: string;
  /** Current search tags */
  searchTags: string[];
  /** Array of all skill sets */
  allSkillSets: SkillSet[];
  /** Current search SkillSets */
  searchSkillSets: SkillSet[];
  /** Display drill card checkbox on click */
  showDrillCardCheckbox?: boolean;
  /** Selected drills */
  selectedDrills?: Drill[];
  /** Whether the shadow is disabled */
  disableShadow?: boolean;
  /** Height of the CommonScrollbar element */
  scrollbarHeight?: string;
  /** Whether the user is using a mobile device */
  isMobile?: boolean;
  /** Whether to display created by & created at on DrillCard */
  displayMetadata?: boolean;
  /** Whether the component is loading */
  isLoading?: boolean;
};

/** DrillLibrary component */
export const DrillLibrary: React.FC<DrillLibraryProps> = (props) => {
  const allTags = Array.from(new Set(props.drills.flatMap((drill) => (drill.tags ? drill.tags : []))));

  const handleSearchChange = (value: string) => {
    props.onSearchChange(value, props.searchTags, props.searchSkillSets);
  };

  const handleTagChange = (tags: string[]) => {
    props.onSearchChange(props.searchQuery, tags, props.searchSkillSets);
  };

  const handleSkillSetChange = (skillSets: SkillSet[]) => {
    props.onSearchChange(props.searchQuery, props.searchTags, skillSets);
  };

  return (
    <Box data-testid="drill-library" height="100%" display="flex" flexDirection="column" position="relative">
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="120px" />
      <Grid templateColumns="repeat(3, 1fr)" columnGap="2" rowGap="6" marginBottom="6">
        <GridItem colSpan={{ mobile: 3, laptop: 1 }}>
          <CommonInput
            disableShadow={props.disableShadow}
            placeholder="Filter by name, goal or implementation"
            value={props.searchQuery}
            onChange={handleSearchChange}
            dataTestId="search-drills"
          />
        </GridItem>
        <GridItem colSpan={{ mobile: 3, laptop: 1 }}>
          <MultiTagSelectMenu
            disableShadow={props.disableShadow}
            selectedTags={props.searchTags}
            handleTagSelection={handleTagChange}
            label="Filter by tags"
            options={allTags}
            getOptionLabel={(option) => option}
          />
        </GridItem>
        <GridItem colSpan={{ mobile: 3, laptop: 1 }}>
          <MultiTagSelectMenu
            disableShadow={props.disableShadow}
            selectedTags={props.searchSkillSets}
            handleTagSelection={handleSkillSetChange}
            label="Filter by Skill Sets"
            options={props.allSkillSets || []}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            dataTestId="skill-set-filter"
          />
        </GridItem>
        <GridItem colSpan={3}>
          <Divider />
        </GridItem>
      </Grid>
      <CommonScrollbar overflow="auto" height={props.scrollbarHeight} isMobile={props.isMobile}>
        <Stack direction="column" spacing={{ mobile: "4", laptop: "2" }}>
          {props.drills.length === 0 ? (
            <Text fontSize="large" textAlign="center">
              {props.searchQuery || props.searchTags || props.searchSkillSets
                ? "No drills match the applied filters. Clear filters to view all drills."
                : "No drills available"}
            </Text>
          ) : (
            props.drills.map((drill, index) => (
              <DrillCard
                disableShadow={props.disableShadow}
                key={index}
                drill={drill}
                selected={!!props.selectedDrills?.find((d) => d.id === drill.id)}
                onSelectDrill={props.onSelectDrill}
                showCheckbox={props.showDrillCardCheckbox}
                displayMetadata={props.displayMetadata}
                isMobile={props.isMobile}
                dataTestId={`drill-card-${index}`}
              />
            ))
          )}
        </Stack>
      </CommonScrollbar>
    </Box>
  );
};
