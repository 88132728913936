import React from "react";
import { Button, Tooltip, type ButtonProps } from "@chakra-ui/react";

/** Props for the CommonButton component. */
export type CommonButtonProps = {
  /** The variant type of the button. */
  variantType: "outlineSecondary" | "solidPrimary";
  /** The content to be displayed inside the button. */
  children: React.ReactNode;
  /** The function to be called when the button is clicked. */
  onClick?: () => void;
  /** Whether the button is disabled. */
  disabled?: boolean;
  /** Whether the button shadow is disabled. */
  disableShadow?: boolean;
  /** Whether the button hover effect is disabled. */
  disableHover?: boolean;
  /** The type of the button. */
  buttonType?: ButtonProps["type"];
  /** Whether the button should take up the full width of its container. */
  fullWidth?: boolean;
  /** The color of the button, defaults to orange. */
  color?: string;
  /** The color of the button on hover, defaults to orange. */
  hoverColor?: string;
  /** Tooltip text to be displayed on hover */
  tooltip?: string;
  /** The data-testid attribute to be added to the button. */
  dataTestId?: string;
};

/** A common button component that supports different variants and customization options. */
export const CommonButton: React.FC<CommonButtonProps> = (props) => {
  const disableShadow = props.disableShadow;
  const disableHover = props.disabled || props.disableHover;

  const commonButtonProps: ButtonProps = {
    width: props.fullWidth ? "100%" : "auto",
    borderRadius: "md",
    onClick: props.onClick,
    isDisabled: props.disabled,
    type: props.buttonType,
    shadow: disableShadow ? "none" : "base",
    transition: "box-shadow 0.3s ease, border-color 0.3s ease, background-color 0.3s ease",
  };

  const variantProps: { outlineSecondary: ButtonProps; solidPrimary: ButtonProps } = {
    outlineSecondary: {
      ...commonButtonProps,
      variant: "outline",
      borderColor: "blackAlpha.300",
      _hover: disableHover
        ? {}
        : { shadow: disableShadow ? "none" : "inner", borderColor: props.hoverColor || "orange.400" },
    },
    solidPrimary: {
      ...commonButtonProps,
      variant: "outline",
      color: "white",
      bgColor: props.color || "orange.400",
      borderColor: props.color || "orange.400",
      _hover: disableHover
        ? {}
        : {
            shadow: disableShadow ? "none" : "inner",
            backgroundColor: props.hoverColor || "orange.500",
            borderColor: "blackAlpha.300",
          },
    },
  };

  return (
    <Tooltip label={props.tooltip} isDisabled={!props.tooltip} placement="top">
      <Button {...variantProps[props.variantType]} data-testid={props.dataTestId}>
        {props.children}
      </Button>
    </Tooltip>
  );
};
