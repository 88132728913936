import React, { useState } from "react";
import type { Coach, Court, RecurringTrainingEvent } from "../../../types";
import { Stack } from "@chakra-ui/react";
import { EventEditorRow } from "./EventEditorRow";
import { CommonButton } from "../../../components/CommonButton";

/** Component for editing an existing group */
export const GroupEditor: React.FC<{
  event: RecurringTrainingEvent;
  allCoaches: Coach[];
  allCourts: Court[];
  onSave: (event: RecurringTrainingEvent) => void;
  onCancel: () => void;
  disabled?: boolean;
  readonly?: boolean;
  timeAndPlaceDisabled?: boolean;
}> = ({ event, allCoaches, allCourts, onSave, onCancel, disabled, readonly, timeAndPlaceDisabled }) => {
  const [eventToEdit, setEventToEdit] = useState(event);

  const handleChange = (field: keyof RecurringTrainingEvent, value: any) => {
    setEventToEdit((prevEvent) => {
      const updatedEvent = { ...prevEvent };
      switch (field) {
        case "recurrenceWeekday":
          updatedEvent.recurrenceWeekday = value as number;
          break;
        case "recurrenceStartTime":
          updatedEvent.recurrenceStartTime = value as string;
          break;
        case "recurrenceEndTime":
          updatedEvent.recurrenceEndTime = value as string;
          break;
        case "court":
          updatedEvent.court = allCourts.find((court) => court.id === value) as Court;
          break;
        case "coaches":
          updatedEvent.coaches = allCoaches.filter((coach) => (value as string[]).includes(coach.id || ""));
          break;
        case "name":
          updatedEvent.name = value as string;
          break;
      }
      return updatedEvent;
    });
  };

  const handleSave = () => {
    onSave(eventToEdit);
  };

  return (
    <Stack direction="row" spacing="4" flex={1}>
      <EventEditorRow
        event={eventToEdit}
        allCoaches={allCoaches}
        allCourts={allCourts}
        onChange={handleChange}
        allDisabled={disabled || readonly}
        timeAndPlaceDisabled={timeAndPlaceDisabled}
      />
      {!readonly && (
        <Stack direction="row" spacing="4" minWidth="160px">
          <CommonButton
            fullWidth
            variantType="outlineSecondary"
            onClick={onCancel}
            dataTestId="cancel-edit-recurring-training-event"
          >
            Cancel
          </CommonButton>
          <CommonButton
            fullWidth
            variantType="solidPrimary"
            onClick={handleSave}
            disabled={!eventToEdit.name || eventToEdit.name?.trim() === ""}
            tooltip={!eventToEdit.name || eventToEdit.name?.trim() === "" ? "Group name cannot be empty" : ""}
            dataTestId="save-edit-recurring-training-event"
          >
            Save
          </CommonButton>
        </Stack>
      )}
    </Stack>
  );
};
