import React from "react";
import {
  Box,
  Image,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Text,
  Divider,
  Stack,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Logout } from "@mui/icons-material";
import { NavigationLink } from "./components";
import Striveon from "../../assets/StriveonCroppedPadding.png";
import { useGlobalContext } from "../../components/GlobalProvider";
import { CommonIconButton } from "../../components/CommonIconButton";
import { CommonSelect } from "../../components/CommonSelect";
import { links, type HeaderProps } from "./Header";

export const MobileHeader: React.FC<HeaderProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { globalAllClubs, globalSelectedClub, globalSetSelectedClub } = useGlobalContext();

  const handleClubChange = (event: any) => {
    const selectedClub = globalAllClubs.find((club) => club.id === event.target.value);
    if (selectedClub) {
      globalSetSelectedClub(selectedClub);
    } else {
      globalSetSelectedClub(null);
    }
    onClose();
  };

  return (
    <Box
      position="fixed"
      top="0"
      width="100%"
      bg="teal.800"
      paddingY="2"
      paddingX="4"
      zIndex="10"
      display={{ mobile: "flex", laptop: "none" }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Logo />
      <CommonIconButton
        backgroundColor="transparent"
        borderColor="whiteAlpha.700"
        icon={isOpen ? <CloseIcon color="orange.500" /> : <HamburgerIcon color="whiteAlpha.700" />}
        onClick={isOpen ? onClose : onOpen}
        ariaLabel="Toggle Navigation"
      />
      <Drawer placement="top" onClose={onClose} isOpen={isOpen} autoFocus={false}>
        <DrawerOverlay />
        <DrawerContent bg="teal.800" color="whiteAlpha.900" height="100dvh" overflow="auto">
          <DrawerHeader marginBottom="12" paddingBottom="0">
            <Logo />
            <DrawerCloseButton color="whiteAlpha.700" size="lg" padding="6" />
          </DrawerHeader>
          <DrawerBody
            maxHeight="100dvh"
            overflow="auto"
            justifyContent="space-between"
            display="flex"
            flexDirection="column"
            paddingTop="0"
            paddingBottom="6"
          >
            <Stack flex={1} direction="column" spacing="2" align="start" divider={<Divider />} marginBottom="8">
              {links.map(
                (link) =>
                  (!link.restricted || (link.restricted && props.isAdmin)) && (
                    <NavigationLink
                      key={link.to}
                      to={link.to}
                      icon={link.icon}
                      primaryText={link.primaryText}
                      onClick={onClose}
                      isDisabledOnMobile={link.isDisabledOnMobile}
                    />
                  ),
              )}
            </Stack>
            <CommonSelect
              value={globalSelectedClub?.id || undefined}
              options={globalAllClubs.map((club) => ({ value: club.id || "", label: club.name }))}
              placeholder={
                !globalSelectedClub?.id && globalAllClubs.length === 0
                  ? "Select active Club from the Settings"
                  : undefined
              }
              onChange={handleClubChange}
              width="100%"
              color="whiteAlpha.900"
              marginBottom="6"
              sx={{ borderColor: "whiteAlpha.900" }}
            />
            <CommonIconButton
              ariaLabel="logout"
              icon={<Logout sx={{ height: "40px" }} />}
              color="whiteAlpha.900"
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

const Logo: React.FC = () => {
  return (
    <Box display="flex" alignItems="center">
      <Image src={Striveon} width="32px" height="32px" borderRadius="8px" />
      <Text fontSize="xl" fontWeight="bold" marginLeft="2" color="whiteAlpha.900">
        Striveon
      </Text>
    </Box>
  );
};
