import { gql } from "@apollo/client";

export const SAVE_PLAYER = gql`
  mutation SavePlayer($data: PlayerInput!) {
    savePlayer(data: $data) {
      id
      name
    }
  }
`;
