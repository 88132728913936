import { gql } from "@apollo/client";

export const UPDATE_USER_ORGANIZATION = gql`
  mutation UpdateUserOrganization($data: OrganizationInput!) {
    updateUserOrganization(data: $data) {
      id
      name
      seasonStart
      seasonEnd
    }
  }
`;
