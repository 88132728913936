import React, { useEffect, useMemo, useState } from "react";
import { Club } from "../../../../types";
import { Courts } from "../Courts/Courts";
import { useMutation } from "@apollo/client";
import { useAlert } from "../../../../components/AlertProvider";
import { handleError } from "../../../../components/utils";
import { GET_ALL_CLUBS } from "./get-all-clubs.query";
import { SAVE_CLUB } from "./save-club.mutation";
import { useGlobalContext } from "../../../../components/GlobalProvider";
import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { FormModal } from "../../../../components/FormModal";
import { CommonButton } from "../../../../components/CommonButton";
import { CommonIconButton } from "../../../../components/CommonIconButton";
import { CommonInput } from "../../../../components/CommonInput";
import { LoadingOverlay } from "../../../../components/LoadingOverlay";

type ClubProps = {
  allClubs: Club[];
};

export const Clubs: React.FC<ClubProps> = (props) => {
  const { globalAllClubs, globalSelectedClub, globalSetAllClubs, globalSetSelectedClub } = useGlobalContext();
  const [saveClubMutation, { error: saveClubError, loading: saveClubLoading }] = useMutation(SAVE_CLUB, {
    refetchQueries: [{ query: GET_ALL_CLUBS }],
    awaitRefetchQueries: true,
  });
  const allClubs: Club[] = useMemo(() => props.allClubs, [props.allClubs]);
  const [selectedClub, setSelectedClub] = useState<Club | null>(globalSelectedClub);
  const [isNewClub, setIsNewClub] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeClub, setActiveClub] = useState(selectedClub);
  const { showAlert, hideAlert } = useAlert();

  useEffect(() => {
    if (saveClubError) {
      const errors = [saveClubError].filter(Boolean);
      if (errors.length) {
        showAlert(handleError(errors), "error");
      }
    }
    return () => {
      hideAlert();
    };
  }, [saveClubError]);

  useEffect(() => {
    if (allClubs.length > 0) {
      globalSetAllClubs(allClubs);
    }
  }, [allClubs]);

  useEffect(() => {
    setSelectedClub(globalSelectedClub);
    setActiveClub(globalSelectedClub);
  }, [globalSelectedClub]);

  const handleOpenDialog = (club: Club) => {
    setActiveClub(club);
    setIsDialogOpen(true);
  };

  const handleSaveClub = async (remove: boolean) => {
    if (remove) {
      showAlert("Deleting Club", "info", undefined, true);
    } else if (activeClub?.id) {
      showAlert("Updating Club", "info", undefined, true);
    } else {
      showAlert("Creating new Club", "info", undefined, true);
    }
    setIsNewClub(false);
    setIsDialogOpen(false);

    try {
      const { __typename, ...clubToSave } = activeClub || {};
      const courtsToSave = activeClub?.courts.map((court) => {
        const { __typename, ...rest } = court;
        return rest;
      });

      const dataToSave = {
        ...clubToSave,
        courts: courtsToSave,
        remove: remove,
      };
      const response = await saveClubMutation({
        variables: {
          data: dataToSave,
        },
      });

      if (response.data.saveClub === null && remove) {
        if (activeClub?.id === globalSelectedClub?.id) {
          globalSetSelectedClub(null);
        }
        showAlert("Club deleted!", "success", 5000);
      } else {
        if (activeClub?.id) {
          showAlert("Club updated!", "success", 5000);
        } else {
          if (!globalSelectedClub) {
            globalSetSelectedClub(response.data.saveClub);
          }
          showAlert("Club created!", "success", 5000);
        }
      }
    } catch {
      // catch silently, error handling is done in the useEffect
    }
  };

  const handleSetActiveClub = () => {
    globalSetSelectedClub(activeClub);
    setIsDialogOpen(false);
  };

  const handleSubmit = () => {
    handleSaveClub(false);
  };

  const handleClose = () => {
    setIsNewClub(false);
    setIsDialogOpen(false);
  };

  const handleAddNew = () => {
    setIsNewClub(true);
    setActiveClub(null);
    handleOpenDialog({ name: "New Club", courts: [] });
  };

  const getActivateClubTooltip = () => {
    if (activeClub?.id === globalSelectedClub?.id) {
      return "This club is already active";
    } else if (isNewClub) {
      return "This club needs to be created first before it can be set as an active";
    }
    return "Setting this club as active will display its courts and related information";
  };

  const getSubmitDisabledReasons = (): string | undefined => {
    const clubNames = allClubs.map((club) => club.name.toLowerCase());
    if (clubNames.includes(activeClub?.name.toLowerCase() || "")) {
      return `Club "${activeClub?.name}" already exists`;
    } else if (activeClub?.name.trim() === "") {
      return "Club name cannot be empty";
    }

    return undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  return (
    <Box id="clubs">
      <Grid templateColumns="repeat(2, 1fr)" gap="12">
        {/* Clubs */}
        <GridItem position="relative" colSpan={1} data-testid="clubs">
          <LoadingOverlay display={saveClubLoading || false} spinnerSize="xl" spinnerTopPosition="40px" />
          <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="center">
            <Text fontSize="x-large">Clubs</Text>
            <CommonIconButton height="36px" onClick={handleAddNew} dataTestId="add-club" />
          </Stack>
          <Grid
            templateColumns="repeat(auto-fit, minmax(calc(25% - 12px), calc(25% - 12px)))"
            gap="4"
            justifyContent="center"
          >
            {allClubs.length === 0 ? (
              <Text fontSize="large" textAlign="center" data-testid="no-clubs">
                No clubs
              </Text>
            ) : (
              allClubs.map((club, index) => (
                <GridItem key={index} colSpan={1} data-testid="club-grid">
                  <CommonButton
                    fullWidth
                    variantType={club.id === selectedClub?.id ? "solidPrimary" : "outlineSecondary"}
                    disableHover={club.id === selectedClub?.id}
                    disableShadow={club.id === selectedClub?.id}
                    onClick={() => handleOpenDialog(club)}
                    dataTestId={`club-${index}`}
                  >
                    <Text
                      fontSize="medium"
                      color={club.id === selectedClub?.id ? "white" : "black"}
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {club.name}
                    </Text>
                  </CommonButton>
                </GridItem>
              ))
            )}
          </Grid>
        </GridItem>

        {/* Courts */}
        <GridItem colSpan={1}>
          {selectedClub && selectedClub.id ? (
            <Courts
              key={selectedClub.id}
              title={`${selectedClub.name} courts`}
              allCourts={selectedClub.courts}
              clubId={selectedClub.id}
              isLoading={saveClubLoading}
            />
          ) : (
            <Text fontSize="x-large" textAlign="center" data-testid="courts-fallback">
              {globalAllClubs.length === 0 ? "Create club to add courts" : "Select club to view courts"}
            </Text>
          )}
        </GridItem>

        <FormModal
          handleSubmit={handleSubmit}
          open={isDialogOpen}
          submitButtonText={isNewClub ? "Create" : "Confirm"}
          title={isNewClub ? "Create new Club" : "Edit Club"}
          onClose={handleClose}
          handleRemove={() => handleSaveClub(true)}
          submitDisabled={!!submitDisabledReasons}
          submitButtonHoverText={submitDisabledReasons}
          removeDisabled={!activeClub?.id}
          removeDisabledReason="Club isn't created yet"
        >
          <Grid templateColumns="repeat(2, 1fr)" gap="4" marginTop="4" data-testid="club-modal">
            <GridItem colSpan={2}>
              <CommonButton
                variantType="outlineSecondary"
                fullWidth
                onClick={handleSetActiveClub}
                tooltip={getActivateClubTooltip()}
                disabled={activeClub?.id === globalSelectedClub?.id || isNewClub}
                dataTestId="set-active-club-button"
              >
                Set as the active club
              </CommonButton>
            </GridItem>
            <GridItem colSpan={2}>
              <CommonInput
                placeholder="Club name"
                value={activeClub?.name || ""}
                onChange={(value) => {
                  setActiveClub({ ...activeClub, name: value, courts: activeClub?.courts || [] });
                }}
                dataTestId="club-name"
              />
            </GridItem>
          </Grid>
        </FormModal>
      </Grid>
    </Box>
  );
};
