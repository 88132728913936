import React, { useEffect, useState } from "react";
import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { CommonDayPicker } from "../../../../components/CommonDayPicker";
import { CommonButton } from "../../../../components/CommonButton";
import { useMutation } from "@apollo/client";
import { GET_USER_ORGANIZATION } from "./get-user-organization.query";
import { UPDATE_USER_ORGANIZATION } from "./update-user-organization.mutation";
import { useAlert } from "../../../../components/AlertProvider";
import { formatDateToUTC, handleError } from "../../../../components/utils";
import { GET_MISSING_RECURRING_TRAININGEVENT_METADATAS } from "./get-missing-recurring-trainingevent-metadatas.query";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog";
import type { MissingMetadata } from "../../../../types";
import { extractDateFromUTC } from "../../../Calendar/utils";
import { CREATE_MISSING_RECURRING_TRAININGEVENT_METADATAS } from "./create-missing-recurring-trainingevent-metadatas.mutation";
import { GET_ALL_RECURRING_TRAINING_EVENT_METADATAS_FOR_SEASON } from "./get-all-recurring-training-event-metadatas-for-season.query";
import { GET_ALL_PLAYERS } from "../../../Players/get-all-players.query";
import { LoadingOverlay } from "../../../../components/LoadingOverlay";

type SeasonProps = {
  seasonStart?: string;
  seasonEnd?: string;
  missingMetadata: MissingMetadata[];
};

export const Season: React.FC<SeasonProps> = (props) => {
  const [updateOrganizationMutation, { error: updateError, loading: updateLoading }] = useMutation(
    UPDATE_USER_ORGANIZATION,
    {
      refetchQueries: [{ query: GET_USER_ORGANIZATION }, { query: GET_MISSING_RECURRING_TRAININGEVENT_METADATAS }],
      awaitRefetchQueries: true,
    },
  );
  const [creteMissingRecurringTrainingEventMetadataMutation, { error: createError, loading: createLoading }] =
    useMutation(CREATE_MISSING_RECURRING_TRAININGEVENT_METADATAS, {
      refetchQueries: [
        { query: GET_MISSING_RECURRING_TRAININGEVENT_METADATAS },
        { query: GET_ALL_RECURRING_TRAINING_EVENT_METADATAS_FOR_SEASON }, // to trigger update in calendar for events
        { query: GET_ALL_PLAYERS },
      ],
      awaitRefetchQueries: true,
    });
  const [seasonStart, setSeasonStart] = useState(props.seasonStart);
  const [seasonEnd, setSeasonEnd] = useState(props.seasonEnd);
  const [seasonDatesModified, setIsSeasonDatesModified] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { showAlert, hideAlert } = useAlert();

  useEffect(() => {
    if ((seasonStart !== props.seasonStart || seasonEnd !== props.seasonEnd) && seasonStart && seasonEnd) {
      setIsSeasonDatesModified(true);
    } else {
      setIsSeasonDatesModified(false);
    }
  }, [seasonStart, seasonEnd]);

  useEffect(() => {
    if (updateError || createError) {
      const errors = [updateError, createError].filter(Boolean);
      if (errors.length) {
        showAlert(handleError(errors), "error");
      }
    }
    return () => {
      hideAlert();
    };
  }, [updateError, createError]);

  /** Save the selected season dates */
  const handleSaveSeason = async () => {
    showAlert("Updating season dates", "info", undefined, true);

    try {
      await updateOrganizationMutation({
        variables: {
          data: {
            seasonStart,
            seasonEnd,
          },
        },
      });

      setIsSeasonDatesModified(false);
      showAlert("Season dates updated!", "success", 5000);
    } catch {
      // catch silently, error handling is done in the useEffect
    }
  };

  /** Handle opening confirmation dialog */
  const handleOpenConfirmation = () => {
    setIsConfirmationOpen(true);
  };

  /** Handle confirm action from the confirmation dialog */
  const handleConfirm = async () => {
    try {
      showAlert("Updating training events", "info", undefined, true);
      setIsConfirmationOpen(false);
      await creteMissingRecurringTrainingEventMetadataMutation();
      showAlert("Training events updated!", "success", 5000);
    } catch {
      // catch silently, error handling is done in the useEffect
    }
  };

  return (
    <Box position="relative" id="season-settings">
      <LoadingOverlay display={updateLoading || createLoading || false} spinnerSize="xl" spinnerTopPosition="40px" />

      <Grid templateColumns="repeat(3, 1fr)" gap="12">
        <GridItem colSpan={1}>
          <CommonDayPicker
            onDateChange={(date) => (date ? setSeasonStart(formatDateToUTC(date)) : setSeasonStart(undefined))}
            initialDate={seasonStart ? new Date(seasonStart) : undefined}
            label="Season start"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <CommonDayPicker
            onDateChange={(date) => (date ? setSeasonEnd(formatDateToUTC(date)) : setSeasonEnd(undefined))}
            initialDate={seasonEnd ? new Date(seasonEnd) : undefined}
            label="Season end"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <Stack direction="row" spacing="6">
            <CommonButton
              fullWidth
              variantType="solidPrimary"
              onClick={handleSaveSeason}
              disabled={!seasonDatesModified}
              tooltip={seasonDatesModified ? "" : "Update both Season Start and Season End dates to save"}
              dataTestId="save-season-dates"
            >
              Update season dates
            </CommonButton>
            <CommonButton
              fullWidth
              variantType="outlineSecondary"
              onClick={handleOpenConfirmation}
              disabled={props.missingMetadata.length === 0}
              tooltip={props.missingMetadata.length > 0 ? "" : "All recurring training events are up to date!"}
              dataTestId="update-training-events"
            >
              Update training events
            </CommonButton>
          </Stack>
        </GridItem>
      </Grid>

      <ConfirmationDialog
        title="Update training events"
        message={
          props.missingMetadata.length > 0 ? (
            <Stack direction="column" spacing="4">
              <Text>
                Below are the missing recurring training events that align with the updated season dates. These events
                will be created once you confirm:
              </Text>
              {props.missingMetadata.map((meta, index) => (
                <Box key={index} p={4} border="1px solid" borderColor="gray.200" borderRadius="md" bg="gray.50">
                  <Text>Date: {extractDateFromUTC(meta.startDateTime)}</Text>
                  <Text>Court: {meta.court.name}</Text>
                  <Text>Coaches: {meta.coaches?.map((coach) => coach.name).join(", ") || "None"}</Text>
                  <Text>Players: {meta.players?.map((player) => player.name).join(", ") || "None"}</Text>
                </Box>
              ))}
            </Stack>
          ) : (
            "No missing Recurring Training Events"
          )
        }
        isOpen={isConfirmationOpen}
        onConfirm={handleConfirm}
        onCancel={() => setIsConfirmationOpen(false)}
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        onClose={() => setIsConfirmationOpen(false)}
      />
    </Box>
  );
};
