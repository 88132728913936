import { useEffect } from "react";
import { handleError } from "../../../components/utils";
import type { Club, Coach, Court, SingleTrainingEvent, Drill, Player, Session } from "../../../types";
import type { ApolloError } from "@apollo/client";

type useCalendarDataProps = {
  globalSelectedClub: Club | null;
  drillData: any;
  sessionData: any;
  playerData: any;
  coachData: any;
  singleTrainingEventData: any;
  setAllCoaches: React.Dispatch<React.SetStateAction<Coach[]>>;
  setAllCourts: React.Dispatch<React.SetStateAction<Court[]>>;
  setAllPlayers: React.Dispatch<React.SetStateAction<Player[]>>;
  setAllDrills: React.Dispatch<React.SetStateAction<Drill[]>>;
  setAllSessions: React.Dispatch<React.SetStateAction<Session[]>>;
  setAllSingleTrainingEvents: React.Dispatch<React.SetStateAction<SingleTrainingEvent[]>>;
  showAlert: (message: string, severity: "success" | "info" | "warning" | "error", autoHideDuration?: number) => void;
  drillError: ApolloError | undefined;
  sessionError: ApolloError | undefined;
  playerError: ApolloError | undefined;
  coachError: ApolloError | undefined;
  singleTrainingEventError: ApolloError | undefined;
  recurringTrainingEventMetadataError: ApolloError | undefined;
  skillSetsError: ApolloError | undefined;
};

export const useCalendarData = (props: useCalendarDataProps) => {
  useEffect(() => {
    if (props.coachData && props.coachData.getAllCoaches) {
      props.setAllCoaches(props.coachData.getAllCoaches);
    }
  }, [props.coachData]);

  useEffect(() => {
    if (props.playerData && props.playerData.getAllPlayers) {
      props.setAllPlayers(props.playerData.getAllPlayers);
    }
  }, [props.playerData]);

  useEffect(() => {
    if (props.drillData && props.drillData.getAllDrills) {
      props.setAllDrills(props.drillData.getAllDrills);
    }
  }, [props.drillData]);

  useEffect(() => {
    if (props.sessionData && props.sessionData.getAllSessions) {
      props.setAllSessions(props.sessionData.getAllSessions);
    }
  }, [props.sessionData]);

  useEffect(() => {
    if (props.singleTrainingEventData) {
      props.setAllSingleTrainingEvents(props.singleTrainingEventData.getAllSingleTrainingEvents || []);
    }
  }, [props.singleTrainingEventData]);

  useEffect(() => {
    if (props.globalSelectedClub) {
      props.setAllCourts(props.globalSelectedClub.courts);
    }
  }, [props.globalSelectedClub]);

  useEffect(() => {
    if (
      props.drillError ||
      props.sessionError ||
      props.playerError ||
      props.coachError ||
      props.singleTrainingEventError ||
      props.recurringTrainingEventMetadataError ||
      props.skillSetsError
    ) {
      const errors = [
        props.drillError,
        props.sessionError,
        props.playerError,
        props.coachError,
        props.singleTrainingEventError,
        props.recurringTrainingEventMetadataError,
        props.skillSetsError,
      ].filter(Boolean);
      if (errors.length) {
        props.showAlert(handleError(errors), "error");
      }
    }
  }, [
    props.drillError,
    props.sessionError,
    props.playerError,
    props.coachError,
    props.singleTrainingEventError,
    props.recurringTrainingEventMetadataError,
    props.skillSetsError,
    props.showAlert,
  ]);
};
