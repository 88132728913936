import { useEffect, useState } from "react";
import type { Coach, Court, SingleTrainingEvent, Drill, Player, Session } from "../../../types";
import type { FullCalendarEvent } from "../types";

type useCalendarStateProps = {
  coachLoading: boolean;
  drillLoading: boolean;
  sessionLoading: boolean;
  recurringTrainingEventMetadataLoading: boolean;
  playerLoading: boolean;
  singleTrainingEventLoading: boolean;
  skillSetsLoading: boolean;
};

export const useCalendarState = (props: useCalendarStateProps) => {
  const [allCoaches, setAllCoaches] = useState<Coach[]>([]);
  const [allCourts, setAllCourts] = useState<Court[]>([]);
  const [allDrills, setAllDrills] = useState<Drill[]>([]);
  const [allSessions, setAllSessions] = useState<Session[]>([]);
  const [allPlayers, setAllPlayers] = useState<Player[]>([]);
  const [allSingleTrainingEvents, setAllSingleTrainingEvents] = useState<SingleTrainingEvent[]>([]);

  const [activeView, setActiveView] = useState("timeGridWeek");
  const [eventsToDisplay, setEventsToDisplay] = useState<FullCalendarEvent[]>([]);
  const [fullCalendarKey, setFullCalendarKey] = useState(1);
  const [dayViewDate, setDayViewDate] = useState(() => {
    const initialDate = new Date();
    const currentDayOfWeek = initialDate.getDay();
    if (currentDayOfWeek === 0) {
      initialDate.setDate(initialDate.getDate() + 1);
    } else if (currentDayOfWeek === 6) {
      initialDate.setDate(initialDate.getDate() + 2);
    }
    return initialDate;
  });
  const [dayViewSelectedCourt, setDayViewSelectedCourt] = useState<Court | null>(null);
  const [todoPlayers, setTodoPlayers] = useState<Player[]>([]);

  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [isDraggableTodoOpen, setIsDraggableTodoOpen] = useState(false);
  const isLoading =
    props.recurringTrainingEventMetadataLoading ||
    props.drillLoading ||
    props.playerLoading ||
    props.coachLoading ||
    props.singleTrainingEventLoading ||
    props.skillSetsLoading;
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const [showContent, setShowContent] = useState(!isLoading);
  const [showOnlyFreeSpots, setShowOnlyFreeSpots] = useState(false);
  const [showOnlyRecurringTrainingEvents, setShowOnlyRecurringTrainingEvents] = useState(false);
  const [showOnlySingleTrainingEvents, setShowOnlySingleTrainingEvents] = useState(false);

  const [selectedCoaches, setSelectedCoaches] = useState<Coach[]>([]);
  const [selectedDateTime, setSelectedDateTime] = useState<string | undefined>(undefined);
  const [selectedStartTime, setSelectedStartTime] = useState<string | null>(null);
  const [selectedEndTime, setSelectedEndTime] = useState<string | null>(null);
  const [selectedEventInfo, setSelectedEventInfo] = useState<FullCalendarEvent>({
    id: "",
    date: undefined,
    start: new Date().toISOString(),
    end: new Date().toISOString(),
    court: { name: "" },
    coach: [],
    players: [],
    playerNotes: [],
    trainingEventType: "single",
    isLoading: false,
    title: "",
    color: "",
  });

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => setShowContent(true), 300);
    }
  }, [isLoading]);

  return {
    selectedCoaches,
    showOnlyFreeSpots,
    showOnlyRecurringTrainingEvents,
    showOnlySingleTrainingEvents,
    isAddEventModalOpen,
    isDraggableTodoOpen,
    selectedDateTime,
    selectedStartTime,
    selectedEndTime,
    isViewModalOpen,
    selectedEventInfo,
    allCoaches,
    allCourts,
    allPlayers,
    allDrills,
    allSessions,
    allSingleTrainingEvents,
    eventsToDisplay,
    fullCalendarKey,
    activeView,
    dayViewDate,
    dayViewSelectedCourt,
    todoPlayers,
    isLoading,
    showContent,
    setSelectedCoaches,
    setShowOnlyFreeSpots,
    setShowOnlyRecurringTrainingEvents,
    setShowOnlySingleTrainingEvents,
    setIsAddEventModalOpen,
    setIsDraggableTodoOpen,
    setSelectedDateTime,
    setSelectedStartTime,
    setSelectedEndTime,
    setIsViewModalOpen,
    setSelectedEventInfo,
    setFullCalendarKey,
    setActiveView,
    setDayViewDate,
    setDayViewSelectedCourt,
    setTodoPlayers,
    setEventsToDisplay,
    setAllCoaches,
    setAllCourts,
    setAllPlayers,
    setAllDrills,
    setAllSessions,
    setAllSingleTrainingEvents,
  };
};
