import React from "react";

import { Grid, GridItem, Stack } from "@chakra-ui/react";
import type { Coach, Court, RecurringTrainingEvent } from "../../../types";
import { CommonInput } from "../../../components/CommonInput";
import { CommonSelect } from "../../../components/CommonSelect";
import { TimePicker } from "../../../components/TimePicker";
import { CourtSelect } from "../../../components/CourtSelect";
import { CoachSelect } from "../../../components/CoachSelect";
import { sortNames } from "../../../components/utils";

const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

/** Component for editing a single event row */
export const EventEditorRow: React.FC<{
  event: RecurringTrainingEvent;
  allCoaches: Coach[];
  allCourts: Court[];
  onChange: (field: keyof RecurringTrainingEvent, value: any) => void;
  allDisabled?: boolean;
  timeAndPlaceDisabled?: boolean;
  dataTestId?: string;
}> = ({ event, allCoaches, allCourts, onChange, allDisabled, timeAndPlaceDisabled, dataTestId }) => {
  return (
    <Stack direction="row" spacing="4" width="100%" data-testid={dataTestId}>
      <Grid templateColumns="repeat(10, 1fr)" gap="4" alignItems="center" width="100%">
        <GridItem colSpan={2} data-testid="input-group">
          <CommonInput
            placeholder="Group name"
            value={event.name || ""}
            onChange={(value) => onChange("name", value)}
            disabled={allDisabled}
            dataTestId="input-group"
          />
        </GridItem>
        <GridItem colSpan={2} data-testid="select-weekday">
          <CommonSelect
            placeholder="Weekday"
            value={event.recurrenceWeekday}
            options={weekdays.map((weekday, i) => ({
              value: i + 1,
              label: weekday,
            }))}
            onChange={(e) => onChange("recurrenceWeekday", parseInt(e.target.value))}
            isDisabled={allDisabled || timeAndPlaceDisabled}
          />
        </GridItem>
        <GridItem colSpan={1} data-testid="select-start">
          <TimePicker
            label="Start"
            selectedTime={event.recurrenceStartTime}
            setSelectedTime={(value: string | Date) => onChange("recurrenceStartTime", value)}
            disabled={allDisabled || timeAndPlaceDisabled}
          />
        </GridItem>
        <GridItem colSpan={1} data-testid="select-end">
          <TimePicker
            label="End"
            selectedTime={event.recurrenceEndTime}
            setSelectedTime={(value: string | Date) => onChange("recurrenceEndTime", value)}
            disabled={allDisabled || timeAndPlaceDisabled}
          />
        </GridItem>
        <GridItem colSpan={2} data-testid="select-court">
          <CourtSelect
            courts={allCourts.slice().sort((a, b) => sortNames(a.name, b.name))}
            selectedCourt={event.court}
            setSelectedCourt={(courtId: string) => onChange("court", courtId)}
            disabled={allDisabled || timeAndPlaceDisabled}
          />
        </GridItem>
        <GridItem colSpan={2} data-testid="select-coach">
          <CoachSelect
            availableCoaches={allCoaches}
            selectedCoaches={event.coaches?.map((coach) => coach) || []}
            setSelectedCoach={(selectedCoaches) =>
              onChange(
                "coaches",
                selectedCoaches.map((coach) => coach.id || ""),
              )
            }
            disabled={allDisabled}
          />
        </GridItem>
      </Grid>
    </Stack>
  );
};
