import React, { useRef } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { OverflowIndicator } from "../OverflowIndicator";

type CommonScrollbarProps = BoxProps & {
  /** Child elements to display inside the scrollable container */
  children: React.ReactNode;
  /** Color of the scrollbar border, defaults to white */
  scrollbarBorderColor?: string;
  /** Indicates if the user is using a mobile device */
  isMobile?: boolean;
};

/**
 * CustomScrollbar component adds a stylized scrollbar to its children, and displays OverflowIndicator on hover. It
 * extends BoxProps, allowing Chakra UI Box styling properties.
 */
export const CommonScrollbar: React.FC<CommonScrollbarProps> = ({
  children,
  scrollbarBorderColor,
  isMobile,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  return isMobile ? (
    children
  ) : (
    <Box
      ref={containerRef}
      _hover={{
        ".overflowIcon": {
          opacity: 0.25,
        },
      }}
      css={{
        "&::-webkit-scrollbar": {
          width: "16px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#00000029", // blackAlpha.300
          borderStyle: "solid",
          borderWidth: "0px 0px 0px 8px",
          borderColor: scrollbarBorderColor || "white",
          backgroundClip: "padding-box",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#0000005C", // blackAlpha.500
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "inherit",
        },
      }}
      {...props}
    >
      {children}
      <OverflowIndicator containerRef={containerRef} />
    </Box>
  );
};
