import React, { useEffect, useState } from "react";
import { SkillSets } from "../components/SkillSets";
import { Box } from "@chakra-ui/react";
import { useMutation, useQuery, type ApolloError } from "@apollo/client";
import { GET_ALL_SKILL_SETS } from "../graphql/get-all-skill-sets.query";
import type { SkillSet } from "../../../types";
import { SAVE_SKILL_SET } from "../graphql/save-skill-set.mutation";
import { useAlert } from "../../../components/AlertProvider";
import { handleError } from "../../../components/utils";
import { LoadingContainer, loadingContainerFadeIn } from "../../../components/LoadingContainer";

type SkillInput = {
  id?: string;
  name: string;
  remove?: boolean;
};

type SkillSetInput = {
  id?: string;
  name: string;
  skills: SkillInput[];
  remove?: boolean;
};

export const SkillSetsContainer: React.FC = () => {
  const { data: skillSetData, loading: skillSetDataLoading, error: skillSetDataError } = useQuery(GET_ALL_SKILL_SETS);
  const [saveSkillSetMutation] = useMutation(SAVE_SKILL_SET, {
    refetchQueries: [{ query: GET_ALL_SKILL_SETS }],
  });
  const [skillSets, setSkillSets] = useState<SkillSet[]>([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const { showAlert, hideAlert } = useAlert();
  const isLoading = skillSetDataLoading;
  const [showContent, setShowContent] = useState(!isLoading);
  useEffect(() => {
    if (!isLoading) {
      // Timeout needs to match with LoadingContainer animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    }
  }, [isLoading]);

  useEffect(() => {
    if (skillSetData?.getAllSkillSets) {
      setSkillSets(skillSetData.getAllSkillSets);
    }
  }, [skillSetData]);

  useEffect(() => {
    if (skillSetDataError) {
      const errors = [skillSetDataError].filter(Boolean);
      if (errors.length) {
        showAlert(handleError(errors), "error");
      }
    }
    return () => {
      hideAlert();
    };
  }, [skillSetDataError]);

  const onSave = async (skillSetToSave: SkillSet) => {
    const dataToSave: SkillSetInput = {
      id: skillSetToSave.id,
      name: skillSetToSave.name,
      skills: skillSetToSave.skills.map((skill) => ({ id: skill.id, name: skill.name, remove: skill.remove })),
      remove: skillSetToSave.remove,
    };

    if (dataToSave.remove) {
      showAlert("Deleting Skill Set", "info", undefined, true);
    } else if (dataToSave.id) {
      showAlert("Updating Skill Set", "info", undefined, true);
    } else {
      showAlert("Creating new Skill Set", "info", undefined, true);
    }

    try {
      const response = await saveSkillSetMutation({
        variables: { data: dataToSave },
        awaitRefetchQueries: true,
      });

      if (response.data.saveSkillSet === null && dataToSave.remove) {
        showAlert("Skill Set deleted!", "success", 5000);
      } else if (dataToSave.id) {
        showAlert("Skill Set updated!", "success", 5000);
      } else {
        showAlert("Skill Set created!", "success", 5000);
      }
    } catch (error) {
      if ((error as ApolloError)?.message === "Entity with the given name already exists for this organization") {
        if (dataToSave.id) {
          if (skillSets.find((skillSet) => skillSet.name === dataToSave.name && skillSet.id !== dataToSave.id)) {
            showAlert("Skill Set not updated: Skill Set with given name exists already", "warning");
          } else {
            showAlert("Skill Set not updated: Skill with given name exists already", "warning");
          }
        } else {
          showAlert("Skill Set not created: Skill Set with given name exists already", "warning");
        }
        setRefreshKey((prevKey) => prevKey + 1);
      } else {
        showAlert(handleError([error]), "error");
      }
    }
  };

  return (
    <Box id="skill-sets">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box animation={isLoading ? undefined : `${loadingContainerFadeIn} 0.3s`}>
          <SkillSets key={refreshKey} onSave={onSave} skillSets={skillSets} />
        </Box>
      )}
    </Box>
  );
};
