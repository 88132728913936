import React, { useState } from "react";
import { TimesOneMobiledata } from "@mui/icons-material";

import { CoachSelect } from "../../../../../components/CoachSelect";
import { PlayerInput } from "../PlayerInput";
import { FormModal } from "../../../../../components/FormModal";
import { Coach, Court, Drill, Player, Session, type SkillSet } from "../../../../../types";
import { CourtSelect } from "../../../../../components/CourtSelect";
import { DrillLibrary } from "../../../../../components/DrillLibrary";
import { DrillCard } from "../../../../../components/DrillCard";
import { updateUTCTime, filterSessions } from "../../../../../components/utils";
import { Box, Grid, GridItem, Icon, Stack, Tab, Tabs, TabList, TabPanel, TabPanels, Text } from "@chakra-ui/react";
import { TimePicker } from "../../../../../components/TimePicker";
import { CommonDayPicker } from "../../../../../components/CommonDayPicker";
import type { TrainingEventUpdateInput } from "../../../types";
import { CommonScrollbar } from "../../../../../components/CommonScrollbar/CommonScrollbar";
import { SessionLibrary } from "../../../../TrainingLibrary/components/SessionManager/SessionLibrary";
import { SessionCard } from "../../../../TrainingLibrary/components/SessionManager/SessionCard";
import { NotesInput } from "../NotesInput";

export type AddEventModalProps = {
  /** Callback function for saving new event */
  onSave: (singleTrainingEventInput: TrainingEventUpdateInput) => void;
  /** Callback function for closing the modal */
  onClose: () => void;
  /** Whether the modal is open or not */
  open: boolean;
  /** Initial date value */
  initialDate?: string;
  /** Initial time value for the start of the event */
  initialTime: string | null;
  /** Initial time value for the end of the event */
  initialEndTime: string | null;
  /** Initial court selection */
  initialCourt: Court;
  /** Array of all available coaches */
  allCoaches: Coach[];
  /** Array of all available courts */
  allCourts: Court[];
  /** Array of all available players */
  allPlayers: Player[];
  /** Array of all available drills */
  allDrills: Drill[];
  /** Array of all available sessions */
  allSessions: Session[];
  allSkillSets: SkillSet[];
  /** Optional id of the event */
  id?: string;
  /** Whether the user is using a mobile device */
  isMobile: boolean;
};

/**
 * A modal component for adding a new event.
 *
 * @param props - The properties of the AddEventModal component
 * @returns The AddEventModal component
 */
export const AddEventModal: React.FC<AddEventModalProps> = (props) => {
  const [selectedDate, setSelectedDate] = useState(props.initialDate);
  const [selectedStartTime, setSelectedStartTime] = useState(props.initialTime);
  const [selectedEndTime, setSelectedEndTime] = useState(props.initialEndTime);
  const [selectedCourt, setSelectedCourt] = useState<Court>(props.initialCourt);
  const [selectedCoaches, setSelectedCoaches] = useState<Coach[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>([]);
  const [groupNote, setGroupNote] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [searchSkillSets, setSearchSkillSets] = useState<SkillSet[]>([]);
  const [selectedDrills, setSelectedDrills] = useState<Drill[]>([]);
  const [selectedSession, setSelectedSession] = useState<Session | undefined>(undefined);
  const allSessionTags = Array.from(
    new Set(props.allSessions.flatMap((session) => (session.tags ? session.tags : []))),
  );

  /**
   * Generates a list of reasons why the submit button is disabled.
   *
   * @returns An object containing the submit disabled reasons if any condition is not met, otherwise undefined.
   */
  const getSubmitDisabledReasons = (): string | undefined => {
    const reasons: string[] = [];

    if (!selectedDate) {
      reasons.push("Date is selected");
    }
    if (!selectedStartTime || selectedStartTime === "24:00") {
      reasons.push("Start time is selected");
    }
    if (!selectedEndTime || selectedEndTime === "24:00") {
      reasons.push("End time is selected");
    }
    if (!selectedCourt) {
      reasons.push("Court is selected");
    }

    return reasons.length > 0 ? `Saving is enabled once\n- ${reasons.join("\n- ")}` : undefined;
  };

  const submitDisabledReasons = getSubmitDisabledReasons();

  const handleSelectDrill = (drill: Drill) => {
    if (selectedDrills.find((d) => d.id === drill.id)) {
      setSelectedDrills(selectedDrills.filter((d) => d.id !== drill.id));
    } else {
      setSelectedDrills([...selectedDrills, drill]);
    }
    setSelectedSession(undefined);
  };

  const handleSelectSession = (session: Session) => {
    if (selectedSession && selectedSession.id === session.id) {
      setSelectedSession(undefined);
    } else {
      setSelectedSession(session);
    }
    setSelectedDrills([]);
  };

  /**
   * Handles the changes in search query and search tags. Updates the state variables 'searchQuery' and 'searchTags'.
   *
   * @param newSearchQuery - The new search query
   * @param newSearchTags - The new search tags
   * @param newSearchSkillSets - The new search skill sets
   */
  const handleSearchChange = (newSearchQuery: string, newSearchTags: string[], newSearchSkillSets: SkillSet[]) => {
    setSearchQuery(newSearchQuery);
    setSearchTags(newSearchTags);
    setSearchSkillSets(newSearchSkillSets);
  };

  // Filter the drills based on the input(s)
  const filteredDrills = props.allDrills.filter((drill) => {
    // Check if the drill matches the search query
    const matchesSearchQuery =
      searchQuery === "" ||
      drill.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      drill.goal.toLowerCase().includes(searchQuery.toLowerCase()) ||
      drill.implementation.toLowerCase().includes(searchQuery.toLowerCase());
    // Check if the drill matches the search tags
    const matchesSearchTags = searchTags.every((searchTag) => drill.tags?.includes(searchTag));
    // Check if the drill matches the search skill sets
    const matchesSearchSkillSets = searchSkillSets.every((searchSkillSet) =>
      drill.skillSets?.some((drillSkillSet) => drillSkillSet.id === searchSkillSet.id),
    );

    // Return the drill if it matches all the search criteria
    return matchesSearchQuery && matchesSearchTags && matchesSearchSkillSets;
  });

  /**
   * Handles the selection of players. Updates the state variable 'selectedPlayers'.
   *
   * @param players - An array of selected players
   */
  const handlePlayerSelection = (players: Player[]) => {
    setSelectedPlayers(players);
  };

  /**
   * Handles the change in the current tab. Updates the state variable 'tabIndex'.
   *
   * @param newValue - The new value of the tab index
   */
  const handleTabChange = (newValue: number) => {
    setTabIndex(newValue);
  };

  const handleSubmit = () => {
    const singleTrainingEventInput: TrainingEventUpdateInput = {
      id: props.id,
      startDateTime: updateUTCTime(selectedDate || "", selectedStartTime || ""),
      endDateTime: updateUTCTime(selectedDate || "", selectedEndTime || ""),
      courtId: selectedCourt.id || "",
      coachIds: selectedCoaches.map((coach) => coach.id || ""),
      playerIds: selectedPlayers.map((selectedPlayer) => selectedPlayer.id || ""),
      drillIds: selectedDrills.map((drill) => drill.id || ""),
      notes: [
        {
          isGroupNote: true,
          content: groupNote,
          playerIds: selectedPlayers.map((player) => player.id || ""),
        },
      ],
      sessionId: selectedSession?.id,
    };

    props.onSave(singleTrainingEventInput);
  };

  return (
    <FormModal
      fixedHeight
      open={props.open}
      handleSubmit={handleSubmit}
      onClose={props.onClose}
      title=""
      submitButtonText="Save"
      tabs={["General", "Training plan"]}
      handleTabChange={handleTabChange}
      tabIndex={tabIndex}
      paddingTop={1}
      submitDisabled={!!submitDisabledReasons}
      submitButtonHoverText={submitDisabledReasons}
    >
      {/* First tab */}
      <Box data-testid="add-event-modal" height="100%">
        <CommonScrollbar height="100%" overflow="auto" isMobile={props.isMobile}>
          <Stack
            direction="row"
            spacing="2"
            justifyContent="center"
            alignItems="center"
            marginBottom={{ mobile: "6", laptop: "3" }}
          >
            <Text fontSize="lg" alignContent="center">
              New Single Training
            </Text>
            <Icon as={TimesOneMobiledata} alignSelf="end" boxSize="8" color="blackAlpha.800" />
          </Stack>
          <Grid templateColumns="repeat(6, 1fr)" templateRows="auto" gap={{ mobile: "6", laptop: "4" }} height="auto">
            <GridItem colSpan={{ mobile: 6, laptop: 2 }}>
              <CommonDayPicker
                initialDate={selectedDate ? new Date(selectedDate) : undefined}
                onDateChange={(date) => setSelectedDate(date?.toISOString())}
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 3, laptop: 2 }}>
              <TimePicker
                selectedTime={selectedStartTime || ""}
                setSelectedTime={(value) => (typeof value === "string" ? setSelectedStartTime(value) : undefined)}
                label="Start"
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 3, laptop: 2 }}>
              <TimePicker
                selectedTime={selectedEndTime || ""}
                setSelectedTime={(value) => (typeof value === "string" ? setSelectedEndTime(value) : undefined)}
                label="End"
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 6, laptop: 3 }}>
              <CourtSelect
                selectedCourt={selectedCourt}
                setSelectedCourt={(courtId) => {
                  const newCourt = props.allCourts.find((court) => court.id === courtId);
                  if (newCourt) {
                    setSelectedCourt(newCourt);
                  }
                }}
                courts={props.allCourts}
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 6, laptop: 3 }}>
              <CoachSelect
                availableCoaches={props.allCoaches}
                selectedCoaches={selectedCoaches}
                setSelectedCoach={(coachArray) => setSelectedCoaches(coachArray)}
              />
            </GridItem>
            <GridItem colSpan={6}>
              <PlayerInput
                players={props.allPlayers}
                selectedPlayers={selectedPlayers}
                handlePlayerSelection={handlePlayerSelection}
              />
            </GridItem>
            <GridItem colSpan={6} marginTop="6">
              <NotesInput
                notes={groupNote}
                label="Add notes for the entire group"
                setNotes={setGroupNote}
                minRows={4}
              />
            </GridItem>
          </Grid>
          <Box flex={1} marginTop="6">
            <Text fontSize="lg" marginBottom="2">
              Training plan
            </Text>
            {selectedDrills.length > 0 && (
              <Stack direction="column" spacing={{ mobile: "4", laptop: "2" }} height="100%">
                {selectedDrills.map((drill, index) => (
                  <DrillCard key={index} drill={drill} selected={true} disableHover={true} isMobile={props.isMobile} />
                ))}
              </Stack>
            )}
            {selectedSession && (
              <SessionCard session={selectedSession} readOnly={true} selected={true} isMobile={props.isMobile} />
            )}
            {selectedDrills.length === 0 && !selectedSession && (
              <Text textColor="blackAlpha.600">
                This training is still unplanned! You can create a plan from the "Training plan" tab.
              </Text>
            )}
          </Box>
        </CommonScrollbar>
      </Box>

      {/* Training structure tab */}
      <Box display="flex" height="100%" overflow={{ laptop: "hidden" }} id="training-plan-tab">
        <Tabs
          defaultIndex={selectedSession ? 1 : 0}
          variant="solid-rounded"
          colorScheme="orange"
          size="md"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <TabList justifyContent="center" marginBottom="2">
            <Grid templateColumns="repeat(2, 1fr)" columnGap="8">
              <GridItem colSpan={1}>
                <Tab
                  border="1px solid #00000029" // blackAlpha.300
                  transition="all 0.3s ease"
                  _hover={{ backgroundColor: "orange.500", color: "white", border: "1px solid transparent" }}
                >
                  Drills
                </Tab>
              </GridItem>
              <GridItem colSpan={1}>
                <Tab
                  border="1px solid #00000029" // blackAlpha.300
                  transition="all 0.3s ease"
                  _hover={{ backgroundColor: "orange.500", color: "white", border: "1px solid transparent" }}
                >
                  Sessions
                </Tab>
              </GridItem>
            </Grid>
          </TabList>

          <TabPanels overflow={{ laptop: "hidden" }}>
            <TabPanel padding="0" paddingTop="2" height="100%">
              <DrillLibrary
                allSkillSets={props.allSkillSets}
                drills={filteredDrills}
                selectedDrills={selectedDrills}
                onSelectDrill={handleSelectDrill}
                onSearchChange={handleSearchChange}
                searchQuery={searchQuery}
                searchTags={searchTags}
                searchSkillSets={searchSkillSets}
                showDrillCardCheckbox
                scrollbarHeight="100%"
                isMobile={props.isMobile}
              />
            </TabPanel>
            <TabPanel padding="0" paddingTop="2" height="100%">
              <SessionLibrary
                allSkillSets={props.allSkillSets}
                allTags={allSessionTags}
                onSearchChange={handleSearchChange}
                onSelectSession={handleSelectSession}
                searchQuery={searchQuery}
                searchTags={searchTags}
                searchSkillSets={searchSkillSets}
                sessions={filterSessions(props.allSessions, searchQuery, searchTags, searchSkillSets)}
                selectedSession={selectedSession}
                showSessionCardCheckbox={true}
                isMobile={props.isMobile}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </FormModal>
  );
};
