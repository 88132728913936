import {
  FormControl,
  FormLabel,
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import React from "react";

/** Props for the CommonNumberInput component. */
export type CommonNumberInputProps = {
  /** The current value of the input field. */
  value: string | number;
  /** Placeholder text for the input field. */
  placeholder: string;
  /** Callback function to handle changes in the input field. */
  onChange: (valueAsString: string, valueAsNumber: number) => void;
  /** Whether the input field is disabled. */
  disabled?: boolean;
  /** Whether the input field is required. */
  required?: boolean;
  /** Whether the input shadow is disabled. */
  disableShadow?: boolean;
};

/** A common number input component that supports different customization options. */
export const CommonNumberInput: React.FC<CommonNumberInputProps> = (props) => {
  const handleChange = (valueAsString: string, valueAsNumber: number) => {
    props.onChange(valueAsString, valueAsNumber);
  };

  return (
    <InputGroup>
      <FormControl id="common-input">
        <FormLabel
          zIndex={2}
          top="-5px"
          lineHeight="10px"
          marginLeft="10px"
          position="absolute"
          backgroundColor="white"
          paddingLeft="4px"
          paddingRight="4px"
          width="fit-content"
          fontSize="small"
          color="blackAlpha.600"
          display={props.value ? "flex" : "none"}
        >
          {props.placeholder}
        </FormLabel>
        <NumberInput
          variant="outline"
          min={0}
          value={props.value || 0}
          onChange={handleChange}
          isRequired={props.required}
          height="42px"
          opacity={props.disabled ? 0.6 : 1}
          shadow={props.disableShadow ? "none" : "base"}
          border="1px solid"
          borderRadius="md"
          borderColor="blackAlpha.300"
          focusBorderColor="orange.500"
          transition="all 0.3s ease"
          _hover={{ borderColor: "orange.500", shadow: props.disableShadow ? undefined : "sm" }}
          _focus={{ boxShadow: "none" }}
          isDisabled={props.disabled}
          data-testid="common-number-input"
        >
          <NumberInputField border="none" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
    </InputGroup>
  );
};
