import React from "react";
import { MultiTagSelectMenu } from "../MultiTagSelectMenu";
import { Coach } from "../../types";

type DisabledProps = {
  /** When set to true, the component is read-only */
  disabled: true;
};

type EnabledProps = {
  /** Callback function for updating selected coaches */
  setSelectedCoach: (coaches: Coach[]) => void;
  /** Array of available coaches */
  availableCoaches: Coach[];
  /** When set to true, the component is read-only */
  disabled?: false;
};

export type CoachSelectProps = (DisabledProps | EnabledProps) & {
  /** Array of selected coaches */
  selectedCoaches: Coach[];
};

/**
 * CoachSelect component allows the user to select a coach. The list of available coaches is determined by the
 * 'availableCoaches' property. The currently selected coach is determined by the 'selectedCoach' property.
 */
export const CoachSelect: React.FC<CoachSelectProps> = (props) => {
  /**
   * Handles the change of selected coaches. Updates the selected coaches using the 'setSelectedCoach' function.
   *
   * @param newValue - The new array of selected coaches
   */
  const handleChange = (newValue: Coach[]) => {
    if (!props.disabled && props.setSelectedCoach) {
      props.setSelectedCoach(newValue);
    }
  };

  return (
    <MultiTagSelectMenu
      options={props.disabled ? [] : props.availableCoaches}
      getOptionLabel={(option) => option.name}
      selectedTags={props.selectedCoaches}
      handleTagSelection={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      label="Coach"
      disableActive={true}
      disabled={props.disabled}
      dataTestId="coach-select"
    />
  );
};
