import React, { useState } from "react";
import type { Coach, Court, RecurringTrainingEvent } from "../../../types";
import { Stack } from "@chakra-ui/react";
import { CommonButton } from "../../../components/CommonButton";
import { CommonSelect } from "../../../components/CommonSelect";
import { EventEditorRow } from "./EventEditorRow";

/** Component for adding the player to a new group */
export const GroupAdder: React.FC<{
  allTrainingEvents: RecurringTrainingEvent[];
  allCoaches: Coach[];
  allCourts: Court[];
  onSave: (eventToSave: RecurringTrainingEvent) => void;
  onCancel: () => void;
  loading?: boolean;
}> = ({ allTrainingEvents, allCoaches, allCourts, onSave, onCancel, loading }) => {
  const [isSelectingExistingGroup, setIsSelectingExistingGroup] = useState(true);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [newEvent, setNewEvent] = useState<RecurringTrainingEvent | null>(null);

  const handleSelectExistingGroup = (groupId: string) => {
    setSelectedGroupId(groupId);
  };

  const handleCreateNewGroup = () => {
    setNewEvent({
      id: undefined,
      name: "",
      recurrenceWeekday: 1,
      recurrenceStartTime: "09:00",
      recurrenceEndTime: "10:30",
      court: allCourts[0],
      coaches: [],
      players: [],
    });
  };

  const handleChange = (field: keyof RecurringTrainingEvent, value: any) => {
    setNewEvent((prevEvent) => {
      const updatedEvent = prevEvent ? { ...prevEvent } : null;
      if (updatedEvent) {
        switch (field) {
          case "recurrenceWeekday":
            updatedEvent.recurrenceWeekday = value as number;
            break;
          case "recurrenceStartTime":
            updatedEvent.recurrenceStartTime = value as string;
            break;
          case "recurrenceEndTime":
            updatedEvent.recurrenceEndTime = value as string;
            break;
          case "court":
            updatedEvent.court = allCourts.find((court) => court.id === value) as Court;
            break;
          case "coaches":
            updatedEvent.coaches = allCoaches.filter((coach) => (value as string[]).includes(coach.id || ""));
            break;
          case "name":
            updatedEvent.name = value as string;
            break;
        }
      }
      return updatedEvent;
    });
  };

  const handleSave = () => {
    if (isSelectingExistingGroup && selectedGroupId) {
      const selectedEvent = allTrainingEvents.find((event) => event.id === selectedGroupId);
      if (selectedEvent) {
        onSave(selectedEvent);
      }
    } else if (newEvent) {
      onSave(newEvent);
    }
  };

  return (
    <Stack direction="column" spacing="4">
      <Stack direction="row" spacing="6" alignItems="center" justify="center">
        {/* TODO: maybe unite with ViewEventModal Tab-switcher, instead of buttons */}
        <CommonButton
          variantType={isSelectingExistingGroup ? "solidPrimary" : "outlineSecondary"}
          onClick={() => {
            setIsSelectingExistingGroup(true);
            setNewEvent(null);
          }}
        >
          Select existing group
        </CommonButton>
        <CommonButton
          variantType={!isSelectingExistingGroup ? "solidPrimary" : "outlineSecondary"}
          onClick={() => {
            setIsSelectingExistingGroup(false);
            setSelectedGroupId(null);
            handleCreateNewGroup();
          }}
          dataTestId="create-new-group"
        >
          Create new group
        </CommonButton>
      </Stack>

      {isSelectingExistingGroup ? (
        <CommonSelect
          placeholder={allTrainingEvents.length === 0 ? "No groups available" : "Group"}
          value={selectedGroupId || undefined}
          options={allTrainingEvents.map((event) => ({
            value: event.id || "",
            label: event.name || "",
          }))}
          onChange={(e) => handleSelectExistingGroup(e.target.value)}
          data-testid="select-existing-group"
        />
      ) : (
        newEvent && (
          <EventEditorRow
            event={newEvent}
            allCoaches={allCoaches}
            allCourts={allCourts}
            onChange={handleChange}
            allDisabled={loading}
            dataTestId="new-recurring-training-event"
          />
        )
      )}

      <Stack direction="row" spacing="4" justifyContent="end">
        <CommonButton
          variantType="outlineSecondary"
          onClick={onCancel}
          dataTestId="cancel-new-recurring-training-event"
        >
          Cancel
        </CommonButton>
        <CommonButton
          variantType="solidPrimary"
          onClick={handleSave}
          disabled={isSelectingExistingGroup ? !selectedGroupId : !newEvent || newEvent.name?.trim() === ""}
          tooltip={
            isSelectingExistingGroup && !selectedGroupId
              ? "Group must be selected"
              : newEvent?.name?.trim() === ""
                ? "Group name cannot be empty"
                : ""
          }
          dataTestId="save-new-recurring-training-event"
        >
          Save
        </CommonButton>
      </Stack>
    </Stack>
  );
};
