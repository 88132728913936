import { AddIcon } from "@chakra-ui/icons";
import { IconButton, Tooltip } from "@chakra-ui/react";
import React from "react";

/** Props for the CommonIconButton component. */
type CommonIconButtonProps = {
  /** Function to be called when the button is clicked */
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  /** Whether the button should take the full width of its container */
  fullWidth?: boolean;
  /** Custom height for the button */
  height?: string;
  /** Whether the button is disabled */
  disabled?: boolean;
  /** Custom icon for the button, defaults to AddIcon */
  icon?: React.ReactElement;
  /** Custom background color for the button, defaults to "whiteAlpha.900" */
  backgroundColor?: string;
  /** Custom border color for the button, defaults to "blackAlpha.300" */
  borderColor?: string;
  /** The color of the button, defaults to orange. */
  color?: string;
  /** The color of the button on hover, defaults to orange. Changes hover border color to this as well */
  hoverColor?: string;
  /** Whether to disable the button shadow */
  disableShadow?: boolean;
  /** Tooltip text to be displayed on hover */
  tooltip?: string;
  /** The aria-label attribute to be added to the button, defaults to "Add new" */
  ariaLabel?: string;
  /** The data-testid attribute to be added to the button */
  dataTestId?: string;
};

/** A common icon button component with customizable properties. */
export const CommonIconButton: React.FC<CommonIconButtonProps> = (props) => {
  return (
    <Tooltip label={props.tooltip} isDisabled={!props.tooltip} placement="top">
      <IconButton
        aria-label={props.ariaLabel || "Add new"}
        variant="outline"
        icon={props.icon || <AddIcon color={props.color} />}
        onClick={props.onClick}
        isDisabled={props.disabled}
        height={props.height}
        width={props.fullWidth ? "100%" : "auto"}
        bgColor={props.backgroundColor || "whiteAlpha.900"}
        borderColor={props.borderColor || "blackAlpha.300"}
        borderRadius="md"
        shadow={props.disableShadow ? "none" : "base"}
        _hover={{
          shadow: props.disableShadow ? "none" : "inner",
          borderColor: props.hoverColor || "orange.400",
          color: props.hoverColor || "orange.400",
        }}
        transition="all 0.3s ease"
        data-testid={props.dataTestId}
      />
    </Tooltip>
  );
};
