import { gql } from "@apollo/client";

export const GET_ALL_RECURRING_TRAINING_EVENT_METADATAS_FOR_PLAYERS = gql`
  query GetAllRecurringTrainingEventMetadatasForPlayers {
    getAllRecurringTrainingEventMetadatas {
      id
      players {
        id
      }
    }
  }
`;
