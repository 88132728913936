import React from "react";
import { CommonScrollbar } from "../../../../../../components/CommonScrollbar/CommonScrollbar";
import { TreeNode, type TreeNodeData } from "./components/TreeNode";

interface StepTrainingGroupsProps {
  treeData: TreeNodeData;
  onCheck: (currentNode: TreeNodeData, targetNodeId: string, checked: boolean) => void;
}

export const StepTrainingGroups: React.FC<StepTrainingGroupsProps> = ({ treeData, onCheck }) => {
  return (
    <CommonScrollbar maxHeight="100%" overflow="auto">
      <TreeNode node={treeData} onCheck={(node, checked) => onCheck(treeData, node.id, checked)} />
    </CommonScrollbar>
  );
};
