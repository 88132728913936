import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { CommonDayPicker } from "../../../../../../components/CommonDayPicker";
import { convertToLocalDateTime, toUTCString } from "../../../../../../components/utils";

interface StepDatesProps {
  startDate: string | undefined;
  endDate: string | undefined;
  setStartDate: (date: string | undefined) => void;
  setEndDate: (date: string | undefined) => void;
}

export const StepDates: React.FC<StepDatesProps> = ({ startDate, endDate, setStartDate, setEndDate }) => {
  return (
    <Grid templateColumns="(1fr 1fr)" columnGap="4" rowGap="8">
      <GridItem colSpan={{ mobile: 2, laptop: 1 }}>
        <CommonDayPicker
          initialDate={startDate ? convertToLocalDateTime(startDate) : undefined}
          onDateChange={(newDate) => setStartDate(newDate ? toUTCString(newDate) : undefined)}
          label="Start Date"
        />
      </GridItem>
      <GridItem colSpan={{ mobile: 2, laptop: 1 }}>
        <CommonDayPicker
          initialDate={endDate ? convertToLocalDateTime(endDate) : undefined}
          onDateChange={(newDate) => setEndDate(newDate ? toUTCString(newDate) : undefined)}
          label="End Date"
        />
      </GridItem>
    </Grid>
  );
};
