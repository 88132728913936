import { FormControl, FormLabel, Input, InputGroup } from "@chakra-ui/react";
import React from "react";

/** Props for the CommonInput component. */
export type CommonInputProps = {
  /** The current value of the input field. */
  value: string;
  /** Placeholder text for the input field. */
  placeholder: string;
  /** Callback function to handle changes in the input field. */
  onChange: (value: string) => void;
  /** Whether the input field is disabled. */
  disabled?: boolean;
  /** Whether the input field is required. */
  required?: boolean;
  /** Whether the input shadow is disabled. */
  disableShadow?: boolean;
  /** The data-testid attribute to be added to the input field. */
  dataTestId?: string;
  /** The type of the input field. */
  type?: string;
};

/** A common input component that supports different customization options. */
export const CommonInput: React.FC<CommonInputProps> = (props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  };

  return (
    <InputGroup>
      <FormControl id="common-input">
        <FormLabel
          zIndex={2}
          top="-5px"
          lineHeight="10px"
          marginLeft="10px"
          position="absolute"
          backgroundColor="white"
          paddingLeft="4px"
          paddingRight="4px"
          width="fit-content"
          fontSize="small"
          color="blackAlpha.600"
          display={props.value ? "flex" : "none"}
        >
          {props.placeholder}
        </FormLabel>
        <Input
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleChange}
          required={props.required}
          height="42px"
          opacity={props.disabled ? 0.6 : 1}
          shadow={props.disableShadow ? "none" : "base"}
          borderColor="blackAlpha.300"
          transition="all 0.3s ease"
          _hover={{ borderColor: "orange.500", shadow: props.disableShadow ? undefined : "sm" }}
          _focus={{ boxShadow: "none" }}
          isDisabled={props.disabled}
          type={props.type}
          data-testid={props.dataTestId}
        />
      </FormControl>
    </InputGroup>
  );
};
