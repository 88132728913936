import React from "react";
import { SessionCard } from "../SessionCard";
import { Session, type SkillSet } from "../../../../../types";
import { MultiTagSelectMenu } from "../../../../../components/MultiTagSelectMenu";
import { Box, Divider, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { CommonInput } from "../../../../../components/CommonInput";
import { CommonScrollbar } from "../../../../../components/CommonScrollbar/CommonScrollbar";
import { LoadingOverlay } from "../../../../../components/LoadingOverlay";

/** Interface for SessionLibrary properties */
type SessionLibraryProps = {
  /** Callback function for selecting a session */
  onSelectSession: (session: Session) => void;
  /** Callback function for changing the search query */
  onSearchChange: (searchQuery: string, searchTags: string[], searchSkillSets: SkillSet[]) => void;
  /** Array of sessions */
  sessions: Session[];
  /** The current search query */
  searchQuery: string;
  /** The current search tags */
  searchTags: string[];
  /** Current search SkillSets */
  searchSkillSets: SkillSet[];
  /** The current set of all tags */
  allTags: string[];
  /** Array of all skill sets */
  allSkillSets: SkillSet[];
  /** The currently selected session */
  selectedSession?: Session;
  /** Whether to show the session card checkbox */
  showSessionCardCheckbox?: boolean;
  /** Height of the CommonScrollbar element */
  scrollbarHeight?: string;
  /** Whether the user is using a mobile device */
  isMobile?: boolean;
  /** Whether to display created by & created at on SessionCard */
  displayMetadata?: boolean;
  /** Whether the component is loading */
  isLoading?: boolean;
};

/** SessionLibrary component */
export const SessionLibrary: React.FC<SessionLibraryProps> = (props) => {
  const handleSearchChange = (value: string) => {
    props.onSearchChange(value, props.searchTags, props.searchSkillSets);
  };

  const handleTagChange = (tags: string[]) => {
    props.onSearchChange(props.searchQuery, tags, props.searchSkillSets);
  };

  const handleSkillSetChange = (skillSets: SkillSet[]) => {
    props.onSearchChange(props.searchQuery, props.searchTags, skillSets);
  };

  return (
    <Box data-testid="session-library" height="100%" display="flex" flexDirection="column" position="relative">
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="120px" />
      <Grid templateColumns="repeat(3, 1fr)" columnGap="2" rowGap="6" marginBottom="6">
        <GridItem colSpan={{ mobile: 3, laptop: 1 }}>
          <CommonInput
            placeholder="Filter by name, goal or Drill name"
            value={props.searchQuery}
            onChange={handleSearchChange}
            dataTestId="search-sessions"
          />
        </GridItem>
        <GridItem colSpan={{ mobile: 3, laptop: 1 }}>
          <MultiTagSelectMenu
            selectedTags={props.searchTags}
            handleTagSelection={handleTagChange}
            label="Filter by tags"
            options={props.allTags}
            getOptionLabel={(option) => option}
          />
        </GridItem>
        <GridItem colSpan={{ mobile: 3, laptop: 1 }}>
          <MultiTagSelectMenu
            selectedTags={props.searchSkillSets}
            handleTagSelection={handleSkillSetChange}
            label="Filter by Skill Sets"
            options={props.allSkillSets || []}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            dataTestId="session-skill-set-filter"
          />
        </GridItem>
        <GridItem colSpan={3}>
          <Divider />
        </GridItem>
      </Grid>
      <CommonScrollbar height={props.scrollbarHeight} overflow="auto" isMobile={props.isMobile}>
        <Stack direction="column" spacing={{ mobile: "4", laptop: "2" }}>
          {props.sessions.length === 0 ? (
            <Text fontSize="large" textAlign="center">
              {props.searchQuery || props.searchTags || props.searchSkillSets
                ? "No sessions match the applied filters. Clear filters to view all sessions."
                : "No sessions available"}
            </Text>
          ) : (
            props.sessions.map((session, index) => (
              <SessionCard
                key={index}
                session={session}
                onSelectSession={props.onSelectSession}
                selected={props.selectedSession && session.id === props.selectedSession.id}
                showCheckbox={props.showSessionCardCheckbox}
                isMobile={props.isMobile}
                displayMetadata={props.displayMetadata}
                dataTestId={`session-card-${index}`}
              />
            ))
          )}
        </Stack>
      </CommonScrollbar>
    </Box>
  );
};
