import React from "react";
import { Drill, type SkillSet } from "../../../../../types";
import { MultiTagSelectMenu } from "../../../../../components/MultiTagSelectMenu";
import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { DrillCard } from "../../../../../components/DrillCard";
import { CommonInput } from "../../../../../components/CommonInput";
import { CommonTextArea } from "../../../../../components/CommonTextArea";
import { CommonScrollbar } from "../../../../../components/CommonScrollbar/CommonScrollbar";

/** Props for the ReviewAndSaveStep component */
type ReviewAndSaveStepProps = {
  /** Function to change the name of the session */
  onNameChange: (name: string) => void;
  /** Function to change the goal of the session */
  onGoalChange: (goal: string) => void;
  /** Function to change the tags of the session */
  onTagsChange: (tags: string[]) => void;
  /** Function to change the related Skill Sets */
  onSkillSetsChange: (skillSets: SkillSet[]) => void;
  /** Array of all possible tags */
  allTags: string[];
  /** All available Skill Sets */
  allSkillSets: SkillSet[];
  /** Array of selected drills */
  selectedDrills: Drill[];
  /** Array of selected durations for the drills */
  durations: number[];
  /** Name of the session */
  name: string;
  /** Goal of the session */
  goal: string;
  /** Array of tags of the session */
  tags: string[];
  /** Array of SkillSets of the session */
  skillSets: SkillSet[];
};

/** Component for the review and save step of the session builder */
export const ReviewAndSaveStep: React.FC<ReviewAndSaveStepProps> = (props) => {
  const totalDuration = props.durations.reduce((total, duration) => total + duration, 0);

  let runningTotal = 0;

  return (
    <Stack spacing="4" data-testid="session-modal-step-review" height="100%">
      {props.selectedDrills.length > 0 ? (
        <>
          <CommonScrollbar overflow="auto" height="calc(100% - 56px - 138px - 16px)">
            <Stack spacing="2">
              {props.selectedDrills.map((drill, index) => {
                const drillStart = runningTotal;
                const drillEnd = runningTotal + props.durations[index];
                runningTotal += props.durations[index];

                return (
                  <DrillCard
                    key={index}
                    disableShadow
                    disableHover
                    selected
                    drill={drill}
                    title={
                      <Stack direction="row" justifyContent="space-between">
                        <Text fontSize="large">{drill.name}</Text>
                        <Text fontSize="medium">{`${drillStart} - ${drillEnd} min`}</Text>
                      </Stack>
                    }
                  />
                );
              })}
            </Stack>
          </CommonScrollbar>
          <Text textAlign="center" fontSize="medium" padding="4">
            {`Total planned duration for this session: ${totalDuration} minutes.`}
          </Text>
        </>
      ) : (
        <Text textAlign="center" fontSize="medium">
          No drills planned for this session. If you want to add drills, go back to the previous step.
        </Text>
      )}
      <Grid templateColumns="repeat(2, 1fr)" gap="4">
        <GridItem colSpan={1}>
          <CommonInput
            required
            disableShadow
            placeholder="Session Name"
            value={props.name}
            onChange={(value) => props.onNameChange(value)}
            dataTestId="session-name-input"
          />
        </GridItem>
        <GridItem colSpan={1}>
          <MultiTagSelectMenu
            disableShadow
            allowCreateTag
            selectedTags={props.tags}
            handleTagSelection={props.onTagsChange}
            handleCreateTag={(tag) => props.onTagsChange([...props.tags, tag])}
            getOptionLabel={(option) => option}
            label="Session Tags"
            options={props.allTags}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <CommonTextArea
            required
            disableShadow
            placeholder="Session Goal"
            value={props.goal}
            onChange={(value) => props.onGoalChange(value)}
            dataTestId="session-goal-input"
          />
        </GridItem>
        <GridItem colSpan={2}>
          <MultiTagSelectMenu
            disableShadow
            selectedTags={props.skillSets || []}
            handleTagSelection={(newSkillSets) => {
              props.onSkillSetsChange(newSkillSets);
            }}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            label="Related Skill Sets"
            options={props.allSkillSets}
            dataTestId="session-skill-set-tags"
          />
        </GridItem>
      </Grid>
    </Stack>
  );
};
