import { gql } from "@apollo/client";

export const SAVE_COACH = gql`
  mutation SaveCoach($data: CoachInput!) {
    saveCoach(data: $data) {
      id
      name
    }
  }
`;
