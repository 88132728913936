import { gql } from "@apollo/client";

export const SAVE_RECURRING_TRAINING_EVENT = gql`
  mutation SaveRecurringTrainingEvent($data: RecurringTrainingEventInput!) {
    saveRecurringTrainingEvent(data: $data) {
      id
      recurrenceWeekday
      recurrenceStartTime
      recurrenceEndTime
      coaches {
        id
        name
      }
      court {
        id
        name
      }
      players {
        id
        name
      }
    }
  }
`;
