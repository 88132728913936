import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import { SkillSetLibrary } from "./components/SkillSetLibrary";
import { SkillSetDisplayCardProps } from "./components/components/SkillSetDisplayCard";

interface StepSkillSetsProps {
  allSkillSets: SkillSetDisplayCardProps["skillSet"][];
  handleSelectSkillSet: (selectedSkillSet: SkillSetDisplayCardProps["skillSet"]) => void;
  handleSelectSkill: (selectedSkill: SkillSetDisplayCardProps["skillSet"]["skills"][0]) => void;
  isMobile: boolean;
}

export const StepSkillSets: React.FC<StepSkillSetsProps> = ({
  allSkillSets,
  handleSelectSkillSet,
  handleSelectSkill,
  isMobile,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredSkillSets = allSkillSets.filter((skillSet) => {
    const matchesQuery =
      searchQuery === "" ||
      skillSet.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      skillSet.skills.map((skill) => skill.name.toLowerCase().includes(searchQuery.toLowerCase())).includes(true);

    return matchesQuery;
  });

  return (
    <Box overflow="hidden">
      <SkillSetLibrary
        skillSets={filteredSkillSets}
        onSelectSkillSet={handleSelectSkillSet}
        onSelectSkill={handleSelectSkill}
        onSearchChange={setSearchQuery}
        searchQuery={searchQuery}
        showSkillSetCardCheckbox={true}
        isMobile={isMobile}
      />
    </Box>
  );
};
