import React from "react";
import { Coach, Court, Player, Drill, type Session, type SkillSet } from "../../../../types";
import { ViewEventModal } from "./ViewEventModal/ViewEventModal";
import { AddEventModal } from "./AddEventModal/AddEventModal";
import { Box } from "@chakra-ui/react";
import type { FullCalendarEvent, TrainingEventUpdateInput } from "../../types";

/** Props for the CalendarModals component. */
export type CalendarModalsProps = {
  /** Indicates if the view event modal is open. */
  isViewModalOpen: boolean;
  /** Function to save the updated training event data. */
  saveTrainingEventUpdate: (
    trainingEventType: FullCalendarEvent["trainingEventType"],
    updatedData: TrainingEventUpdateInput,
  ) => void;
  /** Information of the selected event. */
  selectedEventInfo: FullCalendarEvent;
  /** Indicates if the add event modal is open. */
  isAddEventModalOpen: boolean;
  /** Function to save the new training event data. */
  saveNewSingleTrainingEvent: (singleTrainingEventInput: TrainingEventUpdateInput) => void;
  /** The selected date and time for the event. */
  selectedDateTime: string | undefined;
  /** The selected start time for the event. */
  selectedStartTime: string | null;
  /** The selected end time for the event. */
  selectedEndTime: string | null;
  /** The selected court for the event in day view. */
  dayViewSelectedCourt: Court | null;
  /** List of all coaches. */
  allCoaches: Coach[];
  /** List of all courts. */
  allCourts: Court[];
  /** List of all players. */
  allPlayers: Player[];
  /** List of all drills. */
  allDrills: Drill[];
  /** List of all sessions. */
  allSessions: Session[];
  /** List of all skill sets. */
  allSkillSets: SkillSet[];
  /** Function to handle closing of the add event modal. */
  handleAddEventModalClose: () => void;
  /** Function to handle closing of the view event modal. */
  handleViewEventModalClose: () => void;
  /** Indicates if the user is using a mobile device. */
  isMobile: boolean;
};

/** A component that manages the modals for viewing and adding calendar events. */
export const CalendarModals: React.FC<CalendarModalsProps> = (props) => {
  return (
    <Box id="calendar-modals">
      <ViewEventModal
        key={JSON.stringify(props.selectedEventInfo)} // to make absolutely sure that event has up to date info (note id missing after initial creation)
        open={props.isViewModalOpen}
        onClose={props.handleViewEventModalClose}
        onSave={props.saveTrainingEventUpdate}
        event={props.selectedEventInfo}
        allCoaches={props.allCoaches}
        allCourts={props.allCourts}
        allPlayers={props.allPlayers}
        allDrills={props.allDrills}
        allSessions={props.allSessions}
        allSkillSets={props.allSkillSets}
        isMobile={props.isMobile}
      />
      <AddEventModal
        key={Math.random()}
        open={props.isAddEventModalOpen}
        onSave={props.saveNewSingleTrainingEvent}
        onClose={props.handleAddEventModalClose}
        initialDate={props.selectedDateTime}
        initialTime={props.selectedStartTime}
        initialEndTime={props.selectedEndTime}
        initialCourt={props.dayViewSelectedCourt || props.allCourts[0] || null}
        allCoaches={props.allCoaches}
        allCourts={props.allCourts}
        allPlayers={props.allPlayers}
        allDrills={props.allDrills}
        allSessions={props.allSessions}
        allSkillSets={props.allSkillSets}
        isMobile={props.isMobile}
      />
    </Box>
  );
};
