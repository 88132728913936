import React, { useEffect, useRef, useState } from "react";
import { Repeat, TimesOneMobiledata } from "@mui/icons-material";

import { PlayerInput } from "../PlayerInput";
import { CoachSelect } from "../../../../../components/CoachSelect";

import { FormModal } from "../../../../../components/FormModal";
import { Coach, Court, Drill, Player, type Note, type Session, type SkillSet } from "../../../../../types";
import { CourtSelect } from "../../../../../components/CourtSelect";
import { DrillLibrary } from "../../../../../components/DrillLibrary";
import { DrillCard } from "../../../../../components/DrillCard";
import { filterSessions, formatDateToUTC, updateUTCTime } from "../../../../../components/utils";
import { PlayerTab } from "./PlayerTab";
import { Box, Grid, GridItem, Icon, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";

import { TimePicker } from "../../../../../components/TimePicker";
import { CommonDayPicker } from "../../../../../components/CommonDayPicker";
import type { FullCalendarEvent, TrainingEventUpdateInput } from "../../../types";
import { extractTimeFromUTC } from "../../../utils";
import { CommonScrollbar } from "../../../../../components/CommonScrollbar/CommonScrollbar";
import { SessionLibrary } from "../../../../TrainingLibrary/components/SessionManager/SessionLibrary";
import { SessionCard } from "../../../../TrainingLibrary/components/SessionManager/SessionCard";
import { SeasonPlanCard } from "./SeasonPlanCard";
import { NotesInput } from "../NotesInput";

type ViewEventModalProps = {
  /** Callback function for saving changes to the event */
  onSave: (trainingEventType: FullCalendarEvent["trainingEventType"], dataToSave: TrainingEventUpdateInput) => void;
  /** Callback function for closing the modal */
  onClose: () => void;
  /** Whether the modal is open or not */
  open: boolean;
  /** The event to display and edit */
  event: FullCalendarEvent;
  allCoaches: Coach[];
  allCourts: Court[];
  allPlayers: Player[];
  allDrills: Drill[];
  allSessions: Session[];
  allSkillSets: SkillSet[];
  isMobile: boolean;
};

/** A modal component for viewing and editing an event. */
export const ViewEventModal: React.FC<ViewEventModalProps> = (props) => {
  const initialEventRef = useRef<FullCalendarEvent>(props.event);
  const [selectedDate, setSelectedDate] = useState<string>(props.event.date?.toISOString() || new Date().toISOString());
  const [selectedStartTime, setSelectedStartTime] = useState(props.event.start);
  const [selectedEndTime, setSelectedEndTime] = useState(props.event.end);
  const [selectedPlayers, setSelectedPlayers] = useState<Player[]>(props.event.players);
  const [selectedCoaches, setSelectedCoaches] = useState<Coach[]>(props.event.coach.map((coach) => coach));
  const [selectedCourt, setSelectedCourt] = useState<Court>(props.event.court);
  const [groupNote, setGroupNote] = useState(props.event.groupNote);
  const [playerNotes, setPlayerNotes] = useState(props.event.playerNotes);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [searchSkillSets, setSearchSkillSets] = useState<SkillSet[]>(
    props.event.seasonPlans?.flatMap((seasonPlan) => seasonPlan.skillSets.map((spss) => spss.skillSet) || []) || [],
  );
  const [selectedDrills, setSelectedDrills] = useState<Drill[]>(props.event.drills || []);
  const [selectedSession, setSelectedSession] = useState(props.event.session || null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const allSessionTags = Array.from(
    new Set(props.allSessions.flatMap((session) => (session.tags ? session.tags : []))),
  );

  /**
   * Updates the state variable 'isSubmitDisabled' based on the changes in the event data. If there are any changes, the
   * submit button is enabled. Otherwise, it is disabled.
   */
  const checkForChanges = () => {
    const initialEvent = initialEventRef.current;
    const dateChanged = selectedDate !== initialEvent.date?.toISOString();
    const startTimeChanged = selectedStartTime !== initialEvent.start;
    const endTimeChanged = selectedEndTime !== initialEvent.end;
    const playersChanged =
      selectedPlayers.length !== initialEvent.players.length ||
      selectedPlayers.some((player, index) => player.id !== initialEvent.players[index]?.id);
    const coachesChanged =
      selectedCoaches.length !== initialEvent.coach.length ||
      selectedCoaches.some((coach, index) => coach.id !== initialEvent.coach[index]?.id);
    const courtChanged = selectedCourt.id !== initialEvent.court.id;
    const groupNoteChanged = (groupNote?.content || "") !== (initialEvent.groupNote?.content || "");
    const createPlayerNoteMap = (notes: Note[]) => {
      const map: { [key: string]: string } = {};
      notes.forEach((note) => {
        if (note.content && note.players) {
          note.players.forEach((player) => {
            map[player.id || ""] = note.content?.trim() || "";
          });
        }
      });
      return map;
    };
    const currentPlayerNotesMap = createPlayerNoteMap(playerNotes);
    const initialPlayerNotesMap = createPlayerNoteMap(initialEvent.playerNotes);
    const playerNotesChanged = selectedPlayers.some((player) => {
      const currentNoteContent = currentPlayerNotesMap[player.id || ""];
      const initialNoteContent = initialPlayerNotesMap[player.id || ""];
      return currentNoteContent !== initialNoteContent;
    });
    const drillsChanged =
      selectedDrills.length !== (initialEvent.drills?.length || 0) ||
      selectedDrills.some((drill, index) => drill.id !== initialEvent.drills?.[index]?.id);
    const sessionChanged = selectedSession?.id !== initialEvent.session?.id;

    const anyChanges =
      dateChanged ||
      startTimeChanged ||
      endTimeChanged ||
      playersChanged ||
      coachesChanged ||
      courtChanged ||
      groupNoteChanged ||
      playerNotesChanged ||
      drillsChanged ||
      sessionChanged;
    setIsSubmitDisabled(!anyChanges);
  };

  useEffect(() => {
    checkForChanges();
  }, [
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    selectedPlayers,
    selectedCoaches,
    selectedCourt,
    groupNote,
    playerNotes,
    selectedDrills,
    selectedSession,
  ]);

  /** Handles the save action by creating an TrainingEventUpdateInput object and passing it to the onSave callback. */
  const saveTrainingEvent = (remove: boolean) => {
    const trainingEventUpdateInput: TrainingEventUpdateInput = {
      id: props.event.id,
      coachIds: selectedCoaches.map((coach) => coach.id || ""),
      playerIds: selectedPlayers.map((player) => player.id || ""),
      notes: [
        {
          id: groupNote?.id,
          isGroupNote: true,
          content: groupNote?.content || "",
          playerIds: selectedPlayers.map((player) => player.id || ""),
          remove: !groupNote?.content || groupNote?.content?.trim() === "",
        },
        ...playerNotes.map((note) => ({
          id: note.id,
          content: note.content || "",
          playerIds: note.players?.map((player) => player.id || "") || [],
          remove: !note.content || note.content.trim() === "",
        })),
      ],
      courtId: selectedCourt.id || "",
      startDateTime: selectedStartTime,
      endDateTime: selectedEndTime,
      drillIds: selectedDrills.map((drill) => drill.id || ""),
      sessionId: selectedSession?.id,
      remove: remove,
    };
    props.onSave(props.event.trainingEventType, trainingEventUpdateInput);
  };

  /**
   * Handles the selection of a drill. If the selected drill is already selected, it deselects it. Otherwise, it adds
   * the drill to the selected drills.
   *
   * @param drill - The selected drill.
   */
  const handleSelectDrill = (drill: Drill) => {
    if (selectedDrills.find((d) => d.id === drill.id)) {
      setSelectedDrills(selectedDrills.filter((d) => d.id !== drill.id));
    } else {
      setSelectedDrills([...selectedDrills, drill]);
    }
    setSelectedSession(null);
  };

  const handleSelectSession = (session: Session) => {
    if (selectedSession && selectedSession.id === session.id) {
      setSelectedSession(null);
    } else {
      setSelectedSession(session);
    }
    setSelectedDrills([]);
  };

  /**
   * Handles the changes in search query and search tags. Updates the state variables 'searchQuery' and 'searchTags'.
   *
   * @param newSearchQuery - The new search query.
   * @param newSearchTags - The new search tags.
   */
  const handleSearchChange = (newSearchQuery: string, newSearchTags: string[], newSearchSkillSets: SkillSet[]) => {
    setSearchQuery(newSearchQuery);
    setSearchTags(newSearchTags);
    setSearchSkillSets(newSearchSkillSets);
  };

  // Filter the drills based on the input(s)
  const filteredDrills = props.allDrills.filter((drill) => {
    // Check if the drill matches the search query
    const matchesSearchQuery =
      searchQuery === "" ||
      drill.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      drill.goal.toLowerCase().includes(searchQuery.toLowerCase()) ||
      drill.implementation.toLowerCase().includes(searchQuery.toLowerCase());
    // Check if the drill matches the search tags
    const matchesSearchTags = searchTags.every((searchTag) => drill.tags?.includes(searchTag));
    // Check if the drill matches the search skill sets
    const matchesSearchSkillSets = searchSkillSets.every((searchSkillSet) =>
      drill.skillSets?.some((drillSkillSet) => drillSkillSet.id === searchSkillSet.id),
    );

    // Return the drill if it matches all the search criteria
    return matchesSearchQuery && matchesSearchTags && matchesSearchSkillSets;
  });

  /**
   * Handles the selection of players. Updates the state variable 'selectedPlayers'.
   *
   * @param players - An array of selected players.
   */
  const handleSelectPlayers = (players: Player[]) => {
    setSelectedPlayers(players);
  };

  /**
   * Handles the change in the current tab. Updates the state variable 'tabIndex'.
   *
   * @param newValue - The new value of the tab index.
   */
  const handleTabChange = (newValue: number) => {
    setTabIndex(newValue);
  };

  /**
   * Handles the change in an player note
   *
   * @param playerId - The player ID of note to update
   * @param newNote - The new value of the note
   */
  const handlePlayerNoteUpdate = (playerId: string | undefined, newNote: string) => {
    const noteToUpdate = playerNotes.find((note) => note.players?.find((player) => player.id === playerId));
    if (!noteToUpdate) {
      // create note for player
      const createdNote: Note = {
        content: newNote,
        players: selectedPlayers.filter((player) => player.id === playerId),
      };
      // add created note to existing notes
      setPlayerNotes((prev) => [...prev, createdNote]);
    } else {
      // update existing note
      const updatedNote: Note = { ...noteToUpdate, content: newNote };
      setPlayerNotes((prev) =>
        prev.map((note) => (note.players?.find((player) => player.id === playerId) ? updatedNote : note)),
      );
    }
  };

  const handleGroupNoteUpdate = (newNote: string) => {
    setGroupNote((prev) => ({ ...prev, content: newNote }));
  };

  /** Handles the removal action, by saving training event with "remove": true */
  const handleRemove = () => {
    saveTrainingEvent(true);
  };

  /** Handles the save action, by saving training event with "remove": false */
  const handleSave = () => {
    saveTrainingEvent(false);
  };

  const handleDateChange = (date?: Date) => {
    if (!date) {
      // date is cleared
      return;
    }
    const utcDate = updateUTCTime(formatDateToUTC(date), extractTimeFromUTC(selectedDate));
    setSelectedDate(utcDate);

    // Update the selectedStartTime and selectedEndTime with the new date but keep the original time
    const updatedStartTime = updateUTCTime(utcDate, extractTimeFromUTC(selectedStartTime));
    const updatedEndTime = updateUTCTime(utcDate, extractTimeFromUTC(selectedEndTime));

    setSelectedStartTime(updatedStartTime);
    setSelectedEndTime(updatedEndTime);
  };

  return (
    <FormModal
      fixedHeight
      open={props.open}
      handleSubmit={handleSave}
      handleRemove={handleRemove}
      confirmationDialogTitle="Delete training event"
      confirmationDialogMessage={
        props.event.trainingEventType === "recurring"
          ? "You are about to delete a single occurrence of this recurring training event. To delete all instances of this recurring event, please proceed from the 'Players' page."
          : undefined
      }
      onClose={props.onClose}
      submitButtonText="Save"
      submitDisabled={isSubmitDisabled}
      submitButtonHoverText={isSubmitDisabled ? "No changes to save" : undefined}
      tabs={["General", ...props.event.players.map((p) => p.name), "Training structure"]}
      handleTabChange={handleTabChange}
      tabIndex={tabIndex}
      paddingTop={1}
    >
      {/* First tab */}
      <Box data-testid="view-event-modal-first-tab" height="100%">
        <CommonScrollbar height="100%" overflow="auto" isMobile={props.isMobile}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing="2"
            marginBottom={{ mobile: "6", laptop: "3" }}
          >
            <Text fontSize="lg" alignContent="center">
              {props.event.trainingEventType === "recurring" ? "Recurring Training" : "Single Training"}
            </Text>
            {props.event.trainingEventType === "recurring" ? (
              <Icon as={Repeat} alignSelf="end" boxSize="6" color="blackAlpha.800" />
            ) : (
              <Icon as={TimesOneMobiledata} alignSelf="end" boxSize="8" color="blackAlpha.800" />
            )}
          </Stack>
          <Grid templateColumns="repeat(6, 1fr)" templateRows="auto" gap={{ mobile: "6", laptop: "4" }} height="auto">
            <GridItem colSpan={{ mobile: 6, laptop: 2 }}>
              <CommonDayPicker
                disabled={props.event.trainingEventType === "recurring"}
                initialDate={props.event.date || undefined}
                onDateChange={(date) => handleDateChange(date)}
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 3, laptop: 2 }} data-testid="select-start">
              <TimePicker
                disabled={props.event.trainingEventType === "recurring"}
                label="Start"
                selectedTime={extractTimeFromUTC(selectedStartTime)}
                setSelectedTime={(value) => {
                  if (typeof value === "string") {
                    setSelectedStartTime(updateUTCTime(selectedDate, value));
                  }
                }}
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 3, laptop: 2 }} data-testid="select-end">
              <TimePicker
                disabled={props.event.trainingEventType === "recurring"}
                label="End"
                selectedTime={extractTimeFromUTC(selectedEndTime)}
                setSelectedTime={(value) => {
                  if (typeof value === "string") {
                    setSelectedEndTime(updateUTCTime(selectedDate, value));
                  }
                }}
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 6, laptop: 3 }}>
              <CourtSelect
                courts={props.allCourts}
                selectedCourt={selectedCourt}
                setSelectedCourt={(courtId) => {
                  const newCourt = props.allCourts.find((c) => c.id === courtId);
                  if (newCourt) {
                    setSelectedCourt(newCourt);
                  }
                }}
              />
            </GridItem>
            <GridItem colSpan={{ mobile: 6, laptop: 3 }}>
              <CoachSelect
                availableCoaches={props.allCoaches}
                selectedCoaches={selectedCoaches}
                setSelectedCoach={(coachArray) => setSelectedCoaches(coachArray)}
              />
            </GridItem>
            <GridItem colSpan={6}>
              <PlayerInput
                players={props.allPlayers}
                selectedPlayers={selectedPlayers}
                handlePlayerSelection={handleSelectPlayers}
              />
            </GridItem>

            <GridItem colSpan={6}>
              <Text fontSize="lg" marginBottom="2">
                Training notes
              </Text>
              <NotesInput
                notes={groupNote?.content || ""}
                label="Add notes for the entire group"
                setNotes={handleGroupNoteUpdate}
                minRows={4}
              />
            </GridItem>

            <GridItem colSpan={6}>
              <Text fontSize="lg" marginBottom="2">
                Training targets
              </Text>
              <Stack direction="column" spacing="2">
                {props.event.seasonPlans?.length ? (
                  props.event.seasonPlans.map((seasonPlan) => (
                    <SeasonPlanCard key={seasonPlan.id} skillSets={seasonPlan.skillSets} />
                  ))
                ) : (
                  <SeasonPlanCard skillSets={undefined} />
                )}
              </Stack>
            </GridItem>

            <GridItem colSpan={6}>
              <Text fontSize="lg" marginBottom="2">
                Training structure
              </Text>
              {selectedDrills.length > 0 && (
                <Stack direction="column" spacing={{ mobile: "4", laptop: "2" }} height="100%">
                  {selectedDrills.map((drill, index) => (
                    <DrillCard
                      key={index}
                      drill={drill}
                      selected={true}
                      disableHover={true}
                      isMobile={props.isMobile}
                    />
                  ))}
                </Stack>
              )}
              {selectedSession && (
                <SessionCard session={selectedSession} readOnly={true} selected={true} isMobile={props.isMobile} />
              )}
              {selectedDrills.length === 0 && !selectedSession && (
                <Text textColor="blackAlpha.600">
                  This training is still unplanned! You can create a plan from the "Training structure" tab.
                </Text>
              )}
            </GridItem>
          </Grid>
        </CommonScrollbar>
      </Box>
      {/* Player tabs */}
      {...props.event.players.map((player) => (
        <PlayerTab
          key={player.id}
          player={player}
          trainingNote={
            playerNotes.find((a) => a.players?.find((notePlayer) => notePlayer.id === player.id))?.content || ""
          }
          onNoteChange={(newNote) => handlePlayerNoteUpdate(player.id, newNote)}
        />
      ))}
      {/* Training structure tab */}
      <Box display="flex" height="100%" overflow={{ laptop: "hidden" }} id="training-plan-tab">
        <Tabs
          defaultIndex={selectedSession ? 1 : 0}
          variant="solid-rounded"
          colorScheme="orange"
          size="md"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <TabList justifyContent="center" marginBottom="2">
            <Grid templateColumns="repeat(2, 1fr)" columnGap="8">
              <GridItem colSpan={1}>
                <Tab
                  border="1px solid #00000029" // blackAlpha.300
                  transition="all 0.3s ease"
                  _hover={{ backgroundColor: "orange.500", color: "white", border: "1px solid transparent" }}
                >
                  Drills
                </Tab>
              </GridItem>
              <GridItem colSpan={1}>
                <Tab
                  border="1px solid #00000029" // blackAlpha.300
                  transition="all 0.3s ease"
                  _hover={{ backgroundColor: "orange.500", color: "white", border: "1px solid transparent" }}
                >
                  Sessions
                </Tab>
              </GridItem>
            </Grid>
          </TabList>

          <TabPanels overflow={{ laptop: "hidden" }}>
            <TabPanel padding="0" paddingTop="2" height="100%">
              <DrillLibrary
                allSkillSets={props.allSkillSets}
                drills={filteredDrills}
                selectedDrills={selectedDrills}
                onSelectDrill={handleSelectDrill}
                onSearchChange={handleSearchChange}
                searchQuery={searchQuery}
                searchTags={searchTags}
                searchSkillSets={searchSkillSets}
                showDrillCardCheckbox
                scrollbarHeight="100%"
                isMobile={props.isMobile}
              />
            </TabPanel>
            <TabPanel padding="0" paddingTop="2" height="100%">
              <SessionLibrary
                allSkillSets={props.allSkillSets}
                allTags={allSessionTags}
                onSearchChange={handleSearchChange}
                onSelectSession={handleSelectSession}
                searchQuery={searchQuery}
                searchTags={searchTags}
                searchSkillSets={searchSkillSets}
                sessions={filterSessions(props.allSessions, searchQuery, searchTags, searchSkillSets)}
                selectedSession={selectedSession || undefined}
                showSessionCardCheckbox={true}
                isMobile={props.isMobile}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </FormModal>
  );
};
