import React from "react";
import { Box, Stack, Text } from "@chakra-ui/react";
import { SeasonPlanCard } from "../../../../../Calendar/components/CalendarModals/ViewEventModal/SeasonPlanCard";
import { extractDateFromUTC } from "../../../../../Calendar/utils";
import { RootNode } from "./components/TreeNode";
import { SkillSetDisplayCardProps } from "./components/components/SkillSetDisplayCard";
import { CommonScrollbar } from "../../../../../../components/CommonScrollbar/CommonScrollbar";

interface StepReviewProps {
  startDate: string | undefined;
  endDate: string | undefined;
  treeData: RootNode;
  allSkillSets: SkillSetDisplayCardProps["skillSet"][];
}

export const StepReview: React.FC<StepReviewProps> = ({ startDate, endDate, treeData, allSkillSets }) => {
  return (
    <CommonScrollbar maxHeight="100%" overflow="auto">
      <Stack direction="column" spacing="6">
        <Box>
          <Text fontSize="lg" marginBottom="2">
            Planned dates
          </Text>
          <Stack direction="row" spacing="2">
            <Text>{startDate ? extractDateFromUTC(startDate, ".", true) : "Could not display start date"}</Text>
            <Text>-</Text>
            <Text>{endDate ? extractDateFromUTC(endDate, ".", true) : "Could not display end date"}</Text>
          </Stack>
        </Box>

        <Box>
          <Text fontSize="lg" marginBottom="2">
            Targeted groups and players
          </Text>
          {treeData.checked && <Text>Everyone</Text>}
          {!treeData.checked && treeData.children.filter((group) => group.checked).length > 0 && (
            <Text>
              Individual training groups:{" "}
              {treeData.children
                .filter((group) => group.checked)
                .map((group) => group.label)
                .join(", ")}
            </Text>
          )}
          {!treeData.checked &&
            treeData.children.flatMap((group) => group.children.filter((player) => player.checked && !group.checked))
              .length > 0 && (
              <Text>
                Individual players:{" "}
                {[
                  ...new Set(
                    treeData.children.flatMap((group) =>
                      group.children.filter((player) => player.checked && !group.checked).map((player) => player.label),
                    ),
                  ),
                ].join(", ")}
              </Text>
            )}
        </Box>

        <Box>
          <Text fontSize="lg" marginBottom="2">
            Targeted training
          </Text>
          <Stack direction="column" spacing="2">
            {allSkillSets.filter((skillSet) => skillSet.selected).length > 0 ? (
              allSkillSets
                .filter((skillSet) => skillSet.selected)
                .map((skillSet) => (
                  <SeasonPlanCard
                    key={skillSet.id}
                    skillSets={[{ skillSet: skillSet, skills: skillSet.skills.filter((skill) => skill.selected) }]}
                  />
                ))
            ) : (
              <SeasonPlanCard skillSets={undefined} />
            )}
          </Stack>
        </Box>
      </Stack>
    </CommonScrollbar>
  );
};
