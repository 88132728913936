import { extendTheme } from "@chakra-ui/react";
import "./app.css";

const breakpoints = {
  mobile: "0rem",
  laptop: "80rem",
  base: null,
  sm: null,
  md: null,
  lg: null,
  xl: null,
  "2xl": null,
};

export const theme = extendTheme({
  breakpoints,
  styles: {
    global: {
      body: {
        fontFamily: "Lato",
      },
      "p, a, div, button, input, header, textarea": {
        color: "blackAlpha.800",
      },
      _placeholder: {
        color: "inherit !important",
        opacity: "0.6 !important",
      },
      /* svg: {
        color: "red !important",
      }, */
    },
  },
});
