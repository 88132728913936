import React, { useEffect, useState } from "react";
import { SessionLibrary } from "./SessionLibrary";
import { SessionBuilder } from "./SessionBuilder";
import { Drill, Session, type SkillSet } from "../../../../types";
import { filterSessions, handleError } from "../../../../components/utils";
import { GET_ALL_SESSIONS } from "./get-all-sessions.query";
import { useMutation, useQuery } from "@apollo/client";
import { SAVE_SESSION } from "./save-session.mutation";
import { GET_ALL_DRILLS } from "../DrillManager/get-all-drills.query";
import { useAlert } from "../../../../components/AlertProvider";
import { Box, Stack, Text } from "@chakra-ui/react";
import { CommonIconButton } from "../../../../components/CommonIconButton";
import { LoadingContainer, loadingContainerFadeIn } from "../../../../components/LoadingContainer";
import { GET_ALL_SKILL_SETS } from "../../../SkillSets/graphql/get-all-skill-sets.query";

/** Input for SessionDrill entity */
type SessionDrillInput = {
  id?: string;
  drillId: string;
  duration: number;
  order: number;
  remove?: boolean;
};

/** Input for Session entity */
type SessionInput = {
  id?: string;
  name: string;
  goal: string;
  totalDuration: number;
  tags?: string[];
  sessionDrills?: SessionDrillInput[];
  skillSetIds?: string[];
  remove?: boolean;
};

/** SessionManager component */
export const SessionManager: React.FC = () => {
  const { data: sessionData, loading, error: sessionDataError } = useQuery(GET_ALL_SESSIONS);
  const { data: drillData, loading: drillLoading, error: drillDataError } = useQuery(GET_ALL_DRILLS);
  const { data: skillSetData, loading: skillSetDataLoading, error: skillSetDataError } = useQuery(GET_ALL_SKILL_SETS);
  const [selectedSession, setSelectedSession] = useState<Session | null>(null);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [allDrills, setAllDrills] = useState<Drill[]>([]);
  const [isSessionBuilderOpen, setIsSessionBuilderOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [searchSkillSets, setSearchSkillSets] = useState<SkillSet[]>([]);
  const [saveSessionMutation, { loading: saveSessionLoading, error: saveSessionError }] = useMutation(SAVE_SESSION, {
    refetchQueries: [{ query: GET_ALL_SESSIONS }],
    awaitRefetchQueries: true,
  });
  const { showAlert, hideAlert } = useAlert();
  const isLoading = loading || drillLoading || skillSetDataLoading;
  const [showContent, setShowContent] = useState(!isLoading);
  const allSessionTags = Array.from(new Set(sessions.flatMap((session) => (session.tags ? session.tags : []))));
  useEffect(() => {
    if (!isLoading) {
      // Timeout needs to match with LoadingContainer animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    }
  }, [isLoading]);

  useEffect(() => {
    if (sessionDataError || drillDataError || saveSessionError || skillSetDataError) {
      const errors = [sessionDataError, drillDataError, saveSessionError, skillSetDataError].filter(Boolean);
      if (errors.length) {
        showAlert(handleError(errors), "error");
      }
    }
    return () => {
      hideAlert();
    };
  }, [sessionDataError, drillDataError, saveSessionError, skillSetDataError]);

  useEffect(() => {
    if (sessionData?.getAllSessions) {
      setSessions(sessionData.getAllSessions);
    }
  }, [sessionData]);

  useEffect(() => {
    if (drillData?.getAllDrills) {
      setAllDrills(drillData.getAllDrills);
    }
  }, [drillData]);

  /**
   * Select a session
   *
   * @param {Session} session - The session to select
   */
  const handleSelectSession = (session: Session) => {
    setSelectedSession(session);
    setIsSessionBuilderOpen(true);
  };

  /**
   * Save a session
   *
   * @param {string} name - The name of the session
   * @param {string} goal - The goal of the session
   * @param {string[]} tags - The tags of the session
   * @param {Drill[]} drills - The drills in the session
   * @param {number[]} durations - The durations of the drills in the session
   * @param {string} id - The id of the session
   */
  const saveSession = async (
    name: string,
    goal: string,
    tags: string[],
    drills: Drill[],
    durations: number[],
    skillSets: SkillSet[],
    id?: string,
    remove?: boolean,
  ) => {
    const totalDuration = durations.reduce((total, duration) => total + duration, 0);
    const sessionToSave: SessionInput = {
      goal: goal,
      name: name,
      totalDuration: totalDuration,
      id: id,
      remove: remove,
      sessionDrills: drills.map((drill, index) => ({
        id: selectedSession?.sessionDrills[index]?.id,
        drillId: drill.id || "",
        duration: durations[index] || 0,
        order: index,
        remove: drill.remove,
      })),
      skillSetIds: skillSets.map((skillSet) => skillSet.id || ""),
      tags: tags,
    };

    if (remove) {
      showAlert("Deleting Session", "info", undefined, true);
    } else if (sessionToSave.id) {
      showAlert("Updating Session", "info", undefined, true);
    } else {
      showAlert("Creating new Session", "info", undefined, true);
    }

    try {
      const response = await saveSessionMutation({
        variables: {
          data: sessionToSave,
        },
        awaitRefetchQueries: true,
      });

      if (response.data.saveSession === null && remove) {
        showAlert("Session deleted!", "success", 5000);
      } else if (sessionToSave.id) {
        showAlert("Session updated!", "success", 5000);
      } else {
        showAlert("Session created!", "success", 5000);
      }

      setSelectedSession(null);
    } catch {
      // catch silently, error handling is done in the useEffect
    }
  };

  /**
   * Change the search query
   *
   * @param {string} newSearchQuery - The new search query
   * @param {string[]} newSearchTags - The new search tags
   * @param {SkillSet[]} newSearchSkillSets - The new search skill sets
   */
  const handleSearchChange = (newSearchQuery: string, newSearchTags: string[], newSearchSkillSets: SkillSet[]) => {
    setSearchQuery(newSearchQuery);
    setSearchTags(newSearchTags);
    setSearchSkillSets(newSearchSkillSets);
  };

  return (
    <Box id="sessions">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box animation={isLoading ? undefined : `${loadingContainerFadeIn} 0.3s`} data-testid="session-manager">
          <Stack direction="row" spacing="4" marginBottom="6" width="100%" justifyContent="center">
            <Text fontSize="x-large">Sessions</Text>
            <CommonIconButton height="36px" onClick={() => setIsSessionBuilderOpen(true)} dataTestId="add-session" />
          </Stack>
          <SessionLibrary
            allSkillSets={skillSetData?.getAllSkillSets || []}
            sessions={filterSessions(sessions, searchQuery, searchTags, searchSkillSets)}
            onSelectSession={handleSelectSession}
            onSearchChange={handleSearchChange}
            searchQuery={searchQuery}
            searchTags={searchTags}
            searchSkillSets={searchSkillSets}
            allTags={allSessionTags}
            displayMetadata={true}
            scrollbarHeight="75svh"
            isLoading={saveSessionLoading}
          />
        </Box>
      )}
      {isSessionBuilderOpen && (
        <SessionBuilder
          onSave={saveSession}
          allSkillSets={skillSetData?.getAllSkillSets || []}
          allDrills={allDrills}
          allSessionNames={sessions.map((session) => session.name)}
          open={isSessionBuilderOpen}
          onClose={() => {
            setSelectedSession(null);
            setIsSessionBuilderOpen(false);
          }}
          selectedSession={selectedSession}
          allTags={allSessionTags}
        />
      )}
    </Box>
  );
};
