import React, { useState } from "react";
import { Drill, type SkillSet } from "../../../../../types";
import { DrillLibrary } from "../../../../../components/DrillLibrary";
import { Box } from "@chakra-ui/react";

/** Props for the SelectDrillsStep component */
type SelectDrillsStepProps = {
  /** Function to set selectedDrills */
  setSelectedDrills: React.Dispatch<React.SetStateAction<Drill[]>>;
  /** Array of all drills */
  allDrills: Drill[];
  /** Array of all skill sets */
  allSkillSets: SkillSet[];
  /** Array of selected drills */
  selectedDrills: Drill[];
};

/** Component for the selection of drills in the session builder */
export const SelectDrillsStep: React.FC<SelectDrillsStepProps> = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [searchSkillSets, setSearchSkillSets] = useState<SkillSet[]>([]);

  /**
   * Function to handle adding/removing a drill to/from selectedDrills
   *
   * @param drill - The drill to add/remove
   */
  const handleSelectDrill = (drill: Drill) => {
    const isSelected = props.selectedDrills.find((selectedDrill) => selectedDrill.id === drill.id);
    if (isSelected && !isSelected.remove) {
      props.setSelectedDrills(
        props.selectedDrills.map((selectedDrill) =>
          selectedDrill.id === drill.id ? { ...selectedDrill, remove: true } : selectedDrill,
        ),
      );
    } else if (isSelected && isSelected.remove) {
      props.setSelectedDrills(
        props.selectedDrills.map((selectedDrill) =>
          selectedDrill.id === drill.id ? { ...selectedDrill, remove: false } : selectedDrill,
        ),
      );
    } else {
      props.setSelectedDrills([...props.selectedDrills, drill]);
    }
  };

  /**
   * Change the search query and search tags
   *
   * @param newSearchQuery - The new search query
   * @param newSearchTags - The new search tags as an array
   */
  const handleSearchChange = (newSearchQuery: string, newSearchTags: string[], newSearchSkillSets: SkillSet[]) => {
    setSearchQuery(newSearchQuery);
    setSearchTags(newSearchTags);
    setSearchSkillSets(newSearchSkillSets);
  };

  // Filter the drills based on the input(s)
  const filteredDrills = props.allDrills.filter((drill) => {
    // Check if the drill matches the search query
    const matchesSearchQuery =
      searchQuery === "" ||
      drill.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      drill.goal.toLowerCase().includes(searchQuery.toLowerCase()) ||
      drill.implementation.toLowerCase().includes(searchQuery.toLowerCase());
    // Check if the drill matches the search tags
    const matchesSearchTags = searchTags.every((searchTag) => drill.tags?.includes(searchTag));
    // Check if the drill matches the search skill sets
    const matchesSearchSkillSets = searchSkillSets.every((searchSkillSet) =>
      drill.skillSets?.some((drillSkillSet) => drillSkillSet.id === searchSkillSet.id),
    );

    // Return the drill if it matches all the search criteria
    return matchesSearchQuery && matchesSearchTags && matchesSearchSkillSets;
  });

  return (
    <Box data-testid="session-modal-step-drills" overflow="hidden" height="100%">
      <DrillLibrary
        allSkillSets={props.allSkillSets}
        showDrillCardCheckbox
        disableShadow
        drills={filteredDrills}
        selectedDrills={props.selectedDrills.filter((drill) => !drill.remove)}
        onSearchChange={handleSearchChange}
        onSelectDrill={handleSelectDrill}
        searchQuery={searchQuery}
        searchTags={searchTags}
        searchSkillSets={searchSkillSets}
        scrollbarHeight="calc(100% - 67px - 32px - 6px)"
      />
    </Box>
  );
};
