import React, { useEffect, useMemo, useRef } from "react";
import FullCalendar from "@fullcalendar/react";

import "./fullCalendar.css";
import { Court } from "../../types";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_RECURRING_TRAINING_EVENT_METADATAS } from "./graphql/get-all-recurring-training-event-metadatas.query";
import { GET_ALL_DRILLS } from "../TrainingLibrary/components/DrillManager/get-all-drills.query";
import { GET_ALL_PLAYERS } from "../Players/get-all-players.query";
import { GET_ALL_COACHES } from "../Settings/components/Coaches/get-all-coaches.query";
import { SAVE_SINGLE_TRAINING_EVENT } from "./graphql/save-single-training-event.mutation";
import { UPDATE_RECURRING_TRAINING_EVENT_METADATA } from "./graphql/update-recurring-training-event-metadata.mutation";
import { GET_ALL_SINGLE_TRAINING_EVENTS } from "./graphql/get-all-single-training-events.query";
import { useAlert } from "../../components/AlertProvider";
import { useGlobalContext } from "../../components/GlobalProvider";
import {
  createRecurringTrainingEvents,
  createSingleTrainingEvents,
  filterEvents,
  getNewDate,
  handleDateSelect,
  handleEventClick,
  saveNewSingleTrainingEvent,
  saveTrainingEventUpdate,
} from "./utils";
import { CalendarHeader, CalendarBody, CalendarModals } from "./components";
import { Box, useBreakpoint } from "@chakra-ui/react";
import { LoadingContainer, loadingContainerFadeIn } from "../../components/LoadingContainer";
import { useCalendarState, useCalendarData } from "./hooks";
import type { FullCalendarEvent, TrainingEventUpdateInput } from "./types";
import { GET_ALL_SESSIONS } from "../TrainingLibrary/components/SessionManager/get-all-sessions.query";
import { GET_ALL_SKILL_SETS } from "../SkillSets/graphql/get-all-skill-sets.query";
import { GET_MISSING_RECURRING_TRAININGEVENT_METADATAS } from "../Settings/components/Season/get-missing-recurring-trainingevent-metadatas.query";

// Clicking Training Event on Calendar:
// TODO: Add links to individual Player page within the modal to avoid clutter.

/**
 * The `Calendar` component presents a calendar view for managing training sessions. It provides functionality to filter
 * trainings by coach and to show only those trainings that have free spots. The user can select a date and time slot in
 * the calendar to create a new training session, which opens the `AddEventModal`. Clicking on an existing event in the
 * calendar opens the `ViewEventModal` with the event's details.
 */
export const Calendar: React.FC = () => {
  const { globalSelectedClub } = useGlobalContext();
  const currentBreakPoint = useBreakpoint({ ssr: false });
  const {
    data: recurringTrainingEventMetadata,
    loading: recurringTrainingEventMetadataLoading,
    error: recurringTrainingEventMetadataError,
  } = useQuery(GET_ALL_RECURRING_TRAINING_EVENT_METADATAS);
  const { data: drillData, loading: drillLoading, error: drillError } = useQuery(GET_ALL_DRILLS);
  const { data: sessionData, loading: sessionLoading, error: sessionError } = useQuery(GET_ALL_SESSIONS);
  const { data: playerData, loading: playerLoading, error: playerError } = useQuery(GET_ALL_PLAYERS);
  const { data: coachData, loading: coachLoading, error: coachError } = useQuery(GET_ALL_COACHES);
  const {
    data: singleTrainingEventData,
    loading: singleTrainingEventLoading,
    error: singleTrainingEventError,
  } = useQuery(GET_ALL_SINGLE_TRAINING_EVENTS);
  const { data: skillSetsData, loading: skillSetsLoading, error: skillSetsError } = useQuery(GET_ALL_SKILL_SETS);
  const [saveSingleTrainingEvent] = useMutation(SAVE_SINGLE_TRAINING_EVENT, {
    refetchQueries: [
      { query: GET_ALL_SINGLE_TRAINING_EVENTS },
      { query: GET_ALL_RECURRING_TRAINING_EVENT_METADATAS },
      { query: GET_ALL_PLAYERS },
    ],
    awaitRefetchQueries: true,
  });
  const [updateRecurringTrainingEventMetadata] = useMutation(UPDATE_RECURRING_TRAINING_EVENT_METADATA, {
    refetchQueries: [
      { query: GET_ALL_SINGLE_TRAINING_EVENTS },
      { query: GET_ALL_RECURRING_TRAINING_EVENT_METADATAS },
      { query: GET_ALL_PLAYERS },
      { query: GET_MISSING_RECURRING_TRAININGEVENT_METADATAS },
    ],
    awaitRefetchQueries: true,
  });
  const calendarRefs = useRef<(FullCalendar | null)[]>([]);
  const unitedEventsRef = useRef<FullCalendarEvent[] | null>(null);
  const { showAlert } = useAlert();
  const {
    selectedCoaches,
    showOnlyFreeSpots,
    showOnlyRecurringTrainingEvents,
    showOnlySingleTrainingEvents,
    isAddEventModalOpen,
    isDraggableTodoOpen,
    selectedDateTime,
    selectedStartTime,
    selectedEndTime,
    isViewModalOpen,
    selectedEventInfo,
    allCoaches,
    allCourts,
    allPlayers,
    allDrills,
    allSessions,
    allSingleTrainingEvents,
    eventsToDisplay,
    fullCalendarKey,
    activeView,
    dayViewDate,
    dayViewSelectedCourt,
    isLoading,
    showContent,
    setSelectedCoaches,
    setShowOnlyFreeSpots,
    setShowOnlyRecurringTrainingEvents,
    setShowOnlySingleTrainingEvents,
    setIsAddEventModalOpen,
    setIsDraggableTodoOpen,
    setSelectedDateTime,
    setSelectedStartTime,
    setSelectedEndTime,
    setIsViewModalOpen,
    setSelectedEventInfo,
    setFullCalendarKey,
    setActiveView,
    setDayViewDate,
    setDayViewSelectedCourt,
    setEventsToDisplay,
    setAllCoaches,
    setAllCourts,
    setAllPlayers,
    setAllDrills,
    setAllSessions,
    setAllSingleTrainingEvents,
  } = useCalendarState({
    recurringTrainingEventMetadataLoading,
    drillLoading,
    sessionLoading,
    playerLoading,
    coachLoading,
    singleTrainingEventLoading,
    skillSetsLoading,
  });

  useCalendarData({
    globalSelectedClub,
    drillData,
    sessionData,
    playerData,
    coachData,
    singleTrainingEventData,
    drillError,
    sessionError,
    playerError,
    coachError,
    singleTrainingEventError,
    recurringTrainingEventMetadataError,
    skillSetsError,
    setAllCoaches,
    setAllCourts,
    setAllPlayers,
    setAllDrills,
    setAllSessions,
    setAllSingleTrainingEvents,
    showAlert,
  });

  const recurringTrainingEvents = useMemo(() => {
    // TODO: check if useMemo messes apollo cache
    if (
      recurringTrainingEventMetadata &&
      recurringTrainingEventMetadata.getAllRecurringTrainingEventMetadatas &&
      allPlayers.length > 0
    ) {
      return createRecurringTrainingEvents(recurringTrainingEventMetadata.getAllRecurringTrainingEventMetadatas);
    }
  }, [recurringTrainingEventMetadata, allPlayers]);

  const singleTrainingEvents = useMemo(() => {
    if (allSingleTrainingEvents.length > 0) {
      return createSingleTrainingEvents(allSingleTrainingEvents);
    }
  }, [allSingleTrainingEvents, recurringTrainingEventMetadata]);

  useEffect(() => {
    if (recurringTrainingEvents || singleTrainingEvents) {
      const allEvents = [...(recurringTrainingEvents || []), ...(singleTrainingEvents || [])];
      unitedEventsRef.current = allEvents;
    }
  }, [recurringTrainingEvents, singleTrainingEvents]);

  useEffect(() => {
    const events = filterEvents(
      unitedEventsRef,
      selectedCoaches,
      showOnlyFreeSpots,
      showOnlyRecurringTrainingEvents,
      showOnlySingleTrainingEvents,
    );
    if (events) {
      setEventsToDisplay(events);
    }
  }, [
    recurringTrainingEvents,
    singleTrainingEvents,
    selectedCoaches,
    showOnlyFreeSpots,
    showOnlyRecurringTrainingEvents,
    showOnlySingleTrainingEvents,
  ]);

  /** Handles the closing of the `AddEventModal`. Resets the selected date and time, and closes the modal. */
  const handleAddEventModalClose = () => {
    setSelectedDateTime(undefined);
    setSelectedStartTime(null);
    setIsAddEventModalOpen(false);
    calendarRefs.current.forEach((calendarRef) => {
      calendarRef?.getApi().unselect();
    });
  };

  const handleViewEventModalClose = () => {
    setIsViewModalOpen(false);
  };

  const setEventLoadingState = (trainingEventId: string, loadingState: boolean) => {
    const event = eventsToDisplay.find((event) => event.id === trainingEventId);
    if (event) {
      event.isLoading = loadingState;
      setFullCalendarKey((prevKey) => prevKey + 1);
    }
  };

  const handleSaveNewSingleTrainingEvent = async (singleTrainingEventInput: TrainingEventUpdateInput) => {
    showAlert("Creating new training", "info", undefined, true);
    setFullCalendarKey((prevKey) => prevKey + 1);

    await saveNewSingleTrainingEvent(
      singleTrainingEventInput,
      setIsAddEventModalOpen,
      saveSingleTrainingEvent,
      showAlert,
    );
  };

  const handleUpdateEvent = async (
    trainingEventType: FullCalendarEvent["trainingEventType"],
    updatedData: TrainingEventUpdateInput,
  ) => {
    if (updatedData.remove) {
      showAlert("Deleting training", "info", undefined, true);
    } else {
      showAlert("Updating training", "info", undefined, true);
    }
    setEventLoadingState(updatedData.id || "", true);

    await saveTrainingEventUpdate(
      trainingEventType,
      updatedData,
      setIsViewModalOpen,
      updateRecurringTrainingEventMetadata,
      saveSingleTrainingEvent,
      setIsAddEventModalOpen,
      showAlert,
    );

    // TODO: refetchQueries side effects clears this/updates full calendar, find solution
    setEventLoadingState(updatedData.id || "", false);
  };

  const handleCalendarEventClick = (info: any) => {
    handleEventClick(info, setSelectedEventInfo, setIsViewModalOpen);
  };

  const handleCalendarDateSelect = (args: any, court: Court | null) => {
    setDayViewSelectedCourt(court);
    handleDateSelect(args, setSelectedDateTime, setSelectedStartTime, setSelectedEndTime, setIsAddEventModalOpen);
  };

  return (
    <Box id="calendar">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box animation={isLoading ? undefined : `${loadingContainerFadeIn} 0.3s`}>
          <CalendarHeader
            allCoaches={allCoaches}
            isDraggableTodoOpen={isDraggableTodoOpen}
            selectedCoaches={selectedCoaches}
            setIsDraggableTodoOpen={setIsDraggableTodoOpen}
            setSelectedCoaches={setSelectedCoaches}
            setShowOnlyFreeSpots={setShowOnlyFreeSpots}
            setShowOnlyRecurringTrainingEvents={setShowOnlyRecurringTrainingEvents}
            setShowOnlySingleTrainingEvents={setShowOnlySingleTrainingEvents}
            showOnlyFreeSpots={showOnlyFreeSpots}
            showOnlyRecurringTrainingEvents={showOnlyRecurringTrainingEvents}
            showOnlySingleTrainingEvents={showOnlySingleTrainingEvents}
            activeView={activeView}
            calendarRefs={calendarRefs}
            dayViewDate={dayViewDate}
            getNewDate={getNewDate}
            globalSelectedClub={!!globalSelectedClub}
            setActiveView={setActiveView}
            setDayViewDate={setDayViewDate}
            setFullCalendarKey={setFullCalendarKey}
            isMobile={currentBreakPoint === "mobile"}
          />
          <CalendarBody
            activeView={currentBreakPoint === "mobile" ? "timeGridDay" : activeView}
            dayViewDate={dayViewDate}
            eventsToDisplay={eventsToDisplay}
            allCourts={allCourts}
            globalSelectedClub={globalSelectedClub}
            calendarRefs={calendarRefs}
            handleEventClick={handleCalendarEventClick}
            dateSelectHandler={handleCalendarDateSelect}
            fullCalendarKey={fullCalendarKey}
            showAlert={showAlert}
            isMobile={currentBreakPoint === "mobile"}
          />
          <CalendarModals
            isViewModalOpen={isViewModalOpen}
            saveTrainingEventUpdate={handleUpdateEvent}
            selectedEventInfo={selectedEventInfo}
            isAddEventModalOpen={isAddEventModalOpen}
            saveNewSingleTrainingEvent={handleSaveNewSingleTrainingEvent}
            selectedDateTime={selectedDateTime}
            selectedStartTime={selectedStartTime}
            selectedEndTime={selectedEndTime}
            dayViewSelectedCourt={dayViewSelectedCourt}
            allCoaches={allCoaches}
            allCourts={allCourts}
            allPlayers={allPlayers}
            allDrills={allDrills}
            allSessions={allSessions}
            allSkillSets={skillSetsData?.getAllSkillSets || []}
            handleAddEventModalClose={handleAddEventModalClose}
            handleViewEventModalClose={handleViewEventModalClose}
            isMobile={currentBreakPoint === "mobile"}
          />
        </Box>
      )}
    </Box>
  );
};
