import { gql } from "@apollo/client";

export const SAVE_COURT = gql`
  mutation SaveCourt($data: CourtInput!, $clubId: String!) {
    saveCourt(data: $data, clubId: $clubId) {
      id
      name
    }
  }
`;
