import { gql } from "@apollo/client";

export const SAVE_SKILL_SET = gql`
  mutation SaveSkillSet($data: SkillSetInput!) {
    saveSkillSet(data: $data) {
      id
      name
      skills {
        id
        name
      }
    }
  }
`;
