import React, { useState } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  IconButton,
  Stack,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import { format, isValid, parse } from "date-fns";
import { DayPicker } from "react-day-picker";
import { CalendarIcon, CloseIcon } from "@chakra-ui/icons";
import "react-day-picker/dist/style.css";
import "./CommonDayPicker.css";
import { formatInputValue } from "./utils";

/** Props for the CommonDayPicker component. */
export type CommonDayPickerProps = {
  /** Function to be called when the date changes */
  onDateChange: (date?: Date) => void;
  /** Initial date to be displayed */
  initialDate?: Date;
  /** Whether the input is disabled */
  disabled?: boolean;
  /** Label to be displayed on the input */
  label?: string;
};

/** CommonDayPicker component allowing users to pick a date with a calendar or manually input a date. */
export const CommonDayPicker: React.FC<CommonDayPickerProps> = (props) => {
  const initialValue = props.initialDate ? format(props.initialDate, "dd/MM/yyyy") : undefined;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [month, setMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(props.initialDate);
  const [inputValue, setInputValue] = useState(initialValue || "");
  const [isLocked, setIsLocked] = useState(!!initialValue);

  /**
   * Handle input change event.
   *
   * @param e - The change event.
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const formattedValue = formatInputValue(e.target.value);
    setInputValue(formattedValue);

    const parsedDate = parse(e.target.value, "dd/MM/yyyy", new Date());
    if (isValid(parsedDate) && parsedDate.getFullYear() >= 2000) {
      setSelectedDate(parsedDate);
      setMonth(parsedDate);
      if (formattedValue.length === 10) {
        setIsLocked(true);
      }
      props.onDateChange(parsedDate);
    } else {
      setSelectedDate(undefined);
    }
  };

  /**
   * Handle day picker select event.
   *
   * @param date - The selected date.
   */
  const handleDayPickerSelect = (date?: Date): void => {
    if (date) {
      setIsLocked(true);
      setSelectedDate(date);
      setInputValue(format(date, "dd/MM/yyyy"));
    } else {
      setSelectedDate(undefined);
    }
    onClose();
    props.onDateChange(date);
  };

  /** Handle clear input event. */
  const handleClear = (): void => {
    setInputValue("");
    setMonth(new Date());
    setSelectedDate(undefined);
    setIsLocked(false);
    props.onDateChange(undefined);
  };

  return (
    <Box
      position="relative"
      display="inline-block"
      width="100%"
      height="42px"
      opacity={props.disabled ? 0.6 : 1}
      shadow="base"
      border="1px solid"
      borderRadius="md"
      borderColor="blackAlpha.300"
      transition="all 0.3s ease"
      _hover={{ borderColor: "orange.500", shadow: "sm" }}
    >
      <InputGroup>
        <FormControl id="dayPicker">
          <FormLabel
            zIndex={2}
            top="-5px"
            lineHeight="10px"
            marginLeft="10px"
            position="absolute"
            backgroundColor="white"
            paddingLeft="4px"
            paddingRight="4px"
            width="fit-content"
            fontSize="small"
            color="blackAlpha.600"
            display={inputValue ? "flex" : "none"}
          >
            {props.label ? props.label : "DD/MM/YYYY"}
          </FormLabel>
          <Input
            isDisabled={props.disabled}
            placeholder={props.label ? props.label : "DD/MM/YYYY"}
            border="none"
            value={inputValue}
            readOnly={isLocked}
            onChange={handleInputChange}
          />
        </FormControl>
        <InputRightElement>
          <Stack direction="row">
            <IconButton
              aria-label="clear"
              variant="unstyled"
              isDisabled={props.disabled}
              onClick={handleClear}
              icon={<CloseIcon />}
              display={inputValue ? "flex" : "none"}
              position="absolute"
              right="48px"
              color="blackAlpha.300"
              transition="all 0.3s ease"
              _hover={props.disabled ? {} : { color: "blackAlpha.800" }}
            />
            <IconButton
              aria-label="Open calendar to choose a date"
              variant="unstyled"
              isDisabled={props.disabled}
              onClick={onOpen}
              icon={<CalendarIcon />}
              display="flex"
              backgroundColor="orange.400"
              outline="1px solid"
              outlineOffset="-1px"
              outlineColor="orange.400"
              borderEndRadius="5px"
              borderStartRadius="0px"
              transition="all 0.3s ease"
              _hover={
                props.disabled ? {} : { backgroundColor: "orange.500", outlineColor: "orange.500", shadow: "inner" }
              }
            />
          </Stack>
        </InputRightElement>
      </InputGroup>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width={{ mobile: "100%", laptop: "fit-content" }}>
          <ModalHeader width="100%">
            {selectedDate ? `Selected: ${format(selectedDate, "EEEE dd/MM/yyyy")}` : "Pick a Date"}
            <ModalCloseButton size="lg" display={{ mobile: "flex", laptop: "none" }} />
          </ModalHeader>
          <ModalBody width="100%" padding="0">
            <Box width="100%">
              <DayPicker
                showOutsideDays
                showWeekNumber
                fixedWeeks
                weekStartsOn={1}
                month={month}
                onMonthChange={setMonth}
                mode="single"
                selected={selectedDate}
                onSelect={handleDayPickerSelect}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
