// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rdp {
  --rdp-accent-color: #234e52; /* teal.800 */
}
`, "",{"version":3,"sources":["webpack://./src/components/CommonDayPicker/CommonDayPicker.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B,EAAE,aAAa;AAC5C","sourcesContent":[".rdp {\n  --rdp-accent-color: #234e52; /* teal.800 */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
