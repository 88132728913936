import FullCalendar from "@fullcalendar/react";
import { Coach, SingleTrainingEvent, Player, type RecurringTrainingEventMetadata } from "../../../types";
import { handleError } from "../../../components/utils";

import type { FullCalendarEvent, SingleOrRecurringTrainingEventData, TrainingEventUpdateInput } from "../types";
import type {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
} from "@apollo/client";
import type { EventClickArg } from "fullcalendar";

/**
 * Extracts the date part from a datetime string in the UTC format.
 *
 * @param dateTimeString - The datetime string to extract the date from.
 * @param separator - Optional separator to replace the default '-' in the date format.
 * @param dayoToYear - If true, returns the date in the format 'DD-MM-YYYY' instead of 'YYYY-MM-DD'.
 * @returns The date part of the datetime string in the format 'YYYY-MM-DD' or 'DD-MM-YYYY' based on the dayoToYear
 *   flag.
 * @throws Error if the provided datetime string is not in a valid UTC format.
 */
export const extractDateFromUTC = (dateTimeString: string, separator?: string, dayoToYear?: boolean): string => {
  if (!dateTimeString.endsWith("Z") || dateTimeString.length !== 24) {
    throw new Error(`The provided ISO date string ${dateTimeString} is not in UTC format.`);
  }

  let date = dateTimeString.substring(0, 10); // Extracts YYYY-MM-DD

  if (dayoToYear) {
    // Rearranges the date to DD-MM-YYYY
    const [year, month, day] = date.split("-");
    date = `${day}-${month}-${year}`;
  }

  if (separator) {
    return date.replaceAll("-", separator);
  } else {
    return date;
  }
};

/**
 * Extracts the time part from a datetime string in the UTC format.
 *
 * @param dateTimeString - The datetime string to extract the time from.
 * @returns The time part of the datetime string in the format 'HH:MM'.
 */
export const extractTimeFromUTC = (dateTimeString: string): string => {
  if (!dateTimeString.endsWith("Z") || dateTimeString.length !== 24) {
    throw new Error(`The provided ISO date string ${dateTimeString} is not in UTC format.`);
  }
  return dateTimeString.substring(11, 16);
};

/**
 * Creates events based on the provided metadata.
 *
 * @param singleOrRecurringTrainingEventData - Array of event metadata objects.
 * @returns An array of created events.
 */
export const createEvents = (
  singleOrRecurringTrainingEventData: SingleOrRecurringTrainingEventData[],
): FullCalendarEvent[] => {
  const createdEvents: FullCalendarEvent[] = singleOrRecurringTrainingEventData.map((trainingEventData) => {
    // Extract common fields
    const id = trainingEventData.id;
    const court = trainingEventData.court;
    const coaches = trainingEventData.coaches || [];
    const players = trainingEventData.players || [];
    const drills = trainingEventData.drills || [];
    const session = trainingEventData.session;
    const groupNote = trainingEventData.notes?.find((note) => note.isGroupNote);
    const playerNotes = trainingEventData.notes?.filter((note) => !note.isGroupNote) || [];
    const startDateTime = trainingEventData.startDateTime;
    const endDateTime = trainingEventData.endDateTime;

    if ("recurringTrainingEvent" in trainingEventData) {
      // Recurring training event
      const recurringTrainingEvent: FullCalendarEvent = {
        id: id,
        title: players.map((player) => player.name).join(", "),
        coach: coaches,
        court: court,
        players: players,
        drills: drills,
        session: session,
        groupNote: groupNote,
        playerNotes: playerNotes,
        start: startDateTime,
        end: endDateTime,
        color: "#007BFF",
        trainingEventType: "recurring",
        seasonPlans: trainingEventData.recurringTrainingEvent.seasonPlans,
        isLoading: false,
      };
      return recurringTrainingEvent;
    } else {
      // Single training event
      const singleTrainingEvent: FullCalendarEvent = {
        id: id,
        title: players.map((player) => player.name).join(", "),
        coach: coaches,
        court: court,
        players: players,
        drills: drills,
        session: session,
        groupNote: groupNote,
        playerNotes: playerNotes,
        start: startDateTime,
        end: endDateTime,
        color: "#007BFF",
        trainingEventType: "single",
        isLoading: false,
      };
      return singleTrainingEvent;
    }
  });

  return createdEvents;
};

/**
 * Creates recurring training events based on the provided metadata and player information.
 *
 * @param recurringTrainingEventMetadataList - Array of all event metadata.
 * @returns An array of created events.
 */
export const createRecurringTrainingEvents = (recurringTrainingEventMetadataList: RecurringTrainingEventMetadata[]) => {
  const recurringTrainingEventsToCreate: RecurringTrainingEventMetadata[] = recurringTrainingEventMetadataList.map(
    (recurringTrainingEventMetadata) => {
      const metaData: RecurringTrainingEventMetadata = {
        id: recurringTrainingEventMetadata.id,
        court: recurringTrainingEventMetadata.court,
        coaches: recurringTrainingEventMetadata.coaches || [],
        players: recurringTrainingEventMetadata.players || [],
        drills: recurringTrainingEventMetadata.drills || [],
        session: recurringTrainingEventMetadata.session,
        notes: recurringTrainingEventMetadata.notes || [],
        startDateTime: recurringTrainingEventMetadata.startDateTime,
        endDateTime: recurringTrainingEventMetadata.endDateTime,
        recurringTrainingEvent: recurringTrainingEventMetadata.recurringTrainingEvent,
      };
      return metaData;
    },
  );

  return createEvents(recurringTrainingEventsToCreate);
};

/**
 * Creates single training events for use in the application.
 *
 * @param singleTrainingEvents - An array of database single training event objects.
 * @returns The formatted single training events.
 */
export const createSingleTrainingEvents = (singleTrainingEvents: SingleTrainingEvent[]) => {
  const singleTrainingEventsToCreate: SingleTrainingEvent[] = singleTrainingEvents.map((singleTrainingEvent) => {
    const metaData: SingleTrainingEvent = {
      id: singleTrainingEvent.id,
      court: singleTrainingEvent.court,
      coaches: singleTrainingEvent.coaches || [],
      players: singleTrainingEvent.players || [],
      notes: singleTrainingEvent.notes || [],
      startDateTime: singleTrainingEvent.startDateTime,
      endDateTime: singleTrainingEvent.endDateTime,
      drills: singleTrainingEvent.drills,
      session: singleTrainingEvent.session,
    };
    return metaData;
  });

  return createEvents(singleTrainingEventsToCreate);
};

/**
 * Adjusts the given date based on the view type and direction, and updates calendar references accordingly.
 *
 * @param prevDate - The previous date to be adjusted.
 * @param forward - A boolean indicating whether to move forward or backward.
 * @param activeView - The current active view type ('timeGridDay', 'timeGridWeek', 'dayGridMonth').
 * @param calendarRefs - A reference to an array of FullCalendar instances.
 * @returns The new adjusted date.
 */
export const getNewDate = (
  prevDate: Date,
  forward: boolean,
  activeView: string,
  calendarRefs: React.MutableRefObject<(FullCalendar | null)[]>,
): Date => {
  const newDate = new Date(prevDate);
  const direction = forward ? 1 : -1;

  switch (activeView) {
    case "timeGridDay":
      newDate.setDate(newDate.getDate() + direction);
      const dayOfWeek = newDate.getDay();
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        // Skip weekends
        newDate.setDate(newDate.getDate() + 2 * direction);
      }
      break;

    case "timeGridWeek":
      newDate.setDate(newDate.getDate() + 7 * direction);
      break;

    case "dayGridMonth":
      newDate.setMonth(newDate.getMonth() + direction);
      break;

    default:
      throw new Error(`Unknown view type: ${activeView}`);
  }

  calendarRefs.current.forEach((calendarRef) => {
    calendarRef?.getApi().gotoDate(newDate);
  });

  return newDate;
};

/**
 * Handles the date selection and updates the corresponding state.
 *
 * @param args - The information about the selected date, including start and end times.
 * @param setSelectedDateTime - Function to update the selected date and time.
 * @param setSelectedStartTime - Function to update the selected start time.
 * @param setSelectedEndTime - Function to update the selected end time.
 * @param setIsAddEventModalOpen - Function to open the add event modal.
 */
export const handleDateSelect = (
  args: {
    start: Date;
    end: Date;
  },
  setSelectedDateTime: (dateTime: string) => void,
  setSelectedStartTime: (time: string) => void,
  setSelectedEndTime: (time: string) => void,
  setIsAddEventModalOpen: (isOpen: boolean) => void,
): void => {
  setSelectedDateTime(new Date(args.start).toISOString());
  setSelectedStartTime(extractTimeFromUTC(new Date(args.start).toISOString()));
  setSelectedEndTime(extractTimeFromUTC(new Date(args.end).toISOString()));
  setIsAddEventModalOpen(true);
};

/**
 * Saves a new training event with the given data.
 *
 * @param singleTrainingEventInput - The data for the event to be saved.
 * @param setIsAddEventModalOpen - Function to set the state of the event modal.
 * @param saveSingleTrainingEvent - Function to save a single training event.
 * @param setFullCalendarKey - Function to update the calendar key.
 * @param showAlert - Function to show alerts.
 */
export const saveNewSingleTrainingEvent = async (
  singleTrainingEventInput: TrainingEventUpdateInput,
  setIsAddEventModalOpen: (isOpen: boolean) => void,
  saveSingleTrainingEvent: (
    options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined,
  ) => Promise<FetchResult<any>>,
  showAlert: (message: string, type: "success" | "error", timeout?: number) => void,
) => {
  try {
    setIsAddEventModalOpen(false);
    const response = await saveSingleTrainingEvent({
      variables: { data: singleTrainingEventInput },
      awaitRefetchQueries: true,
    });
    if (response.data) {
      showAlert("Created new training!", "success", 5000);
    }
  } catch (error) {
    showAlert(handleError([error]), "error");
  }
};

/**
 * Saves an updated training event.
 *
 * @param updatedData - The data of the event to be updated.
 * @param setIsViewModalOpen - Function to set the view modal state.
 * @param saveRecurringTrainingEvent - Function to save recurring training events.
 * @param saveSingleTrainingEvent - Function to save single training events.
 * @param setIsAddEventModalOpen - Function to set the add event modal state.
 * @param showAlert - Function to show alerts.
 */
export const saveTrainingEventUpdate = async (
  trainingEventType: FullCalendarEvent["trainingEventType"],
  updatedData: TrainingEventUpdateInput,
  setIsViewModalOpen: (isOpen: boolean) => void,
  saveRecurringTrainingEvent: (
    options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined,
  ) => Promise<FetchResult<any>>,
  saveSingleTrainingEvent: (
    options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>> | undefined,
  ) => Promise<FetchResult<any>>,
  setIsAddEventModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  showAlert: (message: string, type: "success" | "error", duration?: number) => void,
) => {
  setIsViewModalOpen(false);
  setIsAddEventModalOpen(false);

  try {
    if (trainingEventType === "single") {
      // Single training event
      await saveSingleTrainingEvent({ variables: { data: updatedData } });
    } else {
      // Recurring training event
      await saveRecurringTrainingEvent({
        variables: { data: updatedData },
      });
    }
    if (updatedData.remove) {
      showAlert("Training deleted!", "success", 5000);
    } else {
      showAlert("Training updated!", "success", 5000);
    }
  } catch (error) {
    showAlert(handleError([error]), "error");
  }
};

/**
 * Filters events based on selected coaches, availability, and event types.
 *
 * @param unitedEventsRef - A reference to the list of all events.
 * @param selectedCoaches - A list of selected coaches to filter the events by.
 * @param showOnlyFreeSpots - A flag to show only events with free spots.
 * @param showOnlyRecurringTrainingEvents - A flag to show only recurring training events.
 * @param showOnlySingleTrainingEvents - A flag to show only single training events.
 * @returns A filtered list of events based on the provided criteria.
 */
export const filterEvents = (
  unitedEventsRef: React.MutableRefObject<FullCalendarEvent[] | null>,
  selectedCoaches: Coach[],
  showOnlyFreeSpots: boolean,
  showOnlyRecurringTrainingEvents: boolean,
  showOnlySingleTrainingEvents: boolean,
): FullCalendarEvent[] | undefined => {
  return unitedEventsRef?.current?.filter((event) => {
    const coachMatch =
      selectedCoaches.length === 0 ||
      selectedCoaches.some((selectedCoach) => event?.coach.map((coach: Coach) => coach.id).includes(selectedCoach.id));

    const freeSpotMatch = !showOnlyFreeSpots || event.players.length < 4;

    const recurringTrainingEventsMatch = !showOnlyRecurringTrainingEvents || event.trainingEventType === "recurring";

    const singleTrainingEventsMatch = !showOnlySingleTrainingEvents || event.trainingEventType === "single";

    return coachMatch && freeSpotMatch && recurringTrainingEventsMatch && singleTrainingEventsMatch;
  });
};

/**
 * Handles the click event for a calendar event and updates the selected event information and view modal state.
 *
 * @param info - The event information object.
 * @param setSelectedEventInfo - Function to update the selected event information.
 * @param setIsViewModalOpen - Function to set the visibility of the view modal.
 */
export const handleEventClick = (
  info: EventClickArg,
  setSelectedEventInfo: (eventInfo: FullCalendarEvent) => void,
  setIsViewModalOpen: (isOpen: boolean) => void,
): void => {
  if (info.event.extendedProps.isLoading === true) {
    // do nothing as the event is still loading
    return;
  }
  setSelectedEventInfo({
    id: info.event.id,
    coach: info.event.extendedProps.coach,
    court: info.event.extendedProps.court,
    date: info.event.start || undefined,
    start: info.event.start?.toISOString() || "",
    end: info.event.end?.toISOString() || "",
    players: info.event.extendedProps.players,
    groupNote: info.event.extendedProps.groupNote,
    playerNotes: info.event.extendedProps.playerNotes,
    drills: info.event.extendedProps.drills,
    session: info.event.extendedProps.session,
    trainingEventType: info.event.extendedProps.trainingEventType,
    seasonPlans: info.event.extendedProps.seasonPlans,
    isLoading: info.event.extendedProps.isLoading,
    title: "TODO TITLE",
    color: "TODO COLOR",
  });
  setIsViewModalOpen(true);
};
