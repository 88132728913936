import React, { useState, useMemo, useRef, useEffect } from "react";
import {
  Tag,
  TagLabel,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  Stack,
  Input,
  Tooltip,
  Progress,
  InputGroup,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

/** Props for the MultiTagSelectMenu component. */
export type MultiTagSelectMenuProps<T> = {
  /** Array of selected tags */
  selectedTags: T[];
  /** Label for the Input */
  label: string;
  /** Array of options */
  options: T[];
  /** Callback function for tag selection */
  handleTagSelection: (value: T[]) => void;
  /** Callback function for creating a new tag. Boolean allowCreateTag needs to be provided as well. */
  handleCreateTag?: (tag: T) => Promise<void> | void;
  /** Width of the component */
  width?: string;
  /** Disable the active state */
  disableActive?: boolean;
  /** Disable the entire component */
  disabled?: boolean;
  /** Function to get label from option */
  getOptionLabel?: (option: T) => string;
  /** Function to check equality of options */
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  /** Disable shadow */
  disableShadow?: boolean;
  /** Allow creation of new tags. Callback handleCreateTag needs to be provided as well. */
  allowCreateTag?: boolean;
  /** Data-testid attribute of the component */
  dataTestId?: string;
};

/**
 * MultiTagSelectMenu is a component that allows multiple selection of tags from a dropdown menu. The selected tags are
 * displayed in the input field.
 */
export const MultiTagSelectMenu: React.FC<MultiTagSelectMenuProps<any>> = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        setIsPopoverOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverRef]);

  /**
   * Handle the selection of an option. If the option is not already selected, it is added to the selected tags. If it
   * is already selected, remove it from the selected tags.
   *
   * @param selectedOption - The option to select.
   */
  const handleSelect = (selectedOption: any) => {
    if (
      !props.selectedTags.some((tag) =>
        props.isOptionEqualToValue ? props.isOptionEqualToValue(tag, selectedOption) : tag === selectedOption,
      )
    ) {
      props.handleTagSelection([...props.selectedTags, selectedOption]);
    } else {
      const newSelectedTags = props.selectedTags.filter((tag) =>
        props.isOptionEqualToValue ? !props.isOptionEqualToValue(tag, selectedOption) : tag !== selectedOption,
      );
      props.handleTagSelection(newSelectedTags);
    }
    setInputValue("");
  };

  /**
   * Filter options based on the input value.
   *
   * @param options - Array of options to filter.
   * @param query - The query to filter by.
   * @returns Filtered array of options.
   */
  const filterOptions = (options: any[], query: string) => {
    if (!query) return options;
    return options.filter((option) => {
      const label = props.getOptionLabel?.(option) || option;
      return label.toLowerCase().includes(query.toLowerCase());
    });
  };

  const filteredOptions = useMemo(() => filterOptions(props.options, inputValue), [props.options, inputValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsPopoverOpen(true);
    setInputValue(e.target.value);
  };

  const handleInputClick = () => {
    if (!props.disabled && !isPopoverOpen) {
      setIsPopoverOpen(true);
    }
  };

  const handleTagClick = (tag: any) => {
    if (!props.disabled) {
      handleSelect(tag);
    }
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
  };

  const handleOptionClick = (option: any) => {
    if (!props.disabled) {
      handleSelect(option);
    }
  };

  const handleCreateTag = async () => {
    if (props.allowCreateTag && inputValue && props.handleCreateTag) {
      setIsLoading(true);
      await props.handleCreateTag?.(inputValue);
      setIsLoading(false);
      setInputValue("");
    }
  };

  return (
    <Box width={props.width} ref={popoverRef}>
      <InputGroup>
        <FormControl>
          <FormLabel
            top="-5px"
            lineHeight="10px"
            marginLeft="10px"
            position="absolute"
            backgroundColor="white"
            paddingLeft="4px"
            paddingRight="4px"
            width="fit-content"
            fontSize="small"
            color="blackAlpha.600"
            display={inputValue || props.selectedTags.length ? "flex" : "none"}
          >
            {props.label}
          </FormLabel>
          <Box
            border="1px"
            borderRadius="md"
            borderColor={props.selectedTags.length && !props.disableActive ? "orange.500" : "blackAlpha.300"}
            shadow={props.disableShadow ? "none" : "base"}
            display="flex"
            flexWrap="wrap"
            alignContent="center"
            paddingY="1"
            paddingX="2"
            height="42px"
            opacity={props.disabled ? 0.6 : 1}
            transition="all 0.3s ease"
            _hover={
              props.disabled ? undefined : { borderColor: "orange.500", shadow: props.disableShadow ? "none" : "sm" }
            }
            data-testid={props.dataTestId || "multi-tag-select-menu"}
          >
            <Stack direction="row" spacing="1" width="100%">
              <Stack direction="row" spacing="1" maxWidth="90%" height="26px" alignSelf="center">
                {props.selectedTags.map((tag, index) => (
                  <Tag
                    key={index}
                    size="md"
                    borderRadius="full"
                    variant="solid"
                    colorScheme="orange"
                    onClick={() => handleTagClick(tag)}
                    cursor={props.disabled ? "not-allowed" : "pointer"}
                    transition="all 0.3s ease"
                    _hover={{ backgroundColor: "orange.400" }}
                    data-testid={`selected-tag-${index}`}
                  >
                    <Tooltip hasArrow label={props.getOptionLabel?.(tag)} aria-label="Tag" placement="top">
                      <TagLabel>{props.getOptionLabel?.(tag)}</TagLabel>
                    </Tooltip>
                  </Tag>
                ))}
              </Stack>
              <Input
                variant="unstyled"
                placeholder={props.selectedTags.length ? undefined : props.label}
                value={inputValue}
                onChange={handleInputChange}
                onClick={handleInputClick}
                disabled={props.disabled}
                data-testid={props.dataTestId ? props.dataTestId + "-search-input" : "search-input"}
                height="32px"
              />
            </Stack>
          </Box>
        </FormControl>
      </InputGroup>
      <Popover
        autoFocus={false}
        isOpen={isPopoverOpen}
        onClose={handlePopoverClose}
        placement="bottom-start"
        offset={[0, 8]}
      >
        <PopoverTrigger>
          <Box width="100%" />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody data-testid="popover-body" maxHeight="600px" overflowY="auto">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <Box
                  key={index}
                  onClick={() => handleOptionClick(option)}
                  cursor={props.disabled ? "not-allowed" : "pointer"}
                  data-testid={`popover-item-${index}`}
                  display="flex"
                  alignItems="center"
                  padding="1"
                  borderRadius="md"
                  _hover={{ backgroundColor: "gray.100" }}
                >
                  {props.selectedTags.some((selectedOption) =>
                    props.isOptionEqualToValue
                      ? props.isOptionEqualToValue(selectedOption, option)
                      : selectedOption === option,
                  ) && <CheckIcon color="orange.500" marginRight="2" alignSelf="center" />}
                  <Text>{props.getOptionLabel?.(option) || option}</Text>
                </Box>
              ))
            ) : (
              <Text padding="1" color="gray.500">
                No results
              </Text>
            )}
            {props.allowCreateTag &&
              inputValue &&
              !filteredOptions.some(
                (option) => (props.getOptionLabel?.(option) || option).toLowerCase() === inputValue.toLowerCase(),
              ) &&
              (isLoading ? (
                <Box paddingY="2.5">
                  <Progress size="md" isIndeterminate colorScheme="orange" borderRadius="md" />
                </Box>
              ) : (
                <Box
                  onClick={handleCreateTag}
                  cursor={props.disabled ? "not-allowed" : "pointer"}
                  data-testid="create-tag"
                  display="flex"
                  alignItems="center"
                  padding="1"
                  borderRadius="md"
                  _hover={{ backgroundColor: "gray.100" }}
                >
                  <Text>Create new tag "{inputValue}"</Text>
                </Box>
              ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
