import React from "react";
import { Box, Grid, Icon, Stack, Text } from "@chakra-ui/react";
import { Bookmarks, Bookmark } from "@mui/icons-material";
import type { SeasonPlan } from "../../../../../types";

/** Props for the SeasonPlanCard component */
export type SeasonPlanCardProps = {
  /** SkillSet to be displayed */
  skillSets?: SeasonPlan["skillSets"];
};

/** SeasonPlanCard component */
export const SeasonPlanCard: React.FC<SeasonPlanCardProps> = (props) => {
  return (
    <Stack
      paddingY="4"
      paddingX={{ mobile: "2", laptop: "4" }}
      borderWidth="1px"
      borderColor={props.skillSets ? "orange.500" : "blackAlpha.300"}
      borderRadius="md"
      spacing="6"
    >
      <Grid templateColumns="auto 1fr" alignItems="center" gap={4}>
        <Icon
          alignSelf={{ mobile: "start", laptop: "center" }}
          as={Bookmarks}
          boxSize={8}
          color={props.skillSets ? "orange.500" : "blackAlpha.500"}
        />
        <Box>
          <Text fontSize="lg" fontWeight="semibold" color={props.skillSets ? "orange.500" : "blackAlpha.800"}>
            Skill Set
          </Text>
          {props.skillSets ? (
            <Text fontSize="md" color="blackAlpha.800">
              {props.skillSets.map((spss) => spss.skillSet.name).join(", ")}
            </Text>
          ) : (
            <Text fontSize="md" color="blackAlpha.500">
              Not planned
            </Text>
          )}
        </Box>
      </Grid>
      <Grid templateColumns="auto 1fr" alignItems="center" gap={4}>
        <Icon
          alignSelf={{ mobile: "start", laptop: "center" }}
          as={Bookmark}
          boxSize={8}
          color={props.skillSets ? "orange.500" : "blackAlpha.500"}
        />
        <Box>
          <Text fontSize="lg" fontWeight="semibold" color={props.skillSets ? "orange.500" : "blackAlpha.800"}>
            Skills
          </Text>
          {props.skillSets?.flatMap((skillSet) => skillSet.skills).length ? (
            <Text fontSize="md" color="blackAlpha.800">
              {props.skillSets
                ?.flatMap((skillSet) => skillSet.skills || [])
                .map((skill) => skill.name)
                .join(", ")}
            </Text>
          ) : (
            <Text fontSize="md" color="blackAlpha.500">
              Not planned
            </Text>
          )}
        </Box>
      </Grid>
    </Stack>
  );
};
