import React, { useState } from "react";
import { Box, Text, SimpleGrid, Stack } from "@chakra-ui/react";
import type { Evaluation as EvaluationType, Player, SkillSet } from "../../../types";
import { SkillSetCard } from "../../SkillSets/components/SkillSetCard";
import { CommonScrollbar } from "../../../components/CommonScrollbar/CommonScrollbar";
import { CommonButton } from "../../../components/CommonButton";
import { LoadingOverlay } from "../../../components/LoadingOverlay";

type EvaluationProps = {
  player: Player;
  allSkillSets: SkillSet[];
  disabled: boolean;
  onSaveEvaluation: (evaluation: EvaluationType) => void;
  /** Whether the component is loading */
  isLoading?: boolean;
};

export const Evaluation: React.FC<EvaluationProps> = (props) => {
  const [evaluation, setEvaluation] = useState(props.player.evaluation || { skillEvaluations: [] });
  const [isEditingDisabled, setIsEditingDisabled] = useState(true);

  const evaluationValues: { [skillId: string]: number } = {};
  evaluation.skillEvaluations.forEach((skillEvaluation) => {
    evaluationValues[skillEvaluation.skill.id || ""] = skillEvaluation.rating;
  });

  const handleRatingChange = (skillId: string, rating: number) => {
    setEvaluation((prev) => {
      const skillEvaluations = prev?.skillEvaluations || [];
      const existingEvaluationIndex = skillEvaluations.findIndex((se) => se.skill.id === skillId);

      if (existingEvaluationIndex > -1) {
        // Update existing skill evaluation
        const updatedEvaluations = skillEvaluations.map((evaluation, index) =>
          index === existingEvaluationIndex ? { ...evaluation, rating } : evaluation,
        );
        return {
          ...prev!,
          skillEvaluations: updatedEvaluations,
        };
      } else {
        // Add new skill evaluation
        return {
          ...prev!,
          skillEvaluations: [...skillEvaluations, { skill: { id: skillId, name: "" }, rating }],
        };
      }
    });
  };

  const handleSaveEvaluation = () => {
    if (!evaluation) {
      return;
    }
    props.onSaveEvaluation(evaluation);
    setIsEditingDisabled(true);
  };

  const handleSecondaryButtonClick = () => {
    if (isEditingDisabled) {
      setIsEditingDisabled(false);
    } else {
      setEvaluation(props.player.evaluation || { skillEvaluations: [] });
      setIsEditingDisabled(true);
    }
  };

  return (
    <Box
      position="relative"
      padding="6"
      border="1px solid"
      borderRadius="md"
      borderColor="blackAlpha.300"
      boxShadow="base"
      transition="all 0.3s ease"
      _hover={{ boxShadow: "lg", borderColor: "blackAlpha.50" }}
    >
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="60px" />
      <Text fontSize="xl" marginBottom="4">
        Evaluation
      </Text>

      {props.allSkillSets.length === 0 ? (
        <Text textColor="blackAlpha.500">
          No Skill Sets available for player evaluation, you can create them from the "Skill Sets" page
        </Text>
      ) : (
        <CommonScrollbar maxHeight="50svh" overflow="auto">
          <SimpleGrid minChildWidth="80" gap="2">
            {props.allSkillSets.map((skillSet) => (
              <Box key={skillSet.id} maxHeight="inherit">
                <SkillSetCard
                  skillSet={skillSet}
                  evaluationMode={true}
                  isDisabled={isEditingDisabled}
                  isLoading={false}
                  evaluationValues={evaluationValues}
                  onEvaluationChange={(skillId, evaluation) => handleRatingChange(skillId, evaluation)}
                />
              </Box>
            ))}
          </SimpleGrid>
        </CommonScrollbar>
      )}

      <Stack direction="row" spacing="4" marginTop="6" justifyContent="end">
        <CommonButton
          variantType="outlineSecondary"
          onClick={handleSecondaryButtonClick}
          disabled={props.disabled || props.allSkillSets.length === 0}
        >
          {isEditingDisabled ? "Edit" : "Cancel"}
        </CommonButton>
        <CommonButton
          variantType="solidPrimary"
          onClick={handleSaveEvaluation}
          disabled={props.disabled || isEditingDisabled}
        >
          Save
        </CommonButton>
      </Stack>
    </Box>
  );
};
