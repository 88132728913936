import React, { useEffect, useState } from "react";
import { SelectDrillsStep } from "../modals";
import { OrderAndDurationStep } from "../modals";
import { ReviewAndSaveStep } from "../modals";
import { Drill, Session, type SkillSet } from "../../../../../types";
import { FormModal } from "../../../../../components/FormModal";
import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";

/** SessionBuilder properties */
type SessionBuilderProps = {
  /** Callback function for saving the new session */
  onSave: (
    name: string,
    goal: string,
    tags: string[],
    selectedDrills: Drill[],
    durations: number[],
    skillSets: SkillSet[],
    id?: string,
    remove?: boolean,
  ) => void;
  /** Callback function for closing the session builder */
  onClose: () => void;
  /** Array of available drills for building a session */
  allDrills: Drill[];
  /** Array of available skill sets for building a session */
  allSkillSets: SkillSet[];
  /** Whether the session builder is open or not */
  open: boolean;
  /** The currently selected session to edit */
  selectedSession: Session | null;
  /** The current set of all tags */
  allTags: string[];
  /** Existing session names */
  allSessionNames: string[];
};

/** SessionBuilder component */
export const SessionBuilder: React.FC<SessionBuilderProps> = (props) => {
  const [selectedDrills, setSelectedDrills] = useState<Drill[]>(
    props.selectedSession?.sessionDrills.map((sessionDrill) => sessionDrill.drill) || [],
  );
  const [durations, setDurations] = useState<number[]>([]);
  const [name, setName] = useState("");
  const [goal, setGoal] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [skillSets, setSkillSets] = useState(props.selectedSession?.skillSets || []);
  const [submitButtonText, setSubmitButtonText] = useState("Continue");
  const [submitDisabledReasons, setSubmitDisabledReasons] = useState<string[]>([]);
  const id = props.selectedSession ? props.selectedSession.id : undefined;
  const { activeStep, setActiveStep } = useSteps({ index: 0, count: 3 });
  const steps = [
    { title: "Drills", description: "Choose the drills for this session" },
    { title: "Structure", description: "Organize the order and set the durations" },
    { title: "Confirm", description: "Review and save your session details" },
  ];

  useEffect(() => {
    if (props.selectedSession) {
      setSelectedDrills(props.selectedSession.sessionDrills.map((sessionDrill) => sessionDrill.drill));
      setDurations(props.selectedSession.sessionDrills.map((drill) => drill.duration));
      setName(props.selectedSession.name);
      setGoal(props.selectedSession.goal);
      setTags(props.selectedSession.tags || []);
    } else {
      setSelectedDrills([]);
      setDurations([]);
      setName("");
      setGoal("");
      setTags([]);
    }
  }, [props.selectedSession]);

  useEffect(() => {
    if (activeStep < 2) {
      setSubmitButtonText("Continue");
      setSubmitDisabledReasons([]);
    } else {
      setSubmitButtonText("Save session");
      resolveIsSubmitDisabled();
    }
  }, [activeStep, name, goal]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = (remove: boolean) => {
    props.onSave(name, goal, tags, selectedDrills, durations, skillSets, id, remove);
    props.onClose();
  };

  const handleSave = () => {
    handleSubmit(false);
  };

  const handleRemove = () => {
    handleSubmit(true);
  };

  const resolveIsSubmitDisabled = () => {
    if (name === props.selectedSession?.name && goal.trim() !== "") {
      // name is not changed & goal is not empty
      return false;
    } else {
      const reasons = [];
      const nameExists = props.allSessionNames
        .map((sessionName) => sessionName.toLowerCase())
        .includes(name.toLowerCase());
      if (nameExists) {
        reasons.push("Session has a name that doesn't exist already");
      }
      const nameIsEmpty = name.trim() === "";
      if (nameIsEmpty) {
        reasons.push("Session has a name");
      }
      const goalIsEmpty = goal.trim() === "";
      if (goalIsEmpty) {
        reasons.push("Session has a goal");
      }

      setSubmitDisabledReasons(reasons);

      return reasons.length > 0;
    }
  };

  return (
    <FormModal
      fixedHeight
      open={props.open}
      onClose={props.onClose}
      handleSubmit={activeStep < 2 ? handleNext : handleSave}
      submitButtonText={submitButtonText}
      secondaryButtonText={activeStep > 0 ? "Back" : undefined}
      title={props.selectedSession ? "Edit session " + props.selectedSession.name : "Create a new session"}
      submitDisabled={submitDisabledReasons.length > 0}
      submitButtonHoverText={
        submitDisabledReasons.length > 0 ? `Saving is enabled once\n- ${submitDisabledReasons.join("\n- ")}` : undefined
      }
      handleSecondaryClick={activeStep > 0 ? handleBack : undefined}
      handleRemove={handleRemove}
      removeDisabled={!props.selectedSession?.id}
      removeDisabledReason="Session isn't created yet"
      confirmationDialogTitle="Delete Session"
    >
      <Stepper size="md" colorScheme="orange" index={activeStep} marginBottom="6" data-testid="session-modal">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
            </StepIndicator>
            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      {/* stepper height 69px */}
      <Box height="calc(100% - 69px)">
        {activeStep === 0 && (
          <SelectDrillsStep
            allSkillSets={props.allSkillSets}
            allDrills={props.allDrills}
            selectedDrills={selectedDrills.filter((drill) => !drill.remove)}
            setSelectedDrills={setSelectedDrills}
          />
        )}
        {activeStep === 1 && (
          <OrderAndDurationStep
            selectedDrills={selectedDrills.filter((drill) => !drill.remove)}
            setSelectedDrills={setSelectedDrills}
            selectedDurations={durations}
            setSelectedDurations={setDurations}
          />
        )}
        {activeStep === 2 && (
          <ReviewAndSaveStep
            selectedDrills={selectedDrills.filter((drill) => !drill.remove)}
            durations={durations}
            name={name}
            goal={goal}
            tags={tags}
            skillSets={skillSets}
            allTags={props.allTags}
            allSkillSets={props.allSkillSets}
            onNameChange={setName}
            onGoalChange={setGoal}
            onTagsChange={setTags}
            onSkillSetsChange={setSkillSets}
          />
        )}
      </Box>
    </FormModal>
  );
};
