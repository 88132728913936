import React from "react";
import { DrillManager } from "./components/DrillManager";
import { SessionManager } from "./components/SessionManager";
import { Grid, GridItem } from "@chakra-ui/react";

export const TrainingLibrary: React.FC = () => {
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap="12">
      <GridItem colSpan={1}>
        <DrillManager />
      </GridItem>
      <GridItem colSpan={1}>
        <SessionManager />
      </GridItem>
    </Grid>
  );
};
