import { gql } from "@apollo/client";

export const SAVE_NOTE = gql`
  mutation SaveNote($data: NoteInput!) {
    saveNote(data: $data) {
      id
      content
      createdAt
      createdBy {
        id
        username
      }
    }
  }
`;
