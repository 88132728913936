import React, { useEffect, useState } from "react";
import { Coach, Court, type RecurringTrainingEvent } from "../../../types";
import { Box, Stack, Text } from "@chakra-ui/react";
import { CommonButton } from "../../../components/CommonButton";
import { DeleteIcon } from "@chakra-ui/icons";
import { useGlobalContext } from "../../../components/GlobalProvider";
import { GroupAdder } from "./GroupAdder";
import { GroupEditor } from "./GroupEditor";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import { LoadingOverlay } from "../../../components/LoadingOverlay";

/** RecurringTrainingEvents props */
export type RecurringTrainingEventsProps = {
  /** Player name */
  playerName: string;
  /** Coaches to link to Recurring Training Event */
  coaches: Coach[];
  /** Courts to link to Recurring Training Event */
  courts: Court[];
  /** The player's events */
  recurringTrainingEvents: RecurringTrainingEvent[];
  /** All possible recurring training events */
  allTrainingEvents: RecurringTrainingEvent[];
  /** Function to handle event save */
  onSave: (recurringTrainingEventToSave: RecurringTrainingEvent) => void;
  /** Function to handle event remove */
  onRemove: (recurringTrainingEventToRemove: RecurringTrainingEvent) => void;
  /** Flag to indicate whether the component is loading */
  isLoading?: boolean;
  /** Flag to indicate whether season sates are missing */
  isSeasonDatesMissing: boolean;
};

/** Component for displaying and editing a player's recurring training events */
export const RecurringTrainingEvents: React.FC<RecurringTrainingEventsProps> = (props) => {
  const { globalSelectedClub } = useGlobalContext();
  const [isAddingNewGroup, setIsAddingNewGroup] = useState(false);
  const [recurringTrainingEventToEdit, setRecurringTrainingEventToEdit] = useState<RecurringTrainingEvent | null>(null);
  const [recurringTrainingEventToRemove, setRecurringTrainingEventToRemove] = useState<RecurringTrainingEvent | null>(
    null,
  );

  useEffect(() => {
    if (isAddingNewGroup) {
      // scroll to bottom of the page
      window.scrollTo({ top: document.body.scrollHeight, behavior: "auto" });
    }
  }, [isAddingNewGroup]);

  /** Function to handle saving the recurring training event */
  const handleSaveGroup = (event: RecurringTrainingEvent) => {
    props.onSave(event);
    setRecurringTrainingEventToEdit(null);
    setIsAddingNewGroup(false);
  };

  /** Function to handle removing a group from the player's events */
  const handleRemoveGroup = (event: RecurringTrainingEvent) => {
    setRecurringTrainingEventToRemove(null);
    props.onRemove(event);
  };

  /** Function to handle editing an existing group */
  const handleEditGroup = (event: RecurringTrainingEvent) => {
    setRecurringTrainingEventToEdit(event);
  };

  /** Function to handle adding a new group */
  const handleAddNewGroup = () => {
    setRecurringTrainingEventToEdit(null);
    setIsAddingNewGroup(true);
  };

  /** Function to handle canceling editing or adding */
  const handleCancel = () => {
    setRecurringTrainingEventToEdit(null);
    setIsAddingNewGroup(false);
  };

  return (
    <Box
      position="relative"
      padding="6"
      border="1px solid"
      borderRadius="md"
      borderColor="blackAlpha.300"
      boxShadow="base"
      transition="all 0.3s ease"
      _hover={{ boxShadow: "lg", borderColor: "blackAlpha.50" }}
      data-testid="recurring-training-events"
    >
      <LoadingOverlay display={props.isLoading || false} spinnerSize="xl" spinnerTopPosition="60px" />
      <Text fontSize="xl" marginBottom="4">
        Training groups
      </Text>

      {props.isSeasonDatesMissing ? (
        <Text textColor="blackAlpha.500">
          No season dates available for training groups, you can set them from the "Settings" page
        </Text>
      ) : (
        <Stack direction="column" spacing="8">
          {props.recurringTrainingEvents.length > 0 ? (
            props.recurringTrainingEvents.map((event, index) => (
              <Box key={JSON.stringify(event)} data-testid={`recurring-training-event-${index}`}>
                {recurringTrainingEventToEdit && recurringTrainingEventToEdit.id === event.id ? (
                  <GroupEditor
                    allCoaches={props.coaches}
                    allCourts={props.courts}
                    event={recurringTrainingEventToEdit}
                    onSave={handleSaveGroup}
                    onCancel={handleCancel}
                    timeAndPlaceDisabled={true}
                  />
                ) : (
                  <Stack direction="row" spacing="4">
                    <GroupEditor
                      allCoaches={props.coaches}
                      allCourts={props.courts}
                      event={event}
                      onSave={handleSaveGroup}
                      onCancel={handleCancel}
                      readonly={true}
                    />
                    <Stack direction="row" spacing="4" minWidth="160px">
                      <CommonButton
                        fullWidth
                        variantType="outlineSecondary"
                        onClick={() => handleEditGroup(event)}
                        dataTestId={`edit-recurring-training-event-${index}`}
                      >
                        Edit
                      </CommonButton>
                      <CommonButton
                        fullWidth
                        variantType="outlineSecondary"
                        aria-label="remove"
                        hoverColor="red.600"
                        onClick={() => setRecurringTrainingEventToRemove(event)}
                        dataTestId={`remove-recurring-training-event-${index}`}
                      >
                        <DeleteIcon color="red.600" />
                      </CommonButton>
                    </Stack>
                  </Stack>
                )}
              </Box>
            ))
          ) : (
            <Text textColor="blackAlpha.500">{`${props.playerName} doesn't belong to any training group`}</Text>
          )}

          {isAddingNewGroup ? (
            <GroupAdder
              allTrainingEvents={props.allTrainingEvents}
              allCoaches={props.coaches}
              allCourts={props.courts}
              onSave={handleSaveGroup}
              onCancel={handleCancel}
              loading={props.isLoading}
            />
          ) : (
            <Box width="fit-content" alignSelf="end">
              <CommonButton
                variantType="solidPrimary"
                onClick={handleAddNewGroup}
                disabled={!globalSelectedClub || globalSelectedClub?.courts.length === 0}
                tooltip={
                  !globalSelectedClub || globalSelectedClub?.courts.length === 0
                    ? "Court must be created first for selected club"
                    : undefined
                }
                dataTestId="add-recurring-training-event"
              >
                {`Add ${props.playerName} to new group`}
              </CommonButton>
            </Box>
          )}
        </Stack>
      )}

      <ConfirmationDialog
        isOpen={!!recurringTrainingEventToRemove}
        message={`Please confirm that you want to remove "${props.playerName}" from the training group "${recurringTrainingEventToRemove?.name}". This cannot be undone.`}
        onCancel={() => setRecurringTrainingEventToRemove(null)}
        onClose={() => setRecurringTrainingEventToRemove(null)}
        onConfirm={() =>
          recurringTrainingEventToRemove ? handleRemoveGroup(recurringTrainingEventToRemove) : undefined
        }
        title="Remove training group"
        cancelButtonText="Cancel"
        confirmButtonText="Remove"
      />
    </Box>
  );
};
