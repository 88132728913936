import React from "react";
import { Drill } from "../../types";
import { Box, Checkbox, Grid, Stack, Tag, TagLabel, Text, Tooltip } from "@chakra-ui/react";
import { extractDateFromUTC } from "../../features/Calendar/utils";
import { extractFormattedTime } from "../utils";

/** Props for the DrillCard component */
export type DrillCardProps = {
  /** The drill to display */
  drill: Drill;
  /** Whether the card is selected */
  selected: boolean;
  /** Callback function for selecting a drill */
  onSelectDrill?: (drill: Drill) => void;
  /** Whether to show the checkbox */
  showCheckbox?: boolean;
  /** Whether to disable the shadow */
  disableShadow?: boolean;
  /** Whether to disable hover */
  disableHover?: boolean;
  /** Override Drill name with custom title */
  title?: React.ReactNode;
  /** The data-testid attribute to be added to the component */
  dataTestId?: string;
  /** Whether to display created by & created at */
  displayMetadata?: boolean;
  /** Whether the user is using a mobile device */
  isMobile?: boolean;
};

/** DrillCard component */
export const DrillCard: React.FC<DrillCardProps> = (props) => {
  /** Handle the drill selection event. */
  const handleDrillSelection = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    if (props.onSelectDrill) {
      props.onSelectDrill(props.drill);
    }
  };

  return (
    <Box
      paddingY="4"
      paddingX={{ mobile: "2", laptop: "4" }}
      border="1px solid"
      borderColor={props.selected ? "orange.500" : "blackAlpha.300"}
      borderRadius="md"
      shadow={props.disableShadow ? "none" : "base"}
      transition="all 0.3s ease"
      _hover={
        props.disableHover
          ? undefined
          : {
              borderColor: "orange.500",
              shadow: props.disableShadow ? "none" : "sm",
              cursor: "pointer",
            }
      }
      onClick={handleDrillSelection}
      data-testid={props.dataTestId}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="column" width="100%" spacing="6">
          {props.title ? (
            props.title
          ) : (
            <Stack direction="row" justifyContent="space-between">
              <Text fontSize="large" color="blackAlpha.800">
                {props.drill.name}
              </Text>
              {!props.showCheckbox && (
                <Stack direction="row" spacing={1}>
                  {props.drill.tags?.map((tag, index) => (
                    <Tag
                      key={index}
                      height={{ mobile: "fit-content", laptop: undefined }}
                      size="md"
                      borderRadius="full"
                      variant="solid"
                      colorScheme="orange"
                    >
                      <Tooltip hasArrow label={tag} aria-label="Tag" placement="top">
                        <TagLabel>{tag}</TagLabel>
                      </Tooltip>
                    </Tag>
                  ))}
                </Stack>
              )}
              {props.showCheckbox && props.isMobile && (
                <Checkbox isChecked={props.selected} colorScheme="orange" iconColor="white" />
              )}
            </Stack>
          )}
          {props.isMobile ? (
            <Stack direction="column" width="100%" spacing="3">
              <Text fontSize="medium" color="blackAlpha.800">
                {props.drill.goal}
              </Text>
              <Text fontSize="medium" color="blackAlpha.800">
                {props.drill.implementation}
              </Text>
              <Stack direction="row" spacing="1">
                {props.drill.skillSets?.map((skillSet) => (
                  <Tag
                    key={skillSet.id}
                    height="fit-content"
                    size="md"
                    borderRadius="full"
                    variant="solid"
                    colorScheme="orange"
                  >
                    <TagLabel>{skillSet.name}</TagLabel>
                  </Tag>
                ))}
              </Stack>
            </Stack>
          ) : (
            <Box as="dl">
              <Grid templateColumns="min-content 1fr" columnGap="4" rowGap="4">
                <Text as="dt" color="blackAlpha.600">
                  Goal
                </Text>
                <Text as="dd" fontSize="medium" color="blackAlpha.800">
                  {props.drill.goal}
                </Text>
                <Text as="dt" color="blackAlpha.600">
                  Implementation
                </Text>
                <Text as="dd" fontSize="medium" color="blackAlpha.800">
                  {props.drill.implementation}
                </Text>
                <Text as="dt" color="blackAlpha.600">
                  Skill Sets
                </Text>
                <Stack as="dd" direction="row" spacing="1">
                  {props.drill.skillSets?.map((skillSet) => (
                    <Tag key={skillSet.id} size="md" borderRadius="full" variant="solid" colorScheme="orange">
                      <Tooltip hasArrow label={skillSet.name} aria-label="Tag" placement="top">
                        <TagLabel>{skillSet.name}</TagLabel>
                      </Tooltip>
                    </Tag>
                  )) || "-"}
                </Stack>
              </Grid>
            </Box>
          )}
          {!props.isMobile && props.displayMetadata && (
            <Stack direction="row" justifyContent="space-between">
              {props.drill.createdBy?.username ? (
                <Text fontSize="xs" color="blackAlpha.600">
                  {props.drill.createdBy.username}
                </Text>
              ) : (
                <Box flex={1} />
              )}
              {props.drill?.createdAt && (
                <Stack direction="row" spacing="4">
                  <Text fontSize="xs" color="blackAlpha.600">
                    {props.drill?.updatedAt
                      ? extractDateFromUTC(props.drill.updatedAt)
                      : extractDateFromUTC(props.drill.createdAt)}
                  </Text>
                  <Text fontSize="xs" color="blackAlpha.600">
                    {props.drill?.updatedAt
                      ? extractFormattedTime(props.drill.updatedAt)
                      : extractFormattedTime(props.drill.createdAt)}
                  </Text>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
        {props.showCheckbox && !props.isMobile && (
          <Checkbox isChecked={props.selected} colorScheme="orange" iconColor="white" />
        )}
      </Stack>
    </Box>
  );
};
