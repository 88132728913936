import {
  Box,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { EditableControls } from "./EditableControls";
import { CommonIconButton } from "../../../components/CommonIconButton";
import { CommonScrollbar } from "../../../components/CommonScrollbar/CommonScrollbar";
import type { SkillSet } from "../../../types";
import { LoadingOverlay } from "../../../components/LoadingOverlay";

/** Props for the SkillSetCard component. */
export type SkillSetCardProps = {
  /** Determines whether the SkillSet is loading. */
  isLoading: boolean;
  /** Whether the SkillSet is active or not */
  isActive?: boolean;
  /** The SkillSet to be displayed */
  skillSet: SkillSet;
  /** Function to be called when a SkillSet should be saved */
  onSave?: (updatedSkillSet: SkillSet) => void;
  /** Function to be called when a new skill is added */
  onAdd?: () => void;
  /** Function to be called when a SkillSet is reset */
  resetSkillSet?: (skillSetId: string | null) => void;
  evaluationMode?: boolean;
  evaluationValues?: { [skillId: string]: number };
  onEvaluationChange?: (skillId: string, evaluation: number) => void;
  isDisabled?: boolean;
};

/** SkillSetCard component displays a SkillSet card. Each skill can be edited, added, or reset. */
export const SkillSetCard: React.FC<SkillSetCardProps> = (props) => {
  const [isAddSkillDisabled, setIsAddSkillDisabled] = useState(props.isActive);
  const [activeValueIsEmpty, setActiveValueIsEmpty] = useState(!props.skillSet.name);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  /**
   * Handle the addition of a new skill. This function will disable the submit button, call the onAdd prop function, and
   * set the active skill to the last skill in the skill set. It will also set the active value to empty.
   */
  const handleAddSkill = () => {
    setIsAddSkillDisabled(true);
    setDisableSubmit(true);
    props.onAdd?.();
    setActiveValueIsEmpty(true);
  };

  /**
   * Handle the submission of a skill or skill set edit. This function will enable the submit button, set the active
   * value to non-empty, and call the onEdit prop function with the appropriate parameters. If the new value is empty
   * and the id is not provided, it will reset the skill set.
   *
   * @param type - The type of the item being edited.
   * @param id - The id of the item being edited.
   * @param newValue - The new value of the item being edited.
   */
  const handleSubmit = (updatedSkillSet: SkillSetCardProps["skillSet"], remove?: boolean) => {
    setDisableSubmit(false);
    setActiveValueIsEmpty(false);
    setIsAddSkillDisabled(false);

    const { __typename, ...skillSet } = updatedSkillSet;
    if (remove) {
      props.onSave?.({ ...skillSet, remove: true });
    } else {
      props.onSave?.(skillSet);
    }
  };

  const handleValueChange = (type: "skillSet" | "skill", id: string | null, newValue: string) => {
    const updatedSkillSet =
      type === "skillSet"
        ? { ...props.skillSet, name: newValue }
        : {
            ...props.skillSet,
            skills: id
              ? props.skillSet.skills.map((skill) => (skill.id === id ? { ...skill, name: newValue } : skill))
              : props.skillSet.skills.some((skill) => skill.name === "")
                ? props.skillSet.skills.map((skill) => (skill.name === "" ? { ...skill, name: newValue } : skill))
                : [...props.skillSet.skills, { name: newValue }],
          };

    handleSubmit(updatedSkillSet);
  };

  /**
   * Handle the cancellation of a skill or skill set edit. This function will enable the submit button, set the active
   * value to non-empty, and if the previous value is empty, it will reset the skill set.
   *
   * @param previousValue - The previous value of the item being edited.
   */
  const handleCancel = (previousValue: string) => {
    setIsAddSkillDisabled(false);
    setDisableSubmit(false);
    setActiveValueIsEmpty(false);
    if (!previousValue) {
      // remove the empty skill by resetting the skill set
      props.resetSkillSet?.(props.skillSet.id || null);
    }
  };

  const handleSkillRemove = (skillId: string) => {
    const updatedSkillSet = {
      ...props.skillSet,
      skills: props.skillSet.skills.map((skill) => (skill.id === skillId ? { ...skill, remove: true } : skill)),
    };

    handleSubmit(updatedSkillSet);
  };

  return (
    <Box
      ref={containerRef}
      width="80"
      height={props.evaluationMode ? "inherit" : "50svh"}
      maxHeight={props.evaluationMode ? "inherit" : "50svh"}
      pointerEvents={props.isDisabled ? "none" : "auto"}
      opacity={props.isDisabled ? 0.9 : 1}
      overflow="hidden"
      padding="4"
      border="1px solid"
      borderColor="blackAlpha.300"
      borderRadius="md"
      boxShadow="base"
      transition="all 0.3s ease"
      backgroundColor="white"
      _hover={{ boxShadow: "lg", borderColor: "blackAlpha.50", backgroundColor: "blackAlpha.50" }}
      _focusWithin={{ boxShadow: "lg", borderColor: "blackAlpha.50", backgroundColor: "blackAlpha.50" }}
      position="relative"
      display="flex"
      flexDirection="column"
    >
      <LoadingOverlay display={props.isLoading} spinnerSize="xl" spinnerTopPosition="60px" />

      {/* Name of the SkillSet */}
      {props.evaluationMode ? (
        <Text
          fontSize="large"
          paddingY="3"
          paddingX="6"
          minHeight="51px"
          marginBottom="4"
          borderRadius="md"
          backgroundColor="teal.800"
          textColor="whiteAlpha.900"
        >
          {props.skillSet.name}
        </Text>
      ) : (
        <Editable
          isDisabled={props.isLoading}
          defaultValue={props.skillSet.name}
          selectAllOnFocus={false}
          submitOnBlur={false}
          startWithEditView={props.isActive}
          onSubmit={(value) => handleValueChange("skillSet", props.skillSet.id || null, value)}
          onCancel={(value) => handleCancel(value)}
          onChange={(value) => {
            setActiveValueIsEmpty(!value);
            setDisableSubmit(false);
          }}
          onFocus={() => {
            setIsAddSkillDisabled(true);
          }}
          placeholder="Skill category"
          minHeight="51px"
          marginBottom="4"
          borderRadius="md"
          display="flex"
          backgroundColor="teal.800"
          transition="box-shadow 0.3s ease"
          textColor="whiteAlpha.900"
          _hover={{
            boxShadow: "2xl",
            textColor: "orange.400",
          }}
          _focusWithin={{
            boxShadow: "2xl",
            textColor: "orange.400",
          }}
        >
          <EditablePreview width="100%" paddingY="3" paddingX="6" fontSize="large" fontWeight="semibold">
            {props.skillSet.name}
          </EditablePreview>
          <EditableInput
            width="100%"
            paddingY="3"
            paddingX="6"
            fontSize="large"
            fontWeight="semibold"
            textColor="orange.400"
            boxShadow="none !important"
          />
          <EditableControls
            onRemove={() => handleSubmit(props.skillSet, true)}
            displayRemove={activeValueIsEmpty}
            confirmationTitle={`Delete Skill Set "${props.skillSet.name}"`}
            disableSubmit={disableSubmit || (props.isActive && activeValueIsEmpty)}
            confirmationMessage={`Please confirm the action. Deleting Skill Set "${props.skillSet.name}" will also delete all associated skills and their evaluations. This cannot be undone.`}
          />
        </Editable>
      )}

      <Box flex="1" display="flex" flexDirection="column" overflow="hidden">
        <CommonScrollbar overflow="auto" height="100%" scrollbarBorderColor="#FFFFFF0A">
          {props.skillSet.skills && props.skillSet.skills.length > 0 ? (
            <Stack direction="column" spacing="4" marginBottom="4">
              {props.skillSet.skills.map((skill, index) =>
                props.evaluationMode ? (
                  <Stack
                    key={skill.id || index}
                    direction="row"
                    spacing="4"
                    alignItems="center"
                    paddingY="2"
                    paddingLeft="4"
                    paddingRight="6"
                    backgroundColor="blackAlpha.50"
                    borderRadius="md"
                    boxShadow="base"
                    textColor="blackAlpha.900"
                    transition="all 0.3s ease"
                    _hover={{
                      boxShadow: "lg",
                      textColor: "orange.400",
                      backgroundColor: "white",
                    }}
                    _focusWithin={{
                      boxShadow: "lg",
                      textColor: "orange.400",
                      backgroundColor: "white",
                    }}
                  >
                    <Text flex="1" textColor="inherit">
                      {skill.name}
                    </Text>

                    <Slider
                      isDisabled={props.isDisabled}
                      flex={1}
                      focusThumbOnChange={false}
                      min={1}
                      max={10}
                      colorScheme="orange"
                      textColor="inherit"
                      value={props.evaluationValues?.[skill.id || ""] || 0}
                      onChange={(valueNumber) =>
                        props.onEvaluationChange && props.onEvaluationChange(skill.id!, valueNumber)
                      }
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb
                        fontSize="md"
                        boxSize="32px"
                        children={props.evaluationValues?.[skill.id || ""] || "-"}
                        transition="background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease"
                        _hover={{ backgroundColor: "orange.500", color: "white" }}
                        _focusWithin={{ backgroundColor: "orange.500", color: "white", boxShadow: "none" }}
                        _active={{ backgroundColor: "orange.500", color: "white" }}
                        _disabled={{
                          backgroundColor: props.evaluationValues?.[skill.id || ""] ? "orange.500" : undefined,
                          color: "white",
                        }}
                      />
                    </Slider>
                  </Stack>
                ) : (
                  <Editable
                    key={skill.id || index}
                    isDisabled={props.isLoading}
                    defaultValue={skill.name}
                    selectAllOnFocus={false}
                    submitOnBlur={false}
                    startWithEditView={props.isActive && props.skillSet.skills.length === index + 1}
                    onSubmit={(value) => handleValueChange("skill", skill.id || null, value)}
                    onCancel={(value) => handleCancel(value)}
                    onChange={(value) => {
                      setActiveValueIsEmpty(!value);
                      if (value) {
                        setDisableSubmit(false);
                      } else if (!skill.name) {
                        setDisableSubmit(true);
                      }
                    }}
                    onFocus={() => {
                      setIsAddSkillDisabled(true);
                    }}
                    placeholder="Skill"
                    minHeight="48px"
                    backgroundColor="blackAlpha.50"
                    borderRadius="md"
                    boxShadow="base"
                    textColor="blackAlpha.900"
                    display="flex"
                    transition="box-shadow 0.3s ease, background-color 0.3s ease"
                    _hover={{
                      boxShadow: "lg",
                      textColor: "orange.400",
                      backgroundColor: "white",
                    }}
                    _focusWithin={{
                      boxShadow: "lg",
                      textColor: "orange.400",
                      backgroundColor: "white",
                    }}
                  >
                    <EditablePreview width="100%" paddingY="3" paddingX="6" fontSize="medium" fontWeight="medium">
                      {skill.name}
                    </EditablePreview>
                    <EditableInput
                      width="100%"
                      paddingY="3"
                      paddingX="6"
                      fontSize="medium"
                      fontWeight="medium"
                      boxShadow="none !important"
                      textColor="orange.400"
                    />
                    <EditableControls
                      onRemove={() => handleSkillRemove(skill.id || "")}
                      displayRemove={activeValueIsEmpty}
                      confirmationTitle={`Delete Skill "${skill.name}"`}
                      disableSubmit={disableSubmit}
                      confirmationMessage={`Please confirm the action. Deleting Skill "${skill.name}" from the Skill Set "${props.skillSet.name}" will also delete all associated evaluations. This action cannot be undone.`}
                    />
                  </Editable>
                ),
              )}
            </Stack>
          ) : props.skillSet.id ? (
            <Text padding="2" fontWeight="medium">
              No skills have been assigned to this skill category
            </Text>
          ) : (
            <Text padding="2" fontWeight="medium">
              Define and save a skill category first to create individual skills
            </Text>
          )}
        </CommonScrollbar>
      </Box>

      {!props.evaluationMode && (
        <>
          <Divider marginY="6" borderColor="blackAlpha.300" />
          <CommonIconButton
            fullWidth
            height="32px"
            onClick={handleAddSkill}
            disabled={isAddSkillDisabled || props.isLoading || !props.skillSet.id}
          />
        </>
      )}
    </Box>
  );
};
