import { IconButton, Text } from "@chakra-ui/react";
import React from "react";

type CalendarWeekViewButtonProps = {
  activeView: string;
  setActiveView: (view: string) => void;
  setFullCalendarKey: (keySetter: (prevKey: number) => number) => void;
};

export const CalendarWeekViewButton: React.FC<CalendarWeekViewButtonProps> = (props) => {
  return (
    <IconButton
      aria-label="week-view"
      variant="outline"
      onClick={() => {
        props.setActiveView("timeGridWeek");
        props.setFullCalendarKey((prevKey) => prevKey + Math.random());
      }}
      icon={
        <Text fontSize="lg" paddingY="2">
          Week
        </Text>
      }
      minWidth="24"
      shadow="md"
      transition="border-color 0.2s ease, box-shadow 0.2s ease"
      _hover={{ shadow: "base", borderColor: "orange.400" }}
    />
  );
};
