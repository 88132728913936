import { Textarea } from "@chakra-ui/react";
import React from "react";

type NotesInputProps = {
  /** Callback function for updating the notes value */
  setNotes: (value: string) => void;
  /** Current notes value */
  notes: string;
  /** Label of the component */
  label: string;
  /** When set to true, the component is read-only */
  disabled?: boolean;
  /** Minimum number of rows to display */
  minRows?: number;
};

/** NotesInput component allows the user to input notes for an event */
export const NotesInput: React.FC<NotesInputProps> = (props) => {
  return (
    <Textarea
      placeholder={props.label}
      value={props.notes}
      onChange={(e) => props.setNotes(e.target.value)}
      isReadOnly={props.disabled}
      borderColor="blackAlpha.300"
      transition="border-color 0.2s ease"
      color={props.disabled ? "blackAlpha.600" : "black"}
      _hover={props.disabled ? undefined : { borderColor: "orange.500" }}
    />
  );
};
