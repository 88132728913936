import React, { useState, useEffect, useMemo } from "react";
import { RecurringTrainingEvents } from "./subcomponents/RecurringTrainingEvents";
import { Player, type Evaluation as EvaluationType, type NoteInput, type RecurringTrainingEvent } from "../../types";
import { useMutation, useQuery } from "@apollo/client";
import { SAVE_PLAYER } from "./save-player.mutation";
import { GET_ALL_PLAYERS } from "./get-all-players.query";
import { GET_ALL_RECURRING_TRAINING_EVENT_METADATAS_FOR_PLAYERS } from "./graphql/get-all-recurring-training-event-metadatas-for-players.query";
import { useAlert } from "../../components/AlertProvider";
import { handleError } from "../../components/utils";
import { Box, Stack, Text, keyframes } from "@chakra-ui/react";
import { Autocomplete } from "../../components/AutoComplete";
import { FormModal } from "../../components/FormModal";
import { CommonButton } from "../../components/CommonButton";
import { LoadingContainer, loadingContainerFadeIn } from "../../components/LoadingContainer";
import { CommonInput } from "../../components/CommonInput";
import { GET_ALL_COACHES } from "../Settings/components/Coaches/get-all-coaches.query";
import { useGlobalContext } from "../../components/GlobalProvider";
import { SAVE_RECURRING_TRAINING_EVENT } from "./graphql/save-recurring-training-event.mutation";
import { GET_ALL_RECURRING_TRAINING_EVENTS } from "./subcomponents/get-all-recurring-training-events.query";
import { GET_USER_ORGANIZATION } from "../Settings/components/Season/get-user-organization.query";
import { GET_MISSING_RECURRING_TRAININGEVENT_METADATAS } from "../Settings/components/Season/get-missing-recurring-trainingevent-metadatas.query";
import { Notes } from "./subcomponents/Notes";
import { Logs } from "./subcomponents/Logs";
import { SAVE_NOTE } from "./graphql/save-note.mutation";
import { Evaluation } from "./subcomponents/Evaluation";
import { GET_ALL_SKILL_SETS } from "../SkillSets/graphql/get-all-skill-sets.query";
import { SAVE_EVALUATION } from "./graphql/save-evaluation.mutation";
import { LoadingOverlay } from "../../components/LoadingOverlay";

type RecurringTrainingEventInput = {
  /** Weekday for event, 1 = monday */
  recurrenceWeekday: number;
  /** Start time for event, e.g. "09.00" */
  recurrenceStartTime: string;
  /** End time for event, e.g. "10.30" */
  recurrenceEndTime: string;
  /** Unique identifier */
  id?: string;
  /** Array of coach IDs */
  coachIds?: string[];
  /** Player ID, to which input relates to */
  playerId?: string;
  /** Court ID */
  courtId?: string;
  /** Indicates if the event should be removed */
  remove?: boolean;
  /** When the first possible date is for recurring event */
  recurrenceStartDate?: string;
  /** When the last possible date is for recurring event */
  recurrenceEndDate?: string;
  /** Training group name */
  name?: string;
};

type SkillEvaluationInput = {
  /** Unique identifier */
  id?: string;
  /** Skill ID */
  skillId: string;
  /** Rating */
  rating: number;
};

type EvaluationInput = {
  /** Unique identifier */
  id?: string;
  /** Player ID */
  playerId: string;
  /** Array of skill evaluations */
  skillEvaluations: SkillEvaluationInput[];
  /** Indicates if the evaluation should be removed */
  remove?: boolean;
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
`;

/** Main Players component */
export const Players: React.FC = () => {
  const { globalSelectedClub } = useGlobalContext();
  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError,
  } = useQuery(GET_USER_ORGANIZATION);
  const { data: playerData, loading: playerDataLoading, error: playerDataError } = useQuery(GET_ALL_PLAYERS);
  const { data: coachData, loading: coachDataLoading, error: coachDataError } = useQuery(GET_ALL_COACHES);
  const { data: skillSetData, loading: skillSetDataLoading, error: skillSetDataError } = useQuery(GET_ALL_SKILL_SETS);
  const [savePlayerMutation, { error: savePlayerError, loading: savePlayerLoading }] = useMutation(SAVE_PLAYER, {
    refetchQueries: [{ query: GET_ALL_PLAYERS }],
    awaitRefetchQueries: true,
  });
  const [
    saveRecurringTrainingEventsMutation,
    { error: saveRecurringTrainingEventsError, loading: saveRecurringTrainingEventsLoading },
  ] = useMutation(SAVE_RECURRING_TRAINING_EVENT, {
    refetchQueries: [
      { query: GET_ALL_PLAYERS },
      { query: GET_ALL_RECURRING_TRAINING_EVENTS },
      { query: GET_ALL_RECURRING_TRAINING_EVENT_METADATAS_FOR_PLAYERS }, // to trigger update in calendar for events
      { query: GET_MISSING_RECURRING_TRAININGEVENT_METADATAS },
    ],
    awaitRefetchQueries: true,
  });
  const [saveNoteMutation, { error: saveNoteError, loading: saveNoteLoading }] = useMutation(SAVE_NOTE, {
    refetchQueries: [{ query: GET_ALL_PLAYERS }],
    awaitRefetchQueries: true,
  });
  const [saveEvaluationMutation, { error: saveEvaluationError, loading: saveEvaluationLoading }] = useMutation(
    SAVE_EVALUATION,
    {
      refetchQueries: [{ query: GET_ALL_PLAYERS }],
      awaitRefetchQueries: true,
    },
  );
  const [seasonStart, setSeasonStart] = useState<string | undefined>();
  const [seasonEnd, setSeasonEnd] = useState<string | undefined>();
  const [allPlayers, setAllPlayers] = useState<Player[]>(playerData?.getAllPlayers || []);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
  const [clearAutocomplete, setClearAutocomplete] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { showAlert, hideAlert } = useAlert();
  const [changePlayerAnimation, setChangePlayerAnimation] = useState("");
  const [isHeaderDisabled, setIsHeaderDisabled] = useState(false);
  const isLoading = playerDataLoading || coachDataLoading || organizationLoading || skillSetDataLoading;
  const [showContent, setShowContent] = useState(!isLoading);
  useEffect(() => {
    if (!isLoading) {
      // Timeout needs to match with LoadingContainer animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    }
  }, [isLoading]);

  useEffect(() => {
    if (
      playerDataError ||
      coachDataError ||
      organizationError ||
      saveNoteError ||
      skillSetDataError ||
      saveEvaluationError
    ) {
      const errors = [playerDataError, coachDataError, skillSetDataError, saveEvaluationError].filter(Boolean);
      if (errors.length) {
        showAlert(handleError(errors), "error");
      }
    }
    return () => {
      hideAlert();
    };
  }, [playerDataError, coachDataError, skillSetDataError, saveEvaluationError]);

  useEffect(() => {
    if (playerData?.getAllPlayers) {
      setAllPlayers(playerData?.getAllPlayers || []);
    }
  }, [playerData]);

  useEffect(() => {
    if (playerData?.getAllPlayers) {
      const updatedPlayer = playerData.getAllPlayers.find(
        (player: Player) => player.id === selectedPlayer?.id || player.name === selectedPlayer?.name,
      );
      setSelectedPlayer(updatedPlayer || null);
    }
  }, [playerData]);

  useEffect(() => {
    if (organizationData?.getUserOrganization) {
      const { seasonStart: start, seasonEnd: end } = organizationData.getUserOrganization;
      setSeasonStart(start);
      setSeasonEnd(end);
    }
  }, [organizationData]);

  useEffect(() => {
    if (clearAutocomplete) {
      setClearAutocomplete(false);
    }
  }, [clearAutocomplete]);

  const savePlayer = async (remove: boolean) => {
    if (!selectedPlayer) {
      return;
    }
    if (remove) {
      showAlert("Removing Player", "info", undefined, true);
    } else if (selectedPlayer.id) {
      showAlert("Updating Player", "info", undefined, true);
    } else {
      showAlert("Creating new Player", "info", undefined, true);
    }
    try {
      const { __typename, recurringTrainingEvents, logs, notes, evaluation, ...dataToSave } = selectedPlayer;
      await savePlayerMutation({
        variables: {
          data: { ...dataToSave, remove: remove },
        },
      });

      if (remove) {
        showAlert("Player removed!", "success", 5000);
      } else {
        if (selectedPlayer.id) {
          showAlert("Player updated!", "success", 5000);
        } else {
          showAlert("Player created!", "success", 5000);
        }
      }
      setIsDialogOpen(false);
    } catch (error) {
      showAlert(handleError([error]), "error");
    }
  };

  const saveRecurringTrainingEvent = async (eventToSave: RecurringTrainingEvent) => {
    const dataToSave: RecurringTrainingEventInput = {
      recurrenceWeekday: eventToSave.recurrenceWeekday,
      recurrenceStartTime: eventToSave.recurrenceStartTime,
      recurrenceEndTime: eventToSave.recurrenceEndTime,
      id: eventToSave.id,
      name: eventToSave.name,
      coachIds: eventToSave.coaches?.map((coach) => coach.id).filter((id) => id !== undefined) || [],
      playerId: selectedPlayer?.id || "",
      courtId: eventToSave.court.id,
      remove: eventToSave.remove,
    };
    if (eventToSave.remove) {
      showAlert("Removing player from training group", "info", undefined, true);
    } else {
      showAlert("Updating player training groups", "info", undefined, true);
    }

    try {
      const result = await saveRecurringTrainingEventsMutation({
        variables: {
          data: dataToSave,
        },
        awaitRefetchQueries: true,
      });
      if (result.data.saveRecurringTrainingEvent && eventToSave.remove) {
        // some player(s) left in the recurring event
        showAlert("Player removed from the training group!", "success", 5000);
      } else if (result.data.saveRecurringTrainingEvent === null && eventToSave.remove) {
        // no players left in the recurring event
        showAlert("Deleted training group!", "success", 5000);
      } else if (result.data.saveRecurringTrainingEvent) {
        showAlert("Updated player training groups!", "success", 5000);
      }
    } catch (error) {
      showAlert(handleError([error]), "error");
    }
  };

  const handleSave = () => {
    setIsDialogOpen(false);
    savePlayer(false);
  };

  const handleRemove = () => {
    setIsDialogOpen(false);
    savePlayer(true);
  };

  const handlePlayerChange = (newPlayer: Player) => {
    setIsHeaderDisabled(true);
    setClearAutocomplete(true);
    setChangePlayerAnimation(`${fadeOut} 0.5s ease`);
    setTimeout(() => {
      setChangePlayerAnimation(`${fadeIn} 0.5s ease`);
      setSelectedPlayer(newPlayer);
      setIsHeaderDisabled(false);
    }, 400);
  };

  const handleAddPlayer = () => {
    setSelectedPlayer({
      id: undefined,
      name: "New Player",
      recurringTrainingEvents: [],
    });
    setIsDialogOpen(true);
  };

  const handleCloseModal = () => {
    if (!selectedPlayer?.id) {
      setSelectedPlayer(null);
    }
    // Reset player to the one that was selected before opening the modal
    setSelectedPlayer(
      playerData.getAllPlayers.find(
        (player: Player) => player.id === selectedPlayer?.id || player.name === selectedPlayer?.name,
      ),
    );
    setIsDialogOpen(false);
  };

  const handlePlayerNameChange = (name: string) => {
    if (selectedPlayer) {
      setSelectedPlayer({
        ...selectedPlayer,
        name,
      });
    }
    setIsModified(true);
  };

  /**
   * Handler for saving recurring training event
   *
   * @param eventToSave - The event to save
   */
  const handleSaveRecurringTrainingEvent = (eventToSave: RecurringTrainingEvent) => {
    const canBeSaved =
      !!eventToSave.recurrenceWeekday &&
      !!eventToSave.court.id &&
      !!eventToSave.recurrenceStartTime &&
      !!eventToSave.recurrenceWeekday;
    if (canBeSaved) {
      saveRecurringTrainingEvent(eventToSave);
    }
  };

  const handleSaveNote = async (note: string) => {
    showAlert("Saving note", "info", undefined, true);
    try {
      const dataToSave: NoteInput = {
        content: note,
        playerIds: [selectedPlayer?.id || ""],
      };
      await saveNoteMutation({
        variables: {
          data: dataToSave,
        },
        awaitRefetchQueries: true,
      });
      showAlert("Note saved!", "success", 5000);
    } catch (error) {
      showAlert(handleError([error]), "error");
    }
  };

  const handleSaveEvaluation = async (evaluation: EvaluationType) => {
    showAlert("Updating Evaluation", "info", undefined, true);
    try {
      const evaluationInput: EvaluationInput = {
        id: evaluation.id,
        playerId: selectedPlayer?.id || "",
        skillEvaluations: evaluation.skillEvaluations.map((skillEvaluation) => ({
          rating: skillEvaluation.rating,
          skillId: skillEvaluation.skill.id || "",
          id: skillEvaluation.id,
        })),
        remove: evaluation.remove,
      };

      await saveEvaluationMutation({
        variables: {
          data: evaluationInput,
        },
        awaitRefetchQueries: true,
      });
      showAlert("Evaluation updated!", "success", 5000);
    } catch {
      // catch silently as useEffect will handle it
    }
  };

  const getSubmitDisabledReasons = (): string | undefined => {
    if (allPlayers.map((player) => player.name.toLowerCase()).includes(selectedPlayer?.name.toLowerCase() || "")) {
      return `Player "${selectedPlayer?.name}" already exists`;
    } else if (selectedPlayer?.name.trim() === "") {
      return "Player name cannot be empty";
    }

    return undefined;
  };

  const submitDisabledReasons = useMemo(() => {
    return getSubmitDisabledReasons();
  }, [allPlayers, selectedPlayer?.name]);

  const allUniqueRecurringTrainingEvents = allPlayers
    .flatMap((player) => player.recurringTrainingEvents || [])
    .reduce((acc, currentEvent) => {
      if (!acc.some((event) => event.id === currentEvent.id)) {
        acc.push(currentEvent);
      }
      return acc;
    }, [] as RecurringTrainingEvent[]);
  const filteredUniqueRecurringTrainingEvents = allUniqueRecurringTrainingEvents.filter(
    (event) => !selectedPlayer?.recurringTrainingEvents?.some((playerEvent) => playerEvent.id === event.id),
  );

  return (
    <Box id="players">
      <LoadingContainer display={isLoading} />
      {showContent && (
        <Box
          position="relative"
          animation={isLoading ? undefined : `${loadingContainerFadeIn} 0.3s`}
          data-testid="players"
        >
          <LoadingOverlay display={savePlayerLoading || false} spinnerSize="xl" spinnerTopPosition="60px" />

          {/* Header */}
          <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" marginBottom="12">
            <Stack direction="row" spacing="4" flex={1}>
              <Autocomplete
                initialOption={selectedPlayer}
                allOptions={allPlayers}
                getOptionLabel={(player: Player) => player.name}
                handleOptionChange={handlePlayerChange}
                clearInput={clearAutocomplete}
                dataTestId="player-autocomplete"
              />
            </Stack>
            <Text fontSize="x-large" flex={1} textAlign="center" animation={changePlayerAnimation}>
              {selectedPlayer?.name || "Select player"}
            </Text>
            <Stack direction="row" spacing="4" flex={1} justifyContent="end">
              <CommonButton
                variantType="outlineSecondary"
                onClick={handleAddPlayer}
                disabled={isHeaderDisabled}
                dataTestId="add-player"
              >
                New player
              </CommonButton>
              <CommonButton
                variantType="outlineSecondary"
                disabled={!selectedPlayer || isHeaderDisabled}
                onClick={() => setIsDialogOpen(true)}
                dataTestId="edit-player"
              >
                Edit
              </CommonButton>
            </Stack>
          </Stack>

          {/* Player content */}
          {selectedPlayer && (
            <Stack animation={changePlayerAnimation} direction="column" spacing="12">
              <Evaluation
                key={selectedPlayer.evaluation?.id}
                player={selectedPlayer}
                allSkillSets={skillSetData?.getAllSkillSets || []}
                disabled={false}
                onSaveEvaluation={handleSaveEvaluation}
                isLoading={saveEvaluationLoading}
              />

              <Stack direction="row" spacing="4" width="100%">
                <Notes player={selectedPlayer} onSave={handleSaveNote} isLoading={saveNoteLoading} />
                <Logs player={selectedPlayer} isLoading={saveRecurringTrainingEventsLoading} />
              </Stack>

              <RecurringTrainingEvents
                playerName={selectedPlayer?.name || ""}
                coaches={coachData?.getAllCoaches}
                courts={globalSelectedClub?.courts || []}
                allTrainingEvents={filteredUniqueRecurringTrainingEvents}
                recurringTrainingEvents={
                  selectedPlayer?.recurringTrainingEvents
                    ?.map((event) => ({ ...event }))
                    .sort((a, b) => a.recurrenceWeekday - b.recurrenceWeekday) || []
                }
                onSave={(eventToSave) => handleSaveRecurringTrainingEvent(eventToSave)}
                onRemove={(eventToRemove) => handleSaveRecurringTrainingEvent({ ...eventToRemove, remove: true })}
                isLoading={saveRecurringTrainingEventsLoading}
                isSeasonDatesMissing={!seasonStart || !seasonEnd}
              />

              <FormModal
                handleSubmit={handleSave}
                open={isDialogOpen}
                submitButtonText={!selectedPlayer?.id ? "Create" : "Confirm"}
                title={!selectedPlayer?.id ? "Create new player" : `Edit ${selectedPlayer.name}`}
                handleRemove={handleRemove}
                removeDisabled={!selectedPlayer?.id}
                removeDisabledReason={!selectedPlayer?.id ? "Player isn't saved yet" : undefined}
                onClose={handleCloseModal}
                submitDisabled={!!submitDisabledReasons}
                submitButtonHoverText={submitDisabledReasons}
              >
                <Box paddingTop="1" data-testid="player-modal">
                  <CommonInput
                    placeholder="Player name"
                    value={selectedPlayer?.name || ""}
                    onChange={handlePlayerNameChange}
                    dataTestId="player-name"
                  />
                </Box>
              </FormModal>
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
};
