import React, { useState } from "react";
import { Box, Stack, Text, Checkbox } from "@chakra-ui/react";
import type { Skill, SkillSet } from "../../../../../../../../types";

export type SelectableSkill = Skill & { selected: boolean };

export type SelectableSkillSet = Omit<SkillSet, "skills"> & {
  selected: boolean;
  skills: SelectableSkill[];
};

/** Props for the SkillSetDisplayCard component */
export type SkillSetDisplayCardProps = {
  /** The SkillSet to be displayed */
  skillSet: SelectableSkillSet;
  /** Whether to show the checkbox */
  showCheckbox?: boolean;
  /** Callback function for selecting a SkillSet */
  onSelectSkillSet?: (skillSet: SelectableSkillSet) => void;
  /** Callback function for selecting individual skills */
  onSelectSkill?: (selectedSkill: SelectableSkill) => void;
  /** Whether the user is using a mobile device */
  isMobile?: boolean;
  /** The data-testid attribute for testing */
  dataTestId?: string;
};

/**
 * SkillSetDisplayCard component displays a read-only SkillSet with selectable checkboxes for both skill set and
 * individual skills
 */
export const SkillSetDisplayCard: React.FC<SkillSetDisplayCardProps> = (props) => {
  const [isHoveringSkillStack, setIsHoveringSkillStack] = useState(false);
  const handleCardClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const target = event.target as HTMLElement;
    const isSkillStackElement = target.closest("#skill-stack");

    if (isSkillStackElement) {
      return;
    }

    if (props.onSelectSkillSet) {
      props.onSelectSkillSet(props.skillSet);
    }
  };

  const handleSkillClick = (skill: SelectableSkill) => {
    if (props.onSelectSkill) {
      props.onSelectSkill(skill);
    }
  };

  return (
    <Box
      onClick={handleCardClick}
      paddingY="4"
      paddingX={{ mobile: "2", laptop: "4" }}
      border="1px solid"
      borderColor={props.skillSet.selected ? "orange.500" : "blackAlpha.300"}
      borderRadius="md"
      shadow={props.skillSet.selected ? "sm" : "md"}
      transition="all 0.3s ease"
      _hover={isHoveringSkillStack ? undefined : { borderColor: "orange.500", shadow: "sm", cursor: "pointer" }}
      data-testid={props.dataTestId}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom="6" marginRight="4">
        <Text flex={1} fontSize="large" color="blackAlpha.800">
          {props.skillSet.name}
        </Text>
        {props.showCheckbox && <Checkbox isChecked={props.skillSet.selected} colorScheme="orange" iconColor="white" />}
      </Stack>

      {props.skillSet?.skills?.length > 0 ? (
        <Stack
          direction="column"
          spacing="4"
          id="skill-stack"
          onMouseEnter={() => setIsHoveringSkillStack(true)}
          onMouseLeave={() => setIsHoveringSkillStack(false)}
        >
          {props.skillSet.skills.map((skill, index) => (
            <Stack
              key={skill.id}
              direction="row"
              backgroundColor="blackAlpha.50"
              borderRadius="md"
              onClick={() => handleSkillClick(skill)}
              paddingY="2"
              paddingX="4"
              border="1px solid"
              borderColor={skill.selected ? "orange.500" : "transparent"}
              shadow={skill.selected ? "sm" : "base"}
              cursor="pointer"
              transition="all 0.3s ease"
              _hover={{ borderColor: "orange.500", shadow: "base" }}
            >
              <Text as="dt" color="blackAlpha.600" whiteSpace="nowrap">
                Skill {index + 1}
              </Text>
              <Stack as="dd" flex={1} direction="row" spacing="4" justifyContent="space-between">
                <Text color="blackAlpha.800" whiteSpace="nowrap">
                  {skill.name}
                </Text>
                <Checkbox isChecked={skill.selected} colorScheme="orange" iconColor="white" />
              </Stack>
            </Stack>
          ))}
        </Stack>
      ) : null}
    </Box>
  );
};
